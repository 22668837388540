import {
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonTabBar,
  IonTabButton,
  IonTitle,
  IonToggle,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import "firebase/compat/auth";
import "./Community.css";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { UserContext } from "../../../lib/context";
import AnswerFeedback from "../../../components/Bodee.tsx/Koro/AnswerFeedback";
import InterestSlider from "../../../components/Bodee.tsx/Koro/Home/InterestSlider";
import MessageBox from "../../../components/Bodee.tsx/Koro/Home/MessageBox";
import QuestionCard from "../../../components/Bodee.tsx/Koro/QuestionCard";
import Welcome from "../../../components/Bodee.tsx/Koro/Welcome";
import SetPublicAvatar from "../../../components/Bodee.tsx/Koro/SetPublicAvatar";
import {
  library,
  notifications,
  notificationsCircle,
  notificationsOutline,
  playCircle,
  radio,
  search,
} from "ionicons/icons";
import NotificationTray from "../../../components/Bodee.tsx/Koro/Home/NotificationTray";
import Profile from "../../../components/Bodee.tsx/Koro/Home/Profile";
import SetInterests from "../../../components/Bodee.tsx/Koro/SetInterest";
import SetGoals from "../../../components/Bodee.tsx/Koro/SetGoals";
import { InterestList } from "../../../components/Bodee.tsx/Interfaces/Interest";
import { ClinicalConcernsList } from "../../../components/Bodee.tsx/Interfaces/ClinicalConcerns";
import { GoalsList } from "../../../components/Bodee.tsx/Interfaces/Goals";
import GoalSlider from "../../../components/Bodee.tsx/Koro/Home/GoalSlider";
import ClinicalConcernSlider from "../../../components/Bodee.tsx/Koro/Home/ClinicalConcernSlider";

const Community: React.FC = () => {
  const { koroPublicAvatar, profile } = useContext(UserContext);
  const [questionsData, setQuestionsData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [focus, setFocus] = useState("focus");
  const [homeSegment, setHomeSegment] = useState("goals");

  const getQuestions = async () => {
    const questions = [];
    var q;

    const LIMIT = 30;

    if (homeSegment === "goals") {
      // create array of just ids from list GoalsList
      const goalsListIds = GoalsList.map((goal) => goal.id);

      if (focus === "all") {
        q = query(
          collection(getFirestore(), "koroQuestions"),
          where("tags", "array-contains-any", goalsListIds),
          orderBy("dateCreated", "desc"),
          limit(LIMIT)
        );
      }

      if (focus === "focus") {
        q = query(
          collection(getFirestore(), "koroQuestions"),
          where("tags", "array-contains-any", koroPublicAvatar.koroGoals),
          orderBy("dateCreated", "desc"),
          limit(LIMIT)
        );
      }
    } else if (homeSegment === "interest") {
      console.log("interest");
      // create array of just ids from list InterestList
      var interestListIds = InterestList.map((interest) => interest.id);

      // randomize the list
      interestListIds.sort(() => Math.random() - 0.5);

      interestListIds = interestListIds.splice(0, 30);

      if (focus === "all") {
        q = query(
          collection(getFirestore(), "koroQuestions"),
          where("tags", "array-contains-any", interestListIds),
          orderBy("dateCreated", "desc"),
          limit(LIMIT)
        );
      }

      if (focus === "focus") {
        q = query(
          collection(getFirestore(), "koroQuestions"),
          where("tags", "array-contains-any", koroPublicAvatar.koroInterest),
          orderBy("dateCreated", "desc"),
          limit(LIMIT)
        );
      }
    } else if (homeSegment === "clinical-concerns") {
      console.log("clinical-concerns");
      // create array of just ids from list ClinicalConcernsList
      const clinicalConcernsListIds = ClinicalConcernsList.map(
        (clinicalConcern) => clinicalConcern.id
      );

      if (focus === "all") {
        q = query(
          collection(getFirestore(), "koroQuestions"),
          where("tags", "array-contains-any", clinicalConcernsListIds),
          orderBy("dateCreated", "desc"),
          limit(LIMIT)
        );
      }

      if (focus === "focus") {
        q = query(
          collection(getFirestore(), "koroQuestions"),
          where(
            "tags",
            "array-contains-any",
            koroPublicAvatar.koroClinicalConcerns
          ),
          orderBy("dateCreated", "desc"),
          limit(LIMIT)
        );
      }
    }

    const querySnapshot = await getDocs(q);
    // console.log("q", q);

    querySnapshot.forEach((doc) => {
      const data = doc.data() as any;
      questions.push({ id: doc.id, ...data });
    });

    try {
      questions.sort((a, b) => {
        return b?.dateCreated?.seconds - a?.dateCreated?.seconds;
      });
    } catch (e) {
      console.log(e);
    }

    setQuestionsData(questions);
  };

  useEffect(() => {
    // wait till koroPublicAvatar is not null
    if (koroPublicAvatar != null) {
      getQuestions();
    }
  }, [focus, homeSegment, koroPublicAvatar]);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    console.log("Refreshing...");
    setRefresh(true);
    setTimeout(() => {
      // Any calls to load data go here
      getQuestions();
      event.detail.complete();
      setRefresh(false);
    }, 2000);
  }

  const refreshHandler = () => {
    getQuestions();
  };

  if (!profile.koroWelcomeComplete) {
    return <Welcome />;
  } else if (
    profile.koroInterest == null ||
    profile.koroInterest == "" ||
    profile.koroInterest == undefined
  ) {
    return <SetInterests />;
  } else if (
    profile.koroGoals == null ||
    profile.koroGoals == "" ||
    profile.koroGoals == undefined
  ) {
    return <SetGoals />;
  } else if (
    koroPublicAvatar == null ||
    koroPublicAvatar == "" ||
    koroPublicAvatar == undefined
  ) {
    return <SetPublicAvatar />;
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="mainHeaderBackground">
          <Profile />
          <IonButtons slot="start">
            <NotificationTray />
          </IonButtons>
          <IonTitle className="mainPageTitleTEst">Community</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher
          slot="fixed"
          // className="backgroundColor"
          onIonRefresh={handleRefresh}
        >
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <AnswerFeedback />
        <div>
          <IonSegment
            value={focus}
            onIonChange={(e) => setFocus(e.detail.value)}
          >
            <IonSegmentButton value="focus">
              <IonLabel>Focus</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="all">
              <IonLabel>All</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <IonItem lines="none" className="ion-text-center">
            <div className="centerChips">
              <IonChip
                className="sortChipSecondary"
                color={homeSegment === "goals" ? "primary" : ""}
                onClick={() => setHomeSegment("goals")}
              >
                Goals
              </IonChip>
              <IonChip
                className="sortChipSecondary"
                color={homeSegment === "interest" ? "primary" : ""}
                onClick={() => setHomeSegment("interest")}
              >
                Interests
              </IonChip>
              <IonChip
                className="sortChipSecondary"
                color={homeSegment === "clinical-concerns" ? "primary" : ""}
                onClick={() => setHomeSegment("clinical-concerns")}
              >
                Clinical Concerns
              </IonChip>
            </div>
          </IonItem>
          {homeSegment === "clinical-concerns" && (
            <>
              {" "}
              <h3 className="ion-margin">Clinical Concerns</h3>
              <ClinicalConcernSlider refresh={refresh} focus={focus} />
            </>
          )}

          {homeSegment === "goals" && (
            <>
              <h3 className="ion-margin">Goals</h3>
              <GoalSlider refresh={refresh} focus={focus} />
            </>
          )}

          {homeSegment === "interest" && (
            <>
              <h3 className="ion-margin">Interest</h3>
              <InterestSlider refresh={refresh} focus={focus} />
            </>
          )}

          {/* <h3 className="ion-margin">Interest</h3>*/}
          <h3 className="ion-margin">Message Board</h3>
          <MessageBox />
          {questionsData.length === 0 && (
            <IonLabel>
              <p className="ion-margin-start ion-padding-start ion-text-center">
                No questions found
              </p>
            </IonLabel>
          )}
          {questionsData.map((question, index) => {
            return (
              <QuestionCard
                question={question}
                refresh={refresh}
                key={question.id}
                refreshHandler={refreshHandler}
              />
            );
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Community;
