import {
  IonButton,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
} from "@ionic/react";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { checkboxOutline, squareOutline } from "ionicons/icons";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../lib/context";
import "./HRAStyle.css";

type Props = {
  slides;
  buttonHandler;
  profileHandler;
  profile;
  slideIndex;
};

const AddHealthIssue: React.FC<Props> = ({
  slides,
  buttonHandler,
  profileHandler,
  profile,
  slideIndex,
}) => {
  const [anxiety, setAnxiety] = useState(profile.anxiety);
  const [arrhythmia, setArrythmia] = useState(profile.arrhythmia);
  const [asthma, setAsthma] = useState(profile.asthma);
  const [backpain, setBackpain] = useState(profile.backpain);
  const [chf, setChf] = useState(profile.congestiveHeartFailure);
  const [copd, setCopd] = useState(profile.copd);
  const [depression, setDepression] = useState(profile.depression);

  const RenderAnxiety = () => {
    if (anxiety) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setAnxiety(!anxiety);
            profile.anxiety = !anxiety;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Anxiety </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setAnxiety(!anxiety);
            profile.anxiety = !anxiety;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Anxiety </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderArrhythmia = () => {
    if (arrhythmia) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setArrythmia(!arrhythmia);
            profile.arrhythmia = !arrhythmia;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Arrhythmia </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setArrythmia(!arrhythmia);
            profile.arrhythmia = !arrhythmia;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Arrhythmia </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderAsthma = () => {
    if (asthma) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setAsthma(!asthma);
            profile.asthma = !asthma;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Asthma </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setAsthma(!asthma);
            profile.asthma = !asthma;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Asthma </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderBackpain = () => {
    if (backpain) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setBackpain(!backpain);
            profile.backpain = !backpain;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Back pain </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setBackpain(!backpain);
            profile.backpain = !backpain;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Back pain </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderCHF = () => {
    if (chf) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setChf(!chf);
            profile.congestiveHeartFailure = !chf;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left ion-text-wrap">
            {" "}
            Congestive Heart Failure (CHF){" "}
          </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setChf(!chf);
            profile.congestiveHeartFailure = !chf;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left ion-text-wrap">
            {" "}
            Congestive Heart Failure (CHF){" "}
          </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderDepression = () => {
    if (depression) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setDepression(!depression);
            profile.depression = !depression;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Depression </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setDepression(!depression);
            profile.depression = !depression;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> Depression </IonLabel>
        </IonItem>
      );
    }
  };

  const RenderCOPD = () => {
    if (copd) {
      return (
        <IonItem
          className="mainGoalItem pickedItem"
          id="eatBetterItem"
          lines="none"
          onClick={() => {
            setCopd(!copd);
            profile.copd = !copd;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={checkboxOutline}
            color="white"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> COPD </IonLabel>
        </IonItem>
      );
    } else {
      return (
        <IonItem
          className="mainGoalItem "
          id="eatBetterItem"
          lines="none"
          fill="outline"
          onClick={() => {
            setCopd(!copd);
            profile.copd = !copd;
            profileHandler(profile);
          }}
        >
          <IonIcon
            icon={squareOutline}
            color="primary"
            className="checkPadding"
          />
          <IonLabel className="ion-text-left"> COPD </IonLabel>
        </IonItem>
      );
    }
  };

  useEffect(() => {
    if (slideIndex === 6) {
      buttonHandler(false);
    }
  }, [slideIndex]);

  return (
    <>
      <IonCardTitle class="ion-text-start slideTitle">
        Do you have any of these conditions?
      </IonCardTitle>
      <div className="ion-padding">
        <IonGrid>
          <IonRow>
            <IonCol>
              <RenderAnxiety />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderArrhythmia />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderAsthma />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderBackpain />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderCHF />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderCOPD />
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <RenderDepression />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default AddHealthIssue;
