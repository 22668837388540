import {
  IonAlert,
  IonApp,
  setupIonicReact,
} from "@ionic/react";
import PublicRouter from "./pages/public/PublicRouter";
import PrivateRouter from "./pages/private/PrivateRouter";
import { CometChat } from "@cometchat-pro/chat";
import { App } from "@capacitor/app";
import { isPlatform } from "@ionic/react";

import AppUrlListener from "./lib/AppUrlListener";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { UserContext } from "./lib/context";
//import { useUserData } from './lib/hooks';
import { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { useUserData } from "./lib/hooks";
import Loader from "./components/Loader";
import { doc, getDoc, getFirestore } from "firebase/firestore";

import { Browser } from "@capacitor/browser";

import { Capacitor } from "@capacitor/core";

import { ConnectionStatus, Network } from "@capacitor/network";
import React from "react";

setupIonicReact();

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);

//intialize comet chat
const appID = process.env.REACT_APP_COMET_APP_ID;
const region = process.env.REACT_APP_COMET_REGION;
const appSetting = new CometChat.AppSettingsBuilder()
  .subscribePresenceForAllUsers()
  .setRegion(region)
  .build();
CometChat.init(appID, appSetting).then(
  () => {
    console.log("Initialization completed successfully!!!!");
    // You can now call login function.
  },
  (error) => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  }
);

const Root = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [needToUpdate, setNeedToUpdate] = useState(false);
  const [vvvv, setVvvv] = useState("4444");

  const checkVersionNumberApple = () => {
    const ref = doc(getFirestore(), "version", "apple");
    var versionNumber;
    var reviewVersion;

    getDoc(ref)
      .then((doc) => {
        if (doc.exists()) {
          versionNumber = doc.data().version;
          reviewVersion = doc.data().reviewVersion;
          console.log("versionNumber", versionNumber);
          console.log("reviewVersion", reviewVersion);

          App.getInfo()
            .then((info) => {
              console.log("info.version", info.version);
              if (
                versionNumber !== info.version &&
                reviewVersion !== info.version
              ) {
                setNeedToUpdate(true);
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  const checkVersionNumberAndroid = () => {
    const ref = doc(getFirestore(), "version", "android");
    var versionNumber;
    var reviewVersion;

    getDoc(ref)
      .then((doc) => {
        if (doc.exists()) {
          versionNumber = doc.data().version;
          reviewVersion = doc.data().reviewVersion;
          console.log("Document data:", versionNumber);
          App.getInfo()
            .then((info) => {
              console.log("info.version", info.version);
              setVvvv(info.version);
              if (
                versionNumber !== info.version &&
                reviewVersion !== info.version
              ) {
                setNeedToUpdate(true);
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  };

  useEffect(() => {
    //get playform
    if (isPlatform("ios")) {
      checkVersionNumberApple();
    } else if (isPlatform("android")) {
      checkVersionNumberAndroid();
    }
  }, []);

  function emptyCache() {
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
          console.log("Cache deleted");
        });
      });

      // Makes sure the page reloads. Changes are only visible after you refresh.
    }
  }

  useEffect(() => {
    //set loading true for 2 seconds
    console.log(userData.user);
    //if user is online, set loading to false
    if (navigator.onLine) {
      if (userData.user == null) {
        setTimeout(() => {
          setLoading(false);
        }, 3500);
      }
    }
  }, []);  
  

  const userData = useUserData();

  const openAppStore = async () => {
    if (isPlatform("ios")) {
      await Browser.open({
        url: "https://apps.apple.com/us/app/allied-wellness/id1643169342",
      });
    } else if (isPlatform("android")) {
      await Browser.open({
        url: "https://play.google.com/store/apps/details?id=allied.wellness.ios",
      });
    }
  };


  const [networkStatus, setNetworkStatus] = useState<ConnectionStatus>(null);
  // check if there is internet connection
  Network.addListener("networkStatusChange", (status) => {
    console.log("Network status changed", status);
  });

  const logCurrentNetworkStatus = async () => {
    const status = await Network.getStatus();
    setNetworkStatus(status);
    console.log("Network status:", status);
  };

  useEffect(() => {
    logCurrentNetworkStatus();
  }, []);

  useEffect(() => {
    console.log("USER,", userData?.profile?.accountSetupComplete);
  }, [userData.profile]);

  // return <IonPage><IonHeader><IonToolbar>Title</IonToolbar></IonHeader><IonContent>{JSON.stringify(networkStatus)} <IonButton onClick={logCurrentNetworkStatus}>Reload</IonButton></IonContent></IonPage>
  if (userData.loading) {
    return (
  
       <Loader />
  
      
      // <IonPage>
      // <IonContent fullscreen={true}>
      // <div className="middle">
      //   <div className="center-container">
      //     <>
      //       <IonSpinner></IonSpinner>{" "}
      //       <IonLabel>
      //         <p>Fetching your authentication data...</p>
      //         {userData.error && <p>{userData.error.message}</p>}
      //       </IonLabel>
      //     </>
      //   </div>
      // </div>
      // </IonContent>
      // </IonPage>
    );
  }




  
  


  return (
    <>
      <UserContext.Provider value={userData}>
        {needToUpdate ? (
          <>
            <IonAlert
              backdropDismiss={false}
              isOpen={needToUpdate}
              onDidDismiss={() => openAppStore()}
              header={"Update Required"}
              message={"Please update your app to the latest version"}
              buttons={["OK"]}
            />{" "}
          </>
        ) : (
          <>
            {loading ? <Loader /> : null}
            <IonApp>
              {userData.user ? <PrivateRouter /> : <PublicRouter />}
            </IonApp>
          </>
        )}
      </UserContext.Provider>
    </>
  );
};

export default Root;
