import { firebaseApp } from "../lib/Firebase";
import { Browser } from "@capacitor/browser";
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";

const openBrowser = async (url) => {
  await Browser.open({ url: url });
};

export const createPortalSession = async (uid: string) => {
  try {
    console.log("createPortalSession");

    const functions = getFunctions(firebaseApp, "us-central1");

    const portalSession = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    // console.log("portalSession2",portalSession)


    const { data } = await portalSession({
      returnUrl: "https://alliedwellness.com/thankyou/",
      uid: uid,
      locale: "auto",
    });

    console.log("lol");

    // console.log("data", data);

    openBrowser(data["url"]);
  } catch (error) {
    console.log("error", error);
  }
};
