import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

const AppUrlListener: React.FC<any> = () => {
    let history = useHistory();
    useEffect(() => {
      console.log("AppUrlListener: Adding listener")
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        console.log('App opened with URL: ')
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split('.com').pop();
        console.log('slug TEST', slug);
        if (slug) {
          history.push(slug)
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    }, []);
  
    return null;
  };
  
  export default AppUrlListener;