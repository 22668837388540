//import spinner
import React, { useContext } from "react";
import "firebase/compat/auth";
import { UserContext } from "../../../lib/context";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonToggle,
} from "@ionic/react";
import "./SettingsHeaderCard.css";
import {
  callOutline,
  chevronBack,
  chevronForward,
  heartCircle,
  heartOutline,
  homeOutline,
  lockClosed,
  lockClosedOutline,
  mailOutline,
  person,
  personOutline,
  phoneLandscape,
} from "ionicons/icons";
import TimeZone from "../Preferences/TimeZone";

const SettingsHeaderCard: React.FC = () => {
  const { user, profile } = useContext(UserContext);

  return (
    <div className="ion-padding-top thisisfun">
      <div className="wrapperSettingsHeaderImg">
        <img src={profile["photoURL"]} alt="" />
      </div>
      <p className="settingsHeaderCardName ion-text-center">
        {" "}
        {profile["firstName"] + " " + profile["lastName"]}{" "}
      </p>
      <p className="settingsHeaderCardEmail ion-text-center"> {profile["email"]} </p>
      {/* <IonButton className="settingsHeaderButton" shape="round">
        Edit Profile <IonIcon icon={chevronForward} slot="end"></IonIcon>
      </IonButton> */}
     
    </div>
  );
};

export default SettingsHeaderCard;
