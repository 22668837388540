  //import spinner
  import React from "react";
  import "firebase/compat/auth";

  interface Props {
    startTime: string;
  }
  
  const StartTimePretty: React.FC<Props> = ({startTime}) => {
  
    const sessionStartTime = new Date(startTime);
    const sessionStartTimeAndDatePretty = sessionStartTime.toLocaleString(
      "en-US",
      {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }
    );

    return <>{sessionStartTimeAndDatePretty}</>
  };
  
  export default StartTimePretty;