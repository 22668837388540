import { IonPage, IonContent, IonButton } from "@ionic/react";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import React from "react";

const NotAllowed: React.FC = () => {
  const signOutUser = () => {
    // CometChat.logout();
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
    //window.location.href = "/login";
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <p>Not Allowed</p>
        <IonButton onClick={signOutUser}>Sign Out</IonButton>
      </IonContent>
    </IonPage>
  );
};

export default NotAllowed;
