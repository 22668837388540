import { IonItem, IonIcon, IonLabel, IonModal, IonButtons, IonContent, IonHeader, IonList, IonMenuButton, IonTitle, IonToolbar, IonBackButton, IonButton } from "@ionic/react";
import "./style.css";
import { timer, timerOutline } from "ionicons/icons";
import { useState } from "react";
import CommentedPost from "../../../Activity/CommentedPost";
import DownvoteItem from "../../../Activity/DownvoteItem";
import UpvoteItem from "../../../Activity/UpvoteItem";

type Props = {};

const Activity: React.FC<Props> = () => {
  const [activityOpen, setActivityOpen] = useState(false);

  return (
    <>
      <IonItem
        detail={true}
        className="noBackground"
        lines="none"
        onClick={() => {
          setActivityOpen(true);
        }}
      >
        <IonIcon slot="start" icon={timerOutline} />
        <IonLabel>Activity</IonLabel>
      </IonItem>
      <IonModal isOpen={activityOpen} >
      <IonHeader>
        <IonToolbar>
          {/* <IonImg src="koroLogo.png" className="koroLogoHeader" /> */}
          <IonTitle>Activity</IonTitle>
          <IonButtons slot="start">
            <IonButton onClick={() => setActivityOpen(false)}>Back</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <UpvoteItem />
          <DownvoteItem />
          <CommentedPost />
        </IonList>
      </IonContent>
      </IonModal>
    </>
  );
};

export default Activity;
