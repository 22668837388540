import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

import "./SetGoals.css";
import { useContext, useState } from "react";
import { UserContext } from "../../../lib/context";
import { GoalsList } from "../Interfaces/Goals";
import { ellipse, ellipseOutline } from "ionicons/icons";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

const SetGoals: React.FC = () => {
  const { user, profile } = useContext(UserContext);

  const [goals, setGoals] = useState(profile.koroGoals || "");

  const handleSubmit = (e) => {
    e.preventDefault();

    const { uid, email } = user;

    // const koroUser = {
    //   uid,
    //   email,
    //   firstName,
    //   lastName,
    //   gender,
    //   dob: reformatDOB(dob),
    //   dobTimeStamp,
    //   heightFt: parseInt(heightFt),
    //   heightIn: parseInt(heightIn),
    //   weight: parseInt(weight),
    //   // interest,
    //   koroWelcomeComplete: true,
    // };

    // console.log(koroUser);

    const docRef = doc(getFirestore(), "users", uid);

    updateDoc(docRef, {koroGoals: goals}).then(() => {
      console.log("Document successfully updated!");
    });
  };

  const handleSelectGoals = (goal) => {
    // console.log(interests);
    if (goals.includes(goal)) {
      setGoals(goals.filter((item) => item !== goal));
    } else {
      setGoals([...goals, goal]);
    }

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          {/* <IonButtons slot="start">
            <IonIcon
              slot="start"
              icon={chevronBack}
              size="large"
              color="primary"
              onClick={() => signOutUser()}
            />
          </IonButtons> */}
          <IonTitle className="ion-text-center">Set Goals</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="koroOnboardingContainer">
          <div className="center-container ion-padding-top">
            <img src="koroLogo.png" className="loginLogo" alt="logo" />
          </div>
          <div className="ionPaddingTopSmall">
            <h2 className="welcomeKoroTitle ">Select Your Goals</h2>
            <div>
              <p className="subTitle">Please select at least 3 goals</p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <div>
                <div className="ionPaddingTopSmall">
                  <IonList>
                    {GoalsList.map((goal, index) => {
                      return (
                        <IonItem
                          className="ion-no-margin ion-no-padding"
                          lines="none"
                          onClick={() => handleSelectGoals(goal.id)}
                        >
                          <IonLabel  className="ion-text-wrap">{goal.title}</IonLabel>
                          {/* <IonIcon icon={ellipseOutline} slot="end">
              </IonIcon> */}
                          {goals.includes(goal.id) ? (
                            <IonIcon
                              icon={ellipse}
                              slot="end"
                              color="primary"
                            />
                          ) : (
                            <IonIcon
                              icon={ellipseOutline}
                              slot="end"
                              color="primary"
                            />
                          )}
                        </IonItem>
                      );
                    })}
                  </IonList>

                  {/* <IonSelect
                    onIonChange={(e: any) => setInterest(e.target.value)}
                    placeholder="Your Interest"
                    value={interest}
                    className="ion-no-padding ionSelect"
                    multiple={true}
                  >
                    {InterestList.map((interest, index) => {
                      return (
                        <IonSelectOption value={interest.title} key={index}>
                          {interest.title}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect> */}
                </div>
              </div>
            </div>
            <div className="ionPaddingTopSmall">
              <IonButton expand="block" type="submit" className="ion-margin" disabled={goals.length < 3}>
                Continue
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SetGoals;
