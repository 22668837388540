import React, { useState, useRef, useContext, useEffect } from "react";
import {
  IonButtons,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonToolbar,
  IonTitle,
  IonPage,
  IonItem,
  IonLabel,
  IonInput,
  IonAlert,
  IonBackButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCheckbox,
  IonIcon,
  IonList,
  IonSpinner,
  IonMenuButton,
  IonMenuToggle,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/core/components";
import { warning, chevronForward, closeOutline } from "ionicons/icons";
import { Browser } from "@capacitor/browser";
import axios from "axios";
import { UserContext } from "../../../lib/context";
import FaceScan from "../../../assets/icons/face-id-svgrepo-com.svg";
import PopopTermsOfService from "../../PopupModals/PopupTermsOfService";
import "./DeepAffex.css";

function DeepAffex() {
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);

  const [message, setMessage] = useState(
    "This modal example uses triggers to automatically open a modal when the button is clicked."
  );

  function confirm() {
    modal.current?.dismiss(input.current?.value, "confirm");
  }

  function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {
    if (ev.detail.role === "confirm") {
      setMessage(`Hello, ${ev.detail.data}!`);
    }
  }

  const { user } = useContext(UserContext);
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [mostRecentScan, setMostRecentScan] = React.useState(null);
  const [measurementURL, seMeasurementURL] = React.useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertClicked, setShowAlertClicked] = useState(false);

  const [showTermsModal, setShowTermsModal] = useState(false);

  const getMearurementURL = () => {
    if (checked) {
      setLoading(true);
      axios
        .post(
          `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/DFX-GetMeasurementURL`,
          { uid: user.uid }
        )
        .then((res) => {
          setLoading(false);
          seMeasurementURL(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      //alert("Please agree to the terms and conditions");
    }
  };

  const getMostRecentScan = () => {
    setLoading(true);
    var i = 0;
    axios
      .post(
        `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/DFX-GetMeasurementList`,
        { uid: user.uid }
      )
      .then((res) => {
        for (i = 0; i < res.data.length; i++) {
          if (res.data[i].StatusID === "COMPLETE") {
            setMostRecentScan(res.data[i].ID);
            setLoading(false);
            return;
          }
        }

        setMostRecentScan("no-scan");

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getMostRecentScan();
  }, []);

  useEffect(() => {
    getMearurementURL();
  }, [checked]);

  const openBrowser = async (url) => {
    await Browser.open({ url: url });
  };

  function RenderMeasurementButton() {
    if (checked && measurementURL !== "") {
      return (
        <IonButton onClick={() => setShowAlert(true)}>
          {loading ? <IonSpinner /> : "Start Scan"}
        </IonButton>
      );
    } else if (checked && measurementURL === "") {
      return <IonButton>Loading...</IonButton>;
    } else {
      return <IonButton>Agree To Terms To Scan</IonButton>;
    }
    // if (checked && measurementURL !== "") {
    //   return (<a className='button-style' target='_blank' href={measurementURL}>{loading ? <IonSpinner/> : "Start Scan"}</a>)
    // }
    // else if (checked && measurementURL === "") {
    //   return (<a className='button-style'>Loading...</a>)
    // }
    // else {
    //   return (<a className='button-style'>Agree To Terms To Scan</a>)
    // }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuToggle>
              <IonMenuButton />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle className="mainPageTitle">Face Scan Vitals</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className=" ion-text-center">
        <IonCard className="ion-padding ">
          <IonCardHeader>
            <p>
              <IonIcon
                className="face-scan-icon ion-text-center"
                icon={FaceScan}
              />
            </p>
          </IonCardHeader>
          <IonCardContent>
            <p className="ion-text-center">
              Using a conventional video camera, our cloud-based Affective AI
              engine can detect and measure a wide variety of human affects that
              take the form of physiological and psychological states.
            </p>
          </IonCardContent>

          <a onClick={() => setShowAlertClicked(true)}>
            <IonIcon icon={warning} size="small"></IonIcon> Disclosure
          </a>

          <IonAlert
            isOpen={showAlert}
            onDidDismiss={() => {
              setShowAlert(false);
              openBrowser(measurementURL);
            }}
            header="Face Scan Disclosure"
            message="For Investigational Use Only. The Face Scan App is not a substitute for the clinical judgment of a health care professional. It is intended to improve your awareness of general wellness. It does not diagnose, treat, mitigate or prevent any disease, symptom, disorder or abnormal physical state Consult with your physician or emergency services if you believe you may have a medical issue."
            buttons={["OK"]}
          />

          <IonAlert
            isOpen={showAlertClicked}
            onDidDismiss={() => {
              setShowAlertClicked(false);
            }}
            header="Face Scan Disclosure"
            message="For Investigational Use Only. The Face Scan App is not a substitute for the clinical judgment of a health care professional. It is intended to improve your awareness of general wellness. It does not diagnose, treat, mitigate or prevent any disease, symptom, disorder or abnormal physical state Consult with your physician or emergency services if you believe you may have a medical issue."
            buttons={["OK"]}
          />
          {/* <p>
            For Investigational Use Only. The Face Scan App is not a substitute for the clinical judgment of a health care professional. It is intended to improve your awareness of general wellness. It does not diagnose, treat, mitigate or prevent any disease, symptom, disorder or abnormal physical state Consult with your physician or emergency services if you believe you may have a medical issue.
          </p> */}

          <IonItem>
            <p>
              I agree to the{" "}
              <a onClick={() => setShowTermsModal(true)} className="terms">
                terms of service
              </a>
            </p>
            <IonCheckbox
              slot="start"
              onIonChange={(e) => setChecked(e.detail.checked)}
            />
          </IonItem>

          <RenderMeasurementButton />
        </IonCard>

        <IonList lines="none">
          <IonItem
            detail={false}
            routerLink={"scan/" + mostRecentScan}
            routerDirection="none"
            disabled={mostRecentScan === null}
          >
            <IonIcon slot="end" icon={chevronForward} />
            <IonLabel>Most Recent</IonLabel>
          </IonItem>
          <IonItem
            detail={false}
            routerLink={"/guest-scan"}
            routerDirection="none"
          >
            <IonIcon slot="end" icon={chevronForward} />
            <IonLabel>Guest Scan</IonLabel>
          </IonItem>
          <IonItem
            detail={false}
            routerLink={"/scan-list"}
            routerDirection="none"
          >
            <IonIcon slot="end" icon={chevronForward} />
            <IonLabel>Past Scans</IonLabel>
          </IonItem>
        </IonList>

        {/* <PopupModalTerm /> */}
        <IonModal
          ref={modal}
          isOpen={showTermsModal}
          onWillDismiss={(ev) => onWillDismiss(ev)}
          onDidDismiss={() => setShowTermsModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start"></IonButtons>
              <IonButtons slot="end">
                <IonButton
                  strong={true}
                  onClick={() => setShowTermsModal(false)}
                >
                  Close
                </IonButton>
              </IonButtons>
              <IonTitle>Terms and Conditions</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <PopopTermsOfService />
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
}

export default DeepAffex;
