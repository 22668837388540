/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
//import spinner
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../lib/context";
import React from "react";
import {
  getDocs,
  collection,
  getFirestore,
  doc,
  updateDoc,
} from "firebase/firestore";
import { reformatDOB, formatToIonicDate } from "../../../../lib/helper";

const ChangeHealthData: React.FC = () => {
  const { user, profile } = useContext(UserContext);

  const [dob, setDob] = React.useState(formatToIonicDate(profile ? profile.dob : ""));
  const [weight, setWeight] = React.useState(profile.weight);
  const [heightIn, setHeightIn] = React.useState<number>(profile.heightIn);
  const [heightFt, setHeightFt] = React.useState<number>(profile.heightFt);
  const [gender, setGender] = React.useState(profile.gender);
  const [smoker, setSmoker] = React.useState(profile.smoker);
  const [hypertension, setHypertension] = React.useState(profile.hypertension);
  const [bloodPressureMeds, setBloodPressureMeds] = React.useState(
    profile.bloodPressureMeds
  );
  const [diabetes, setDiabetes] = React.useState(profile.diabetes);
  const [goals, setGoals] = React.useState(profile.goals);
  const [goalsData, setGoalsData] = useState([]);
  const [presentAlert] = useIonAlert();


  const validateDOB = () => {
    var dobHelper = document.getElementById("dobHelp");

    if (dob === undefined) {
      dobHelper.innerHTML = "DOB is required";
      return false;
    } else {
      dobHelper.innerHTML = "";
      return true;
    }
  };

  const validateWeight = () => {
    var weightHelper = document.getElementById("weightHelp");

    if (weight === undefined) {
      weightHelper.innerHTML = "Weight is required";
      return false;
    } else if (weight < 0) {
      weightHelper.innerHTML = "Weight must be greater than 0";
      return false;
    }

    //check if weight is a number
    else if (isNaN(weight)) {
      weightHelper.innerHTML = "Weight must be a number";
      return false;
    } else {
      weightHelper.innerHTML = "";
      return true;
    }
  };

  const validateFeet = () => {
    var feetHelper = document.getElementById("feetHelp");

    if (heightFt === undefined) {
      feetHelper.innerHTML = "Feet is required";
      return false;
    } else if (heightFt < 0) {
      feetHelper.innerHTML = "Feet must be greater than 0";
      return false;
    }

    //check if weight is a number
    else if (isNaN(heightFt)) {
      feetHelper.innerHTML = "Feet must be a number";
      return false;
    } else {
      feetHelper.innerHTML = "";
      return true;
    }
  };

  const validateInches = () => {
    var inchHelper = document.getElementById("inchHelp");

    if (heightIn === undefined) {
      inchHelper.innerHTML = "Inches are required";
      return false;
    } else if (heightIn < 0) {
      inchHelper.innerHTML = "Inches must be greater than 0";
      return false;
    }

    //check if inches are between 0 and 12
    else if (heightIn > 12) {
      inchHelper.innerHTML = "Inches must be between 0 and 12";
      return false;
    }

    //check if weight is a number
    else if (isNaN(heightIn)) {
      inchHelper.innerHTML = "Inches must be a number";
      return false;
    } else {
      inchHelper.innerHTML = "";
      return true;
    }
  };

  const validateGender = () => {
    var genderHelp = document.getElementById("genderHelp");

    if (gender === undefined) {
      genderHelp.innerHTML = "Gender is required";
      return false;
    } else {
      genderHelp.innerHTML = "";
      return true;
    }
  };

  const validateHypertension = () => {
    var hypertensionHelper = document.getElementById("hypertensionHelp");

    if (hypertension === undefined) {
      hypertensionHelper.innerHTML = "Hypertension is required";
      return false;
    } else {
      hypertensionHelper.innerHTML = "";
      return true;
    }
  };

  const validateMeds = () => {
    var medsHelper = document.getElementById("medsHelp");

    if (bloodPressureMeds === undefined) {
      medsHelper.innerHTML = "Bloodpressure medication is required";
      return false;
    } else {
      medsHelper.innerHTML = "";
      return true;
    }
  };

  const validateDiabetic = () => {
    var diabeticHelper = document.getElementById("diabeticHelp");

    if (diabetes === undefined) {
      diabeticHelper.innerHTML = "Diabetes is required";
      return false;
    } else {
      diabeticHelper.innerHTML = "";
      return true;
    }
  };

  const validateSmoke = () => {
    var smokeHelper = document.getElementById("smokeHelp");

    if (smoker === undefined) {
      smokeHelper.innerHTML = "Smoker is required";
      return false;
    } else {
      smokeHelper.innerHTML = "";
      return true;
    }
  };

  const validateGoals = () => {
    var goalsHelper = document.getElementById("goalsHelp");

    if (goals === undefined) {
      goalsHelper.innerHTML = "Please select at least one goal";
      return false;
    } else {
      goalsHelper.innerHTML = "";
      return true;
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();

    if (
      validateDOB &&
      validateWeight() &&
      validateFeet() &&
      validateInches() &&
      validateGender() &&
      validateSmoke() &&
      validateHypertension() &&
      validateMeds() &&
      validateDiabetic()
    ) {
      const ref = doc(getFirestore(), "users", user.uid);

      // const dobArray = dob.split("-");
      // const dobYear = dobArray[0];
      // const dobMonth = dobArray[1];
      // const dobDay = dobArray[2];
      const formatedDob = reformatDOB(dob);

      await updateDoc(ref, {
        dob: formatedDob,
        weight: Number(weight),
        heightIn: Number(heightIn),
        heightFt: Number(heightFt),
        bloodPressureMeds: bloodPressureMeds,
        diabetes: diabetes,
        hypertension: hypertension,
        smoker: smoker,
        gender: gender,
      });

      presentAlert({
        header: 'Success',
        message: 'Your health data has been updated',
        buttons: ['OK'],
      })

    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="tabs/home" />
          </IonButtons>

          <IonTitle className="mainPageTitle">Update Health Data</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        
            <form onSubmit={updateProfile}>
              <IonItem>
                <IonLabel position="stacked">DOB</IonLabel>
                <IonInput
                  type="date"
                  placeholder="DOB"
                  value={dob}
                  onIonChange={(e: any) => {
                    setDob(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateDOB();
                  }}
                  name="dob"
                ></IonInput>
                <p className="formHelp" id="dobHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Weight</IonLabel>
                <IonInput
                  type="number"
                  placeholder="190"
                  value={weight}
                  onIonChange={(e: any) => {
                    setWeight(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateWeight();
                  }}
                ></IonInput>
                <p className="formHelp" id="weightHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Height (ft in)</IonLabel>
                <IonInput
                  placeholder="feet"
                  type="number"
                  value={heightFt}
                  onIonChange={(e: any) => {
                    setHeightFt(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateFeet();
                  }}
                ></IonInput>
                <p className="formHelp" id="feetHelp"></p>
                <IonInput
                  placeholder="inches"
                  type="number"
                  value={heightIn}
                  onIonChange={(e: any) => {
                    setHeightIn(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateInches();
                  }}
                ></IonInput>
                <p className="formHelp" id="inchHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Gender at Birth</IonLabel>
                <IonSelect
                  value={gender}
                  onIonChange={(e) => setGender(e.detail.value)}
                  onIonDismiss={(e: any) => {
                    validateGender();
                  }}
                >
                  <IonSelectOption value="male">Male</IonSelectOption>
                  <IonSelectOption value="female">Female</IonSelectOption>
                </IonSelect>
                <p className="formHelp" id="genderHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Do you smoke?</IonLabel>
                <IonSelect
                  value={smoker}
                  onIonChange={(e) => setSmoker(e.detail.value)}
                  onIonDismiss={(e: any) => {
                    validateSmoke();
                  }}
                >
                  <IonSelectOption value="yes">Yes</IonSelectOption>
                  <IonSelectOption value="no">No</IonSelectOption>
                </IonSelect>
                <p className="formHelp" id="smokeHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">
                  Do you have hypertension (high blood pressure)?
                </IonLabel>
                <IonSelect
                  value={hypertension}
                  onIonChange={(e) => setHypertension(e.detail.value)}
                  onIonDismiss={(e: any) => {
                    validateHypertension();
                  }}
                >
                  <IonSelectOption value="yes">Yes</IonSelectOption>
                  <IonSelectOption value="no">No</IonSelectOption>
                </IonSelect>
                <p className="formHelp" id="hypertensionHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">
                  Are you taking any blood pressure medication?
                </IonLabel>
                <IonSelect
                  value={bloodPressureMeds}
                  onIonChange={(e) => setBloodPressureMeds(e.detail.value)}
                  onIonDismiss={(e: any) => {
                    validateMeds();
                  }}
                >
                  <IonSelectOption value="yes">Yes</IonSelectOption>
                  <IonSelectOption value="no">No</IonSelectOption>
                </IonSelect>
                <p className="formHelp" id="medsHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Are you diabetic?</IonLabel>
                <IonSelect
                  value={diabetes}
                  onIonChange={(e) => setDiabetes(e.detail.value)}
                  onIonDismiss={(e: any) => {
                    validateDiabetic();
                  }}
                >
                  <IonSelectOption value="type1">Type 1</IonSelectOption>
                  <IonSelectOption value="type2">Type 2</IonSelectOption>
                  <IonSelectOption value="no">No</IonSelectOption>
                </IonSelect>
                <p className="formHelp" id="diabeticHelp"></p>
              </IonItem>

              <div className="ion-padding-top" id="login-help"></div>

              <IonButton type="submit" expand="block" className="ion-padding ion-margin-bottom">
                Update
              </IonButton>
            </form>
      </IonContent>
    </IonPage>
  );
};

export default ChangeHealthData;
