import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSearchbar,
  IonSlide,
  IonSlides,
  IonThumbnail,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
  limit,
  orderBy,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import "./Explore.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

//import in app browser from capacitor
import { Browser } from "@capacitor/browser";
import { connectStorageEmulator } from "firebase/storage";
import { chevronBack, chevronForward } from "ionicons/icons";

const Explore: React.FC = () => {
  // const { user, profile } = useContext(UserContext);

  const openBrowser = async (url) => {
    await Browser.open({ url: url });
  };

  const [post, setPost] = useState([]);
  const [mostRecentWorkout, setMostRecentWorkout] = useState([]);
  const [mostRecentRecipe, setMostRecentRecipe] = useState([]);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const getSearchResults = (searchString) => {
    return new Promise((resolve, reject) => {
      const queryArray = searchString.split(" ");
      //set array to lower case
      const queryArrayLowerCase = queryArray.map((item) => item.toLowerCase());

      var searchResults = [];

      queryArrayLowerCase.forEach(async (item) => {
        const q = query(
          collection(getFirestore(), "content"),
          where("isActive", "==", true),
          where("keyWords", "array-contains", item)
        );

        getDocs(q).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            searchResults.push(doc.id, doc.data());
          });
        });
      });

      resolve(searchResults);
    });
  };

  const handleChange = async (ev) => {
    let searchStringValue = "";
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) searchStringValue = target.value!.toLowerCase();

    const queryArray = searchStringValue.split(" ");
    //set array to lower case
    const queryArrayLowerCase = queryArray.map((item) => item.toLowerCase());

    //remove duplicates from query array
    const queryArrayLowerCaseNoDuplicates = queryArrayLowerCase.filter(
      (item, index) => queryArrayLowerCase.indexOf(item) === index
    );

    var searchResults = [];

    queryArrayLowerCaseNoDuplicates.forEach(async (item) => {
      const q = query(
        collection(getFirestore(), "content"),
        where("isActive", "==", true),
        where("keyWords", "array-contains", item)
      );

      getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (!searchResults.find((o) => o.id === doc.id)) {
            searchResults.push({ id: doc.id, ...doc.data() });
          }
        });
      });
    });

    console.log("searchResults", searchResults);

    setResults(searchResults);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [results]);

  const getMostRecentWorkout = async () => {
    const q = query(
      collection(getFirestore(), "content"),
      limit(5),
      where("isActive", "==", true),
      where("tags", "array-contains", "workout"),
      orderBy("date", "desc")
    );

    var workoutList = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      workoutList.push(doc.data());
    });

    // console.log("workouts", workoutList);
    setMostRecentWorkout(workoutList);
  };

  const getMostRecentRecipe = async () => {
    const q = query(
      collection(getFirestore(), "content"),
      limit(5),
      where("isActive", "==", true),
      where("tags", "array-contains", "recipe"),
      orderBy("date", "desc")
    );

    var recipeList = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      recipeList.push(doc.data());
    });

    // console.log("recipes", recipeList);
    setMostRecentRecipe(recipeList);
  };

  const GetPost = async () => {
    const q = query(
      collection(getFirestore(), "content"),
      limit(5),
      where("isActive", "==", true),
      orderBy("date", "desc")
    );

    var postList = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      postList.push(doc.data());
    });

    // console.log("recentPost", postList);
    setPost(postList);
  };

  useEffect(() => {
    GetPost();
    getMostRecentWorkout();
    getMostRecentRecipe();
  }, []);

  return (
    <>
      <IonPage id="main">
        <IonHeader>
          <IonToolbar className="mainHeaderBackground">
          <IonButtons slot="start">
              <IonMenuToggle className="white">
                <IonMenuButton className="white" />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle className="mainPageTitleTEst">Explore</IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonSearchbar
            debounce={2000}
            onIonChange={(ev) => {
              handleChange(ev);
            }}
          ></IonSearchbar>
          {loading ? (
            <></>
          ) : (
            <>
              <IonList>
                {results.map((document) => {
                  return (
                    <IonItem onClick={() => openBrowser(document.link)}>
                      {document.title}
                    </IonItem>
                  );
                })}
              </IonList>
            </>
          )}

          <p className="exploreSectionTitles ion-padding-start">
            Recommended for you
          </p>

          <Swiper
            onSlideChange={() => console.log("slide change")}
            slidesPerView={"auto"}
            spaceBetween={10}
          >
            {post.map((document) => {
              return (
                <SwiperSlide className="recForYouCard">
                  <IonCard
                    className="recForYouCard"
                    onClick={() => openBrowser(document["link"])}
                  >
                    <div className="imgContainerForPost">
                      <img
                        src={document["photo"]}
                        alt=""
                        className="recForYouImg"
                      />
                      {document["type"] === "Video" ? (
                        <>
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/helpers%2Findex-media-cover-art-play-button-overlay-5.png?alt=media&token=7f9a82cb-74c7-4511-98bc-735c0eec3426"
                            alt="play"
                            className="playBtn"
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <IonCardHeader>
                      <IonCardTitle className="ion-text-start post-card-title">
                        {document["title"]}
                      </IonCardTitle>
                      <IonCardSubtitle className="ion-text-start post-card-subtitle">
                        {document["type"]}{" "}
                        {document["type"] === "Video" ? (
                          <>
                            {document["length"] === undefined ? null : (
                              <>{document["length"]} minutes</>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </IonCardSubtitle>
                    </IonCardHeader>
                  </IonCard>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <p className="exploreSectionTitles ion-padding-start ion-padding-bottom">
            Categories
          </p>
          <div className="ion-padding-start">
            <Swiper slidesPerView={"auto"} spaceBetween={10}>
              <SwiperSlide className="categorySlides">
                <IonRouterLink routerLink={"/all"}>
                  <IonChip>All</IonChip>
                </IonRouterLink>
              </SwiperSlide>
              <SwiperSlide className="categorySlides">
                <IonRouterLink routerLink={"/nutrition"}>
                  <IonChip>Nutrition</IonChip>
                </IonRouterLink>
              </SwiperSlide>
              <SwiperSlide className="categorySlides">
                <IonRouterLink routerLink={"/fitness"}>
                  <IonChip>Fitness</IonChip>
                </IonRouterLink>
              </SwiperSlide>
              <SwiperSlide className="categorySlides">
                <IonRouterLink routerLink={"/mental-health"}>
                  <IonChip>Mental Health</IonChip>
                </IonRouterLink>
              </SwiperSlide>
              <SwiperSlide className="categorySlides">
                <IonRouterLink routerLink={"/rehabilitation"}>
                  <IonChip>Rehabilitation</IonChip>
                </IonRouterLink>
              </SwiperSlide>
              <SwiperSlide className="categorySlides">
                <IonRouterLink routerLink={"/addiction"}>
                  <IonChip>Addiction</IonChip>
                </IonRouterLink>
              </SwiperSlide>
            </Swiper>
          </div>
          {/* <Swiper slidesPerView={"auto"} spaceBetween={10}>
              <SwiperSlide className="categorySlides">
                <IonItem routerLink={"/all"} lines="none" detail={false}>
                  <IonChip>All</IonChip>
                </IonItem>
              </SwiperSlide>
              <SwiperSlide className="categorySlides">
                <IonItem routerLink={"/nutrition"} lines="none" detail={false}>
                  <IonChip>Nutrition</IonChip>
                </IonItem>
              </SwiperSlide>
              <SwiperSlide className="categorySlides">
                <IonItem routerLink={"/fitness"} lines="none" detail={false}>
                  <IonChip>Fitness</IonChip>
                </IonItem>
              </SwiperSlide>
              <SwiperSlide className="categorySlides">
                <IonItem routerLink={"/mental-health"} lines="none" detail={false}>
                  <IonChip>Mental Health</IonChip>
                </IonItem>
              </SwiperSlide>
              <SwiperSlide className="categorySlides">
                <IonItem routerLink={"/rehabilitation"} lines="none" detail={false}>
                  <IonChip>Rehabilitation</IonChip>
                </IonItem>
              </SwiperSlide>
              <SwiperSlide className="categorySlides">
                <IonItem routerLink={"/addiction"} lines="none" detail={false}>
                  <IonChip>Addiction</IonChip>
                </IonItem>
              </SwiperSlide>
            </Swiper> */}

          <p className="exploreSectionTitles ion-padding-start ion-padding-bottom ion-padding-top">
            Recent Workouts
          </p>

          <Swiper
            onSlideChange={() => console.log("slide change")}
            slidesPerView={"auto"}
            spaceBetween={10}
          >
            {mostRecentWorkout.map((document) => {
              return (
                <SwiperSlide className="recentWorkoutCard">
                  <IonCard
                    className="recentWorkoutCard"
                    onClick={() => openBrowser(document["link"])}
                  >
                    <div className="imgContainerForPost">
                      <img
                        src={document["photo"]}
                        alt=""
                        className="recentWorkoutImg"
                      />
                      {document["type"] === "Video" ? (
                        <>
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/helpers%2Findex-media-cover-art-play-button-overlay-5.png?alt=media&token=7f9a82cb-74c7-4511-98bc-735c0eec3426"
                            alt="play"
                            className="playBtn"
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <IonCardHeader>
                      <IonCardTitle className="ion-text-start post-card-title">
                        {document["title"]}
                      </IonCardTitle>
                      <IonCardSubtitle className="ion-text-start post-card-subtitle">
                        {document["type"]}{" "}
                        {document["type"] === "Video" ? <></> : <></>}
                      </IonCardSubtitle>
                    </IonCardHeader>
                  </IonCard>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <p className="exploreSectionTitles ion-padding-start ion-padding-bottom ion-padding-top">
            Trending Recipes
          </p>

          <IonList>
            {mostRecentRecipe.map((document) => {
              return (
                <IonCard
                  className="recipeCard"
                  onClick={() => openBrowser(document["link"])}
                >
                  <IonCardHeader className="recipeNoPadding">
                    <IonGrid className="recipeNoPadding">
                      <IonRow>
                        <IonCol className="recipeNoPadding" size="4">
                          <IonImg
                            className="recipeImg"
                            src={document["photo"]}
                          />
                        </IonCol>
                        <IonCol className="recipeNoPadding recipeWords">
                          <IonCardTitle className="ion-text-start post-card-title">
                            {document["title"]}
                          </IonCardTitle>
                          <IonCardSubtitle className="ion-text-start post-card-subtitle">
                            {document["calories"] === null ||
                            document["calories"] === "" ||
                            document["calories"] === undefined ? null : (
                              <>{document["calories"]} cals </>
                            )}
                          </IonCardSubtitle>
                        </IonCol>
                        <IonCol className="recipeWords">
                          <IonIcon icon={chevronForward} size="large"></IonIcon>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCardHeader>
                </IonCard>
              );
            })}
          </IonList>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Explore;
