import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import React, { useContext } from "react";
import "firebase/compat/auth";
import { profile } from "console";
import { UserContext } from "../../../lib/context";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import TimeZone from "./TimeZone";

const Preferences: React.FC = () => {


  
  const { user, profile } = useContext(UserContext);


  const updateSMSNotifications = async () => {
    const db = getFirestore();
    const userDoc = doc(db, "users", user.uid);
    await updateDoc(userDoc, {
      smsNotificationsOn: !profile.smsNotificationsOn,
    });
  };


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="tabs/home" />
          </IonButtons>

          <IonTitle className="mainPageTitle">Preferences</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonList>
          <IonItem>
            <IonLabel>SMS Notifications</IonLabel>
            <IonToggle slot="end" onClick={updateSMSNotifications} checked={profile.smsNotificationsOn}></IonToggle>
          </IonItem>
          <TimeZone />
          {/* <IonItem>
            <IonLabel>Checked Toggle</IonLabel>
            <IonToggle slot="end" checked={true}></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Disabled Toggle</IonLabel>
            <IonToggle slot="end" disabled={true}></IonToggle>
          </IonItem>
          <IonItem>
            <IonLabel>Disabled Checked Toggle</IonLabel>
            <IonToggle slot="end" checked={true} disabled={true}></IonToggle>
          </IonItem> */}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default Preferences;
