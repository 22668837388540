import { UserContext } from "../../lib/context";
import { useContext, useEffect, useState } from "react";
import FaceScanOnlyRouter from "./FacescanOnly/FacescanOnlyRouter";
import TelemedicineOnlyRouter from "./TelemedicineOnly/TelemedicineOnlyRouter";
import FullAccessRouter from "./FullAccess/FullAccessRouter";
import NotAllowed from "./NotAllowed";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonLabel,
  IonInput,
  IonItem,
  isPlatform,
  useIonToast,
  IonRouterLink,
  useIonRouter,
  IonText,
  IonIcon,
  IonToast,
  IonPage,
  IonSpinner,
} from "@ionic/react";
import "./PrivateRouter.css";
import { doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
// import frebase messaging

import {
  PushNotificationSchema,
  PushNotifications,
  Token,
  ActionPerformed,
} from "@capacitor/push-notifications";
import { Toast } from "@capacitor/toast";
import { Router } from "workbox-routing";
import {
  alertCircleOutline,
  constructOutline,
  sadOutline,
} from "ionicons/icons";
import { getAuth, updatePassword } from "firebase/auth";

const PrivateRouter = () => {
  //user user context
  const { user, profile, profileLoading, error } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [validPasswordConfirm, setValidPasswordConfirm] = useState(false);
  const [presentToast] = useIonToast();

  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const router = useIonRouter();

  const nullEntry: any[] = [];
  const [notifications, setnotifications] = useState(nullEntry);

  useEffect(() => {
    PushNotifications.checkPermissions()
      .then((res) => {
        if (res.receive !== "granted") {
          PushNotifications.requestPermissions()
            .then((res) => {
              if (res.receive === "denied") {
                // showToast('Push Notification permission denied', "WAD");
              } else {
                // showToast('Push Notification permission granted',"awd");
                register();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          // showToast('Push Notification permission granted 1',"awd");
          register();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const register = () => {
    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register().catch((err) => {
      console.log("err", err);
    });

    PushNotifications.addListener(
      "registrationError",
      (error: any) => {}
    ).catch((err) => {
      console.log("err", err);
    });

    PushNotifications.addListener(
      "registrationError",
      (error: any) => {}
    ).catch((err) => {
      console.log("err", err);
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        setnotifications((notifications) => [
          ...notifications,
          {
            id: notification.id,
            title: notification.title,
            body: notification.body,
            type: "foreground",
          },
        ]);
      }
    ).catch((err) => {
      console.log("err", err);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        setnotifications((notifications) => [
          ...notifications,
          {
            id: notification.notification.data.id,
            title: notification.notification.data.title,
            body: notification.notification.data.body,
            type: "action",
          },
        ]);
      }
    ).catch((err) => {
      console.log("err", err);
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      const tokenValue = token.value;
      const db = getFirestore();
      const userRef = doc(db, "FCMToken", user.uid);
      console.log("tokenValue", tokenValue);

      const data = {};

      if (isPlatform("ios")) {
        data["ios"] = tokenValue;
      } else {
        data["android"] = tokenValue;
      }

      updateDoc(userRef, data).catch((error) => {
        console.log(error);
        setDoc(userRef, data).catch((error) => {
          console.log(error);
        });
      });
    }).catch((err) => {
      console.log("err", err);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      alert("Error on registration: " + JSON.stringify(error));
    }).catch((err) => {
      console.log("err", err);
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        console.log("ETTETETETDGEWUBFAWEFB 1");
        console.log("pushNotificationReceived", notification);
        setnotifications((notifications) => [
          ...notifications,
          {
            id: notification.id,
            title: notification.title,
            body: notification.body,
            type: "foreground",
          },
        ]);
        showToast(notification.body, notification.data.sender);
      }
    ).catch((err) => {
      console.log("err", err);
    });

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (notification: ActionPerformed) => {
        setnotifications(nullEntry);
        PushNotifications.removeAllDeliveredNotifications();
        if (notification.notification.data.type === "chat") {
          window.location.href = `/tabs/chat/${notification.notification.data.id}`;
        }
        if (notification.notification.data.type === "content") {
          window.location.href = `/tabs/explore/`;
        }
        if (notification.notification.data.type === "session") {
          window.location.href = `/session/${notification.notification.data.id}`;
        }
      }
    ).catch((error) => {
      console.log(error);
    });
  };

  const showToast = async (msg: string, sender: string) => {
    presentToast({
      message: msg,
      duration: 2500,
      position: "top",
      color: "danger",
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      PushNotifications.removeAllDeliveredNotifications().catch((err) => {
        console.log("err", err);
      });
    }, 3000);
  }, []);

  const checkIfTempPasswordChanges = async () => {
    if (profile && !profile.tempPasswordChanged) {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    checkIfTempPasswordChanges();
  }, [profile]);

  const validatePassword = () => {
    var passwordHelper = document.getElementById("passwordHelp")!;
    //pasword regex
    var regex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (password === undefined || password === "") {
      passwordHelper.innerHTML = "Password is required";
      setValidPassword(false);
      return false;
    } else if (password.length < 8) {
      passwordHelper.innerHTML = "Password must be at least 8 characters";
      setValidPassword(false);
      return false;
    } else if (!regex.test(password)) {
      passwordHelper.innerHTML =
        "Password must contain one letter, one number, and one special character";
      setValidPassword(false);
      return false;
    } else {
      passwordHelper.innerHTML = "";
      setValidPassword(true);
      return true;
    }
  };

  const validateConfirmPassword = () => {
    var confirmPasswordHelper = document.getElementById("passwordConfirmHelp");

    if (passwordConfirm === undefined || passwordConfirm === "") {
      confirmPasswordHelper.innerHTML = "Confirm password is required";
      setValidPasswordConfirm(false);
      return false;
    } else if (passwordConfirm !== password) {
      confirmPasswordHelper.innerHTML = "Passwords do not match";
      setValidPasswordConfirm(false);
      return false;
    } else {
      confirmPasswordHelper.innerHTML = "";
      setValidPasswordConfirm(true);
      return true;
    }
  };

  const signOutUser = () => {

    const auth = getAuth();
    auth.signOut().then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
      console.log(error);
    });
  };

  const updatePasswordFunction = async () => {
    setResetPasswordLoading(true);
    console.log("Starting update password");
    if (password === passwordConfirm) {
      // const user = firebase.auth().currentUser;
      console.log("NEW UPDATE IS HERE");


      updatePassword(user, password)
        .then(async () => {
          // Update successful.
          const ref = doc(getFirestore(), "users", user.uid);
          await updateDoc(ref, { tempPasswordChanged: true })
            .then(() => {
              console.log("Document successfully updated!");
              setResetPasswordLoading(false);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log("an error occured");
          setResetPasswordLoading(false);

          console.log(error);
          alert(
            "Your session has expired. Please log in again with temporary password"
          )
          signOutUser();
         
        });
    } else {
      setResetPasswordLoading(false);
      alert("Passwords do not match");
    }
  };

  useEffect(() => {
    //check if the elements exist
    if (
      document.getElementById("passwordHelp") &&
      document.getElementById("passwordConfirmHelp")
    ) {
      //if they do, then check if the password and confirm password are valid
      validatePassword();
      validateConfirmPassword();
    }
  }, [password, passwordConfirm]);

  useEffect(() => {
    var tracking = {
      lastUse: new Date(),
    };

    if (isPlatform("android")) {
      tracking["android"] = true;
      tracking["androidLastUse"] = new Date();
    } else if (isPlatform("ios")) {
      tracking["ios"] = true;
      tracking["iosLastUse"] = new Date();
    } else if (isPlatform("desktop")) {
      tracking["desktop"] = true;
      tracking["desktopLastUse"] = new Date();
    } else if (isPlatform("mobile")) {
      tracking["mobile"] = true;
      tracking["mobileLastUse"] = new Date();
    } else if (isPlatform("hybrid")) {
      tracking["hybrid"] = true;
      tracking["hybridLastUse"] = new Date();
    } else if (isPlatform("cordova")) {
      tracking["cordova"] = true;
      tracking["cordovaLastUse"] = new Date();
    } else if (isPlatform("capacitor")) {
      tracking["capacitor"] = true;
      tracking["capacitorLastUse"] = new Date();
    } else if (isPlatform("electron")) {
      tracking["electron"] = true;
      tracking["electronLastUse"] = new Date();
    } else if (isPlatform("pwa")) {
      tracking["pwa"] = true;
      tracking["pwaLastUse"] = new Date();
    } else if (isPlatform("mobileweb")) {
      tracking["mobileweb"] = true;
      tracking["mobilewebLastUse"] = new Date();
    } else if (isPlatform("ipad")) {
      tracking["ipad"] = true;
      tracking["ipadLastUse"] = new Date();
    } else if (isPlatform("iphone")) {
      tracking["iphone"] = true;
      tracking["iphoneLastUse"] = new Date();
    } else if (isPlatform("phablet")) {
      tracking["phablet"] = true;
      tracking["phabletLastUse"] = new Date();
    } else if (isPlatform("tablet")) {
      tracking["tablet"] = true;
      tracking["tabletLastUse"] = new Date();
    }

    updateDoc(doc(getFirestore(), "tracking", user.uid), tracking)
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  }, [user]);

  if (profileLoading) {
    return (
      <IonPage>
        <IonContent fullscreen={true}>
          <div className="middle">
            <div className="center-container">
              <>
                <IonSpinner></IonSpinner>{" "}
                <IonLabel>
                  <p>Fetching your profile data...</p>
                  {error && <p>{error.message}</p>}
                </IonLabel>
              </>
            </div>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  if (profile && !profile.tempPasswordChanged) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>New Password</IonTitle>
            <IonButtons slot="end"></IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <p className="ion-margin">
            We need to create a new password for you so your account is more
            secure.
          </p>
          <IonItem>
            <IonLabel position="floating">New Password</IonLabel>
            <IonInput
              type="password"
              value={password}
              onIonChange={(e: any) => {
                setPassword(e.detail.value);
              }}
              onIonBlur={() => {
                validatePassword();
              }}
            />
            <p className="formHelp" id="passwordHelp"></p>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Confirm New Password</IonLabel>
            <IonInput
              type="password"
              value={passwordConfirm}
              onIonChange={(e: any) => {
                setPasswordConfirm(e.detail.value);
              }}
              onIonBlur={() => {
                validateConfirmPassword();
              }}
            />
            <p className="formHelp" id="passwordConfirmHelp"></p>
          </IonItem>
          <IonButton
            className="firstButton"
            expand="block"
            disabled={!validPassword || !validPasswordConfirm}
            onClick={updatePasswordFunction}
          >
            {resetPasswordLoading ? (
              <IonSpinner name="lines-small" />
            ) : (
              "CONTINUE"
            )}
          </IonButton>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <>
      {profile && profile.role === "coach" ? <NotAllowed /> : null}
      {profile && profile.access === "facescanOnly" ? (
        <FaceScanOnlyRouter />
      ) : null}
      {profile && profile.access === "telemedicineOnly" ? (
        <TelemedicineOnlyRouter />
      ) : null}
      {profile && profile.access === "fullAccess" ? <FullAccessRouter /> : null}
    </>
  );
};

export default PrivateRouter;
