import { CometChat } from "@cometchat-pro/chat";
import {
  IonBackButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../lib/context";
import {
  CometChatConversation,
  CometChatMessages,
} from "../chat/cometchat-pro-react-ui-kit-master/CometChatWorkspace/src";
import Loader from "../Loader";

import { Keyboard } from "@capacitor/keyboard";

import "./Meeting.css";

const Meeting: React.FC = (props) => {
  const { user, profile } = useContext(UserContext);
  const [loggedInUser, setLoggedInUser] = useState<any>(false);
  const [meetingId, setMeetingId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [callEnded, setCallEnded] = useState<boolean>(false);

  const [presentAlert] = useIonAlert();

  /**
   * get first day of month and return in format yyyy-mm-ddT00:00:00
   * @param {string} str
   * @return {string} string with no special char
   */
  function removeSpecialChar(str) {
    return str.replace(/[^a-zA-Z0-9]/g, "");
  }

  function loginCometUser() {
    const authKey = process.env.REACT_APP_COMET_AUTH_KEY;
    const uid = removeSpecialChar(user.uid);

    CometChat.login(uid, authKey).then(
      (user) => {
        console.log("CometChat Login Successfull");
        setLoggedInUser(true);
      },
      (error) => {
        console.log("Login failed with exception:", { error });
        setLoggedInUser(false);
      }
    );
  }

  const startCall = () => {
    const joinCallButton = document.getElementById("videoCallBtnGroup");
    joinCallButton.click();

    // set display to none of joinCallButon
    joinCallButton.style.display = "none";
  };

  const presentJoinCallAlert = () => {
    presentAlert({
      // header: 'Alert',
      subHeader: "You are about to join this session",
      message: "Are you sure you would like to join?",
      backdropDismiss: false,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {
            // redirect back one page
            window.history.back();
          },
        },
        {
          text: "Join",
          role: "join",
          handler: () => {
            // console.log('Alert confirmed');
            startCall();
          },
        },
      ],
    });

    // let callSettings = new CometChat.CallSettingsBuilder()
    //   .enableDefaultLayout(defaultLayout)
    //   .setSessionID(sessionID)
    //   .setIsAudioOnlyCall(audioOnly)
    //   .build();

    // CometChat.startCall(
    //   callSettings,
    //   document.getElementById("callScreen"),
    //   new CometChat.OngoingCallListener({
    //     onUserListUpdated: (userList) => {
    //       console.log("user list:", userList);
    //       setLoading(false);
    //     },
    //     onCallEnded: (call) => {
    //       console.log("Call ended:", call);
    //       setCallEnded(true);
    //     },
    //     onError: (error) => {
    //       console.log("Error :", error);
    //     },
    //     onMediaDeviceListUpdated: (deviceList) => {
    //       console.log("Device List:", deviceList);
    //       setLoading(false);
    //     },
    //     onUserMuted: (userMuted, userMutedBy) => {
    //       // This event will work in JS SDK v3.0.2-beta1 & later.
    //       console.log("Listener => onUserMuted:", userMuted, userMutedBy);
    //     },
    //     onScreenShareStarted: () => {
    //       // This event will work in JS SDK v3.0.3 & later.
    //       console.log("Screen sharing started.");
    //     },
    //     onScreenShareStopped: () => {
    //       // This event will work in JS SDK v3.0.3 & later.
    //       console.log("Screen sharing stopped.");
    //     },
    //     onCallSwitchedToVideo: (
    //       sessionID,
    //       callSwitchInitiatedBy,
    //       callSwitchAcceptedBy
    //     ) => {
    //       // This event will work in JS SDK v3.0.8 & later.
    //       console.log("call switched to video:", {
    //         sessionID,
    //         callSwitchInitiatedBy,
    //         callSwitchAcceptedBy,
    //       });
    //     },
    //   })
    // );
  };

  const getLastPartOfUrl = () => {
    const url = window.location.href;
    const urlParts = url.split("/");
    const lastPart = urlParts[urlParts.length - 1];
    console.log("last part of url", lastPart);
    if (lastPart !== "chat" && lastPart !== "messages") {
      setMeetingId(lastPart);
    }
    return lastPart;
  };

  useEffect(() => {
    //set timeout for login

    loginCometUser();
    presentJoinCallAlert();
    //make sure keyboard is closed
    setTimeout(() => {
      Keyboard.hide();
    }, 2000);
  }, []);

  useEffect(() => {
    getLastPartOfUrl();
  }, []);

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonToolbar className="mainHeaderBackground">
            <IonButtons slot="start">
              <IonBackButton
                className="mainPageTitleTEst"
                defaultHref="/tabs/home"
              />
            </IonButtons>
            <IonTitle className="mainPageTitleTEst">Meeting</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <div className="meeting__left">
          <div id="callScreen"></div>
        </div>

        <>
          {" "}
          <div className="meeting">
            <div className="meeting__left">
            </div>
            <div className="meeting__right">
              <CometChatMessages chatWithGroup={meetingId} />
            </div>
          </div>
        </>
        <div id="callScreen"></div> */}
        {/* {callEnded ? (
          <></>
        ) : (
          <>
            {" "}
            <div className="meetingWindow">
              <div id="callScreen">
                {loading ? <Loader /> : null}
              </div>
            </div>
          </>
        )}
        {meetingId !== "" ? (
          <div>
            <CometChatMessages chatWithGroup={meetingId} />
          </div>
        ) : null} */}
        {loggedInUser && profile ? (
          <CometChatConversation chatWithGroup={meetingId} />
        ) : (
          <Loader />
        )}
      </IonContent>
    </IonPage>
  );
};

export default Meeting;
