import {
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../lib/context";
import {
  addCircleOutline,
  calendar,
  chevronBackSharp,
  chevronForwardSharp,
  trashOutline,
} from "ionicons/icons";
import "./Journal.css";
import React from "react";
import {
  collection,
  getFirestore,
  query,
  where,
  onSnapshot,
  deleteDoc,
  doc,
} from "firebase/firestore";
import LogMeal from "../../../components/Journal/LogMeal";
import LogExercise from "../../../components/Journal/LogExercise";
import LogMood from "../../../components/Journal/LogMood";
import LogMetrics from "../../../components/Journal/LogMetrics";
import {
  getCurrentDate,
  decrementDate,
  incrementDate,
} from "../../../HelperFunctions/dates";
import SuperHappyFilled from "../../../assets/icons/super-happy-filled.svg";
import HappyFilled from "../../../assets/icons/happy-filled.svg";
import Neutral from "../../../assets/icons/neutral-filled.svg";
import SadFilled from "../../../assets/icons/sad-filled.svg";
import SuperSadFilled from "../../../assets/icons/super-sad-filled.svg";
import { InactiveAccountHeader } from "../../../components/InactiveAccountHeader";
import LogGlucose from "../../../components/Journal/LogGlucose";
import LogGlucose2 from "../../../components/Journal/LogGlucose2";

const Journal: React.FC = (props) => {
  const { user } = useContext(UserContext);

  const [date, setDate] = useState(getCurrentDate());

  const [mealLogs, setMealLogs] = useState([]);
  const [exerciseLogs, setExerciseLogs] = useState([]);
  const [moodLogs, setMoodLogs] = useState([]);
  const [metricsLogs, setMetricsLogs] = useState([]);

  const [showDataPicker, setShowDataPicker] = useState(false);

  const ShowCurrentDate = () => {
    if (date === getCurrentDate()) {
      return (
        <>
          <div
            className="date-wrapper ion-text-center"
            onClick={() => setShowDataPicker(!showDataPicker)}
          >
            <a
              className="date ion-text-center "
              onClick={() => setShowDataPicker(!showDataPicker)}
            >
              Today
            </a>
            <IonIcon
              className="ion-padding-left icon"
              color="primary"
              icon={calendar}
            />
          </div>
        </>
      );
    } else {
      return (
        <div>
          <a
            className="date"
            onClick={() => setShowDataPicker(!showDataPicker)}
          >
            {date}
          </a>
          <IonIcon
            className="ion-padding-left icon"
            color="primary"
            icon={calendar}
          />
        </div>
      );
    }
  };

  //Get Meal Logs from firebase, unsubs when unmounting
  useEffect(() => {
    const q = query(
      collection(getFirestore(), "meals"),
      where("uid", "==", user.uid),
      where("date", "==", date)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const logs = [];
      querySnapshot.forEach((doc) => {
        logs.push({ id: doc.id, ...doc.data() });
      });

      setMealLogs(logs);
    });

    return () => {
      unsubscribe();
    };
  }, [date]);

  //Get Exercise Logs from firebase, unsubs when unmounting
  useEffect(() => {
    const q = query(
      collection(getFirestore(), "exercise"),
      where("uid", "==", user.uid),
      where("date", "==", date)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const logs = [];
      querySnapshot.forEach((doc) => {
        logs.push({ id: doc.id, ...doc.data() });
      });

      setExerciseLogs(logs);
    });

    return () => {
      unsubscribe();
    };
  }, [date]);

  //Get Exercise Logs from firebase, unsubs when unmounting
  useEffect(() => {
    const q = query(
      collection(getFirestore(), "moods"),
      where("uid", "==", user.uid),
      where("date", "==", date)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const logs = [];
      querySnapshot.forEach((doc) => {
        logs.push({ id: doc.id, ...doc.data() });
      });

      setMoodLogs(logs);
    });

    return () => {
      unsubscribe();
    };
  }, [date]);

  useEffect(() => {
    const q = query(
      collection(getFirestore(), "metrics"),
      where("uid", "==", user.uid),
      where("date", "==", date)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const logs = [];
      querySnapshot.forEach((doc) => {
        logs.push({ id: doc.id, ...doc.data() });
      });

      setMetricsLogs(logs);
    });

    return () => {
      unsubscribe();
    };
  }, [date]);

  const deleteMeal = async (id: string) => {
    const db = getFirestore();
    const ref = doc(db, `meals/${id}`);
    await deleteDoc(ref);
  };

  const deleteMood = async (id: string) => {
    const db = getFirestore();
    const ref = doc(db, `moods/${id}`);
    await deleteDoc(ref);
  };

  const deleteExercise = async (id: string) => {
    const db = getFirestore();
    const ref = doc(db, `exercise/${id}`);
    await deleteDoc(ref);
  };

  const deleteMetrics = async (id: string) => {
    const db = getFirestore();
    const ref = doc(db, `metrics/${id}`);
    await deleteDoc(ref);
  };

  const RenderMoodIcon = ({ mood }) => {
    switch (mood) {
      case "super-happy":
        return <IonIcon className="mood-icon " icon={SuperHappyFilled} />;
      case "happy":
        return <IonIcon className="mood-icon" icon={HappyFilled} />;
      case "neutral":
        return <IonIcon className="mood-icon" icon={Neutral} />;
      case "sad":
        return <IonIcon className="mood-icon" icon={SadFilled} />;
      case "super-sad":
        return <IonIcon className="mood-icon" icon={SuperSadFilled} />;
      default:
        return <IonIcon className="mood-icon ion-margin" icon={Neutral} />;
    }
  };

  const RenderMetricType = ({ type }) => {
    switch (type) {
      case "a1c":
        return "A1C";
      case "blood-pressure":
        return "Blood Pressure";
      case "body-fat":
        return "Body Fat";
      case "steps":
        return "Steps";
      case "waist-circumference":
        return "Waist Circumference";
      case "weight":
        return "Weight";
      case "bmi":
        return "BMI";
      default:
        return "DEF";
    }
  };

  const RenderMetric = ({ metric }) => {
    return (
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonLabel>{RenderMetricType({ type: metric.type })}</IonLabel>
          </IonCol>
          <IonCol>
            <IonLabel>
              {metric.type == "blood-pressure" ? (
                <>
                  D: {metric.systolic} S: {metric.diastolic}
                </>
              ) : (
                <>{metric.value}</>
              )}
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
    );
  };
  return (
    <IonPage>
      <InactiveAccountHeader />
      <IonHeader>
          <IonToolbar className="mainHeaderBackground">
          <IonButtons slot="start">
              <IonMenuToggle className="white">
                <IonMenuButton className="white" />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle className="mainPageTitleTEst">Journal</IonTitle>
          </IonToolbar>
        </IonHeader>
      <IonContent fullscreen>
        <LogMeal date={date} user={user} />
        <LogExercise date={date} user={user} />
        <LogMood date={date} user={user} />
        <LogMetrics date={date} user={user} />

        <>
          <IonToolbar className="dateToolbar">
            <IonButtons slot="start">
              <IonIcon
                size="large"
                icon={chevronBackSharp}
                color="primary"
                onClick={() => setDate(decrementDate(date))}
              />
            </IonButtons>
            <IonButtons slot="end">
              <IonIcon
                size="large"
                icon={chevronForwardSharp}
                color="primary"
                onClick={() => setDate(incrementDate(date))}
              />
            </IonButtons>
            <ShowCurrentDate />
          </IonToolbar>
        </>

        {showDataPicker && (
          <IonDatetime
            presentation="date"
            value={date}
            onIonChange={(e: any) => {
              setDate(e.detail.value);
              setShowDataPicker(!showDataPicker);
            }}
            className="ion-margin"
            onIonCancel={(e: any) => setShowDataPicker(!showDataPicker)}
          ></IonDatetime>
        )}
        <div className="journal-outer">
          <div className="journal-inner">
            <IonCard className="journal-card">
              <IonCardHeader className="meal journal-head" id="open-log-meal">
                <IonRow>
                  <IonCol size="8">
                    <IonCardTitle className="journal-head">
                      Log Meal
                    </IonCardTitle>
                  </IonCol>
                  <IonCol size="4">
                    <IonIcon
                      className="icon_plus"
                      icon={addCircleOutline}
                    ></IonIcon>
                  </IonCol>
                </IonRow>
              </IonCardHeader>

              <IonCardContent>
                <IonList>
                  {mealLogs.map((log, index) => {
                    return (
                      <IonItem key={index} className="ionLogItem">
                        {log.mealPhoto === "no-photo" ? (
                          <img
                            className="mealLogCover"
                            src="https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/no_photo.png?alt=media&token=908c8412-8af8-469d-ae98-591408c933d3"
                            alt="meal"
                          />
                        ) : (
                          <img
                            className="mealLogCover"
                            src={log.mealPhoto}
                            alt="meal"
                          />
                        )}
                        • {log.mealName} <br />
                        {log.mealCalories == 0 ? null : (
                          <>
                            • {log.mealCalories} cals
                            <br />
                          </>
                        )}
                        {log.mealType == "" ? null : (
                          <>
                            • {log.mealType}
                            <br />
                          </>
                        )}
                        <IonIcon
                          onClick={() => deleteMeal(log.id)}
                          slot="end"
                          icon={trashOutline}
                        ></IonIcon>
                      </IonItem>
                    );
                  })}
                </IonList>
              </IonCardContent>
            </IonCard>

            <IonCard className="journal-card">
              <IonCardHeader
                className="activity journal-head"
                id="open-log-exercise"
              >
                <IonRow>
                  <IonCol size="8">
                    <IonCardTitle className="journal-head">
                      Log Activity
                    </IonCardTitle>
                  </IonCol>
                  <IonCol size="4">
                    <IonIcon
                      className="icon_plus"
                      icon={addCircleOutline}
                    ></IonIcon>
                  </IonCol>
                </IonRow>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {exerciseLogs.map((log, index) => {
                    return (
                      <IonItem key={index} className="ionLogItem">
                        {log.type} <br /> • Intensity {log.intensity} <br /> •{" "}
                        {log.duration} minutes <br /> {log.comment}
                        <IonIcon
                          onClick={() => deleteExercise(log.id)}
                          slot="end"
                          icon={trashOutline}
                        ></IonIcon>
                      </IonItem>
                    );
                  })}
                </IonList>
              </IonCardContent>
            </IonCard>

            <IonCard className="journal-card">
              <IonCardHeader className="mood journal-head" id="open-log-mood">
                <IonRow>
                  <IonCol size="8">
                    <IonCardTitle className="journal-head">
                      Log Mood
                    </IonCardTitle>
                  </IonCol>
                  <IonCol size="4">
                    <IonIcon
                      className="icon_plus"
                      icon={addCircleOutline}
                    ></IonIcon>
                  </IonCol>
                </IonRow>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {moodLogs.map((log, index) => {
                    return (
                      <IonItem key={index} className="ionLogItem">
                        <IonGrid>
                          <IonRow>
                            <IonCol size="4">
                              <RenderMoodIcon mood={log.mood} />
                            </IonCol>
                            <IonCol>{log.comment}</IonCol>
                          </IonRow>
                        </IonGrid>

                        <IonIcon
                          onClick={() => deleteMood(log.id)}
                          slot="end"
                          icon={trashOutline}
                        ></IonIcon>
                      </IonItem>
                    );
                  })}
                </IonList>
              </IonCardContent>
            </IonCard>

            <IonCard className="journal-card">
              <IonCardHeader
                className="metrics journal-head"
                id="open-log-metrics"
              >
                <IonRow>
                  <IonCol size="8">
                    <IonCardTitle className="journal-head">
                      Log Metrics
                    </IonCardTitle>
                  </IonCol>
                  <IonCol size="4">
                    <IonIcon
                      className="icon_plus"
                      icon={addCircleOutline}
                    ></IonIcon>
                  </IonCol>
                </IonRow>
              </IonCardHeader>
              <IonCardContent>
                <IonList>
                  {metricsLogs.map((log, index) => {
                    return (
                      <IonItem key={log.id} className="ionLogItem">
                        <RenderMetric metric={log} />
                        <IonIcon
                          onClick={() => deleteMetrics(log.id)}
                          slot="end"
                          icon={trashOutline}
                        ></IonIcon>
                      </IonItem>
                    );
                  })}
                </IonList>
              </IonCardContent>
            </IonCard>
            <LogGlucose2 date={date}/>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Journal;
