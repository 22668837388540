import {
  IonItem,
  IonIcon,
  IonToggle,
  IonLabel,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
  IonAvatar,
  IonCol,
  IonGrid,
  IonRow,
  IonTextarea,
  IonSegment,
  IonSegmentButton,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from "@ionic/react";
import {
  arrowUp,
  arrowDown,
  shareSocial,
  chatbox,
  chatboxOutline,
  cropOutline,
  closeOutline,
  paperPlane,
  refresh,
  archive,
  heart,
  trash,
} from "ionicons/icons";
import "./style.css";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../../../../lib/context";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import Comment from "../Comment";
import { timeSince } from "../../../../../lib/Helpers/time";
import { CommentInterface } from "../ c../../../../Interfaces/CommentInterface";
import Reply from "../Reply";
import Avatar from "avataaars";
import Share from "../../../../Share";

type Props = {
  downvotes;
  upvotes;
  share;
  id;
  comments;
  question;
};

const Toolbar: React.FC<Props> = ({
  downvotes,
  upvotes,
  share,
  id,
  comments,
  question,
}) => {
  const { koroPublicAvatar } = useContext(UserContext);
  const [upvotesTracker, setUpvotesTracker] = useState(upvotes);
  const [downvotesTracker, setDownvotesTracker] = useState(downvotes);
  const [commentsOpen, setCommentsOpen] = useState(false);

  const [commentsData, setCommentsData] = useState([]) as any;

  const [comment, setComment] = useState("");
  const [replies, setReplies] = useState([]) as any;

  const [isThereExpertReply, setIsThereExpertReply] = useState(false);

  const [reply, setReply] = useState({});

  const inputRef = useRef<any>(null);

  const [commentSegment, setCommentSegment] = useState("expert");

  const replyHandler = (r) => {
    console.log("");
    setReply(r);
    setComment(`@${r.name} `);
    inputRef?.current?.setFocus();
  };

  const upVote = async () => {
    var upvotesData = upvotesTracker;
    var downvotesData = downvotesTracker;

    if (upvotesTracker.includes(koroPublicAvatar.uid)) {
      const newUpvotesTracker = upvotesTracker.filter(
        (upvote) => upvote !== koroPublicAvatar.uid
      );
      setUpvotesTracker(newUpvotesTracker);
      upvotesData = newUpvotesTracker;
    } else {
      setUpvotesTracker([...upvotesTracker, koroPublicAvatar.uid]);
      upvotesData = [...upvotesTracker, koroPublicAvatar.uid];
      // remove from downvotesTracker if it exists
      if (downvotesTracker.includes(koroPublicAvatar.uid)) {
        const newDownvotesTracker = downvotesTracker.filter(
          (downvote) => downvote !== koroPublicAvatar.uid
        );
        setDownvotesTracker(newDownvotesTracker);
        downvotesData = newDownvotesTracker;
      }
    }

    const docRef = doc(getFirestore(), "koroQuestions", id);

    await updateDoc(docRef, {
      upvotes: upvotesData,
      downvotes: downvotesData,
    })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const downVote = async () => {
    var upvotesData = upvotesTracker;
    var downvotesData = downvotesTracker;

    if (downvotesTracker.includes(koroPublicAvatar.uid)) {
      const newDownvotesTracker = downvotesTracker.filter(
        (downvote) => downvote !== koroPublicAvatar.uid
      );
      setDownvotesTracker(newDownvotesTracker);
      downvotesData = newDownvotesTracker;
    } else {
      setDownvotesTracker([...downvotesTracker, koroPublicAvatar.uid]);
      downvotesData = [...downvotesTracker, koroPublicAvatar.uid];
      // remove from upvotesTracker if it exists
      if (upvotesTracker.includes(koroPublicAvatar.uid)) {
        const newUpvotesTracker = upvotesTracker.filter(
          (upvote) => upvote !== koroPublicAvatar.uid
        );
        setUpvotesTracker(newUpvotesTracker);
        upvotesData = newUpvotesTracker;
      }
    }

    const docRef = doc(getFirestore(), "koroQuestions", id);

    await updateDoc(docRef, {
      upvotes: upvotesData,
      downvotes: downvotesData,
    });
  };

  const postComment = () => {
    console.log(comment);
    const data: CommentInterface = {
      avatar: koroPublicAvatar.avatar,
      name: koroPublicAvatar.displayName,
      timestamp: new Date(),
      comment: comment,
      postId: id,
      poster: koroPublicAvatar.id,
      reply: false,
      replyTo: "",
      replyFrom: "",
      parentCommentId: "",
      expert: false,
    };

    if (comment.includes("@")) {
      data.reply = true;
      data.replyTo = reply["publicAvatarUid"];
      data.replyFrom = koroPublicAvatar.id;
      data.parentCommentId = reply["parentCommentId"];
    }

    console.log(data);

    addDoc(collection(getFirestore(), "koroComments"), data).then((docRef) => {
      console.log("Document successfully written!");
      setComment("");
      data.id = docRef.id;

      if (data.reply) {
        setReplies([...replies, data]);
      } else {
        setCommentsData([...commentsData, data]);
      }
    });
  };

  const getComments = async () => {
    var comments = [];

    const q = query(
      collection(getFirestore(), "koroComments"),
      where("postId", "==", id)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      comments.push({ id: doc.id, ...doc.data(),});
    });

    // remove all comments that have deleted === true
    comments = comments.filter((comment) => comment.deleted !== true);

    // order comments by timestamp but put expert == true at the top but ignore if it's a reply
    comments.sort((a, b) => {
      if (a.expert && !b.expert) {
        return -1;
      } else if (!a.expert && b.expert) {
        return 1;
      } else {
        return a.timestamp.seconds - b.timestamp.seconds;
      }
    });

    // organize comments by comment.upvote - comment.downvote but ignore if it's a reply
    comments.sort((a, b) => {
      if (a.expert && !b.expert) {
        return -1;
      } else if (!a.expert && b.expert) {
        return 1;
      } else {
        return (
          (b.upvotes?.length || 0) -
          (b.downvotes?.length || 0) -
          ((a.upvotes?.length || 0) - (a.downvotes?.length || 0))
        );
      }
    });

    var rep = [];

    comments.forEach((comment) => {
      if (comment.reply) {
        rep.push(comment);
        comments = comments.filter((c) => c.id !== comment.id);
      }
    });

    // check to see if there is an expert reply
    comments.forEach((comment) => {
      if (comment.expert) {
        setIsThereExpertReply(true);
      }
    });

    // rep.forEach((reply) => {
    //   console.log("parentCommentId! => ", reply.parentCommentId);
    //   const parentCommentId = reply.parentCommentId;
    //   // find parent comment
    //   comments.forEach((comment) => {
    //     if (comment.id === parentCommentId) {
    //       console.log("found parent comment");
    //     }
    //   });

    // });

    // check to see if partenComment exist in comments
    rep.forEach((reply) => {
      const parentComment = comments.find(
        (comment) => comment.id === reply.parentCommentId
      );

      if (!parentComment) {
        rep = rep.filter((r) => r.id !== reply.id);
      }
    }
    );
   


    setCommentsData(comments);
    setReplies(rep);
  };

  useEffect(() => {
    getComments();
  }, [refresh]);

  useEffect(() => {
    setTimeout(() => inputRef?.current?.setFocus(), 700);
  }, [commentsOpen]);

  return (
    <>
      <IonItem className="koroQuestionToolbar ion-margin" lines="none">
        <span className="koroQuestionToolbarWords">
          <IonIcon
            icon={arrowUp}
            onClick={upVote}
            color={
              upvotesTracker.includes(koroPublicAvatar?.uid) ? "primary" : ""
            }
          />{" "}
          <span
            className={
              upvotesTracker.includes(koroPublicAvatar?.uid)
                ? "colorPrimary"
                : ""
            }
          >
            {upvotesTracker.length}
          </span>
        </span>
        <span className="ion-margin"></span>
        <span className="koroQuestionToolbarWords">
          <IonIcon
            icon={arrowDown}
            onClick={downVote}
            color={
              downvotesTracker.includes(koroPublicAvatar?.uid) ? "primary" : ""
            }
          />{" "}
          <span
            className={
              downvotesTracker.includes(koroPublicAvatar?.uid)
                ? "colorPrimary"
                : ""
            }
          >
            {downvotesTracker.length}
          </span>
        </span>
        <span className="ion-margin"></span>
        <span className="koroQuestionToolbarWords">
          <IonIcon
            icon={chatboxOutline}
            onClick={() => setCommentsOpen(true)}
          />{" "}
          {commentsData.length + replies.length}
        </span>
        <IonButtons slot="end" onClick={() => console.log("share clicked")}>
          <Share
            url={`https://member.alliedwellness.com/tabs/community/${question.id}`}
            title="Check out this awesome app!"
            text="Check out this awesome app!"
            dialogTitle=""
          />
        </IonButtons>
      </IonItem>

      <IonModal
        isOpen={commentsOpen}
        onDidDismiss={() => setCommentsOpen(false)}
      >
        <IonContent>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Comments </IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setCommentsOpen(false)}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          {/* {reply["publicAvatarUid"]}
          <br></br>
          {reply["name"]}
          <br></br>

          {reply["parentCommentId"]} */}
          <IonSegment
            value={commentSegment}
            onIonChange={(e) => setCommentSegment(e.detail.value)}
          >
            <IonSegmentButton value="expert">
              <IonLabel>Expert</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="member">
              <IonLabel>Member</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <IonGrid></IonGrid>

          {commentSegment === "expert" ? (
            <IonList className="ion-padding-top">
              {!isThereExpertReply && (
                <>
                  <IonLabel>
                    <p className="ion-text-center">No expert replies yet</p>
                  </IonLabel>
                </>
              )}
              {commentsData.map((comment) => {
                if (comment.expert) {
                  return (
                    <div key={comment.id}>
                      <Comment
                        avatar={comment.avatar}
                        name={comment.name}
                        time={timeSince(comment.timestamp)}
                        comment={comment.comment}
                        expert={comment.expert}
                        publicAvatarUid={comment.poster}
                        replyHandler={replyHandler}
                        commentObject={comment}
                        id={comment.id}
                        allowReply={false}
                        answer={question.answer === comment.comment}
                      />
                      {replies.map((reply) => {
                        // console.log(reply.replyTo, comment.poster, reply.comment)
                        if (
                          reply.parentCommentId === comment.id &&
                          comment.deleted !== true
                        ) {
                          return (
                            <div key={reply.id}>
                              <Reply
                                avatar={reply.avatar}
                                key={reply.id}
                                name={reply.name}
                                time={timeSince(reply.timestamp)}
                                comment={reply.comment}
                                publicAvatarUid={reply.poster}
                                expert={reply.expert}
                                allowReply={false}
                                replyHandler={replyHandler}
                                id={reply.parentCommentId}
                                commentObj={reply}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
            </IonList>
          ) : (
            <IonList className="ion-padding-top">
              {!commentsData.some((comment) => !comment.expert) && (
                <>
                  <IonLabel>
                    <p className="ion-text-center">No member comments yet</p>
                  </IonLabel>
                </>
              )}

              {commentsData.map((comment) => {
                if (!comment.expert) {
                  return (
                    <div key={comment.id}>
                      <Comment
                        avatar={comment.avatar}
                        name={comment.name}
                        time={timeSince(comment.timestamp)}
                        comment={comment.comment}
                        commentObject={comment}
                        expert={comment.expert}
                        publicAvatarUid={comment.poster}
                        replyHandler={replyHandler}
                        allowReply={true}
                        id={comment.id}
                        answer={question.answer === comment.comment}
                      />
                      {replies.map((reply) => {
                        // console.log(reply.replyTo, comment.poster, reply.comment)
                        if (
                          reply.parentCommentId === comment.id &&
                          comment.deleted !== true
                        ) {
                          return (
                            <div key={reply.id}>
                              <Reply
                                avatar={reply.avatar}
                                key={reply.id}
                                name={reply.name}
                                time={timeSince(reply.timestamp)}
                                comment={reply.comment}
                                publicAvatarUid={reply.poster}
                                expert={reply.expert}
                                replyHandler={replyHandler}
                                allowReply={true}
                                id={reply.parentCommentId}
                                commentObj={reply}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                  );
                }
              })}
            </IonList>
          )}

          {/* {reply["publicAvatarUid"]}
  {reply["name"]} */}

          {commentSegment !== "expert" && (
            <IonItem lines="none" className="tetetetetettetete">
              <IonGrid>
                <IonRow className="createCommentRowElement">
                  <IonCol size="auto">
                    <Avatar
                      style={{ width: "40px", height: "40px" }}
                      avatarStyle="Circle"
                      {...koroPublicAvatar.avatarConfig}
                    />
                  </IonCol>
                  <IonCol>
                    <div className="inputItemFormComment">
                      {/* <span>@{koroPublicAvatar.displayName}</span> */}
                      <IonTextarea
                        placeholder="Your comment..."
                        required={true}
                        autoGrow={true}
                        value={comment}
                        autofocus={true}
                        onIonInput={(e: any) => setComment(e.target.value)}
                        ref={(ref) => (inputRef.current = ref)}
                      ></IonTextarea>
                    </div>
                  </IonCol>
                  <IonCol size="1" className="ion-text-center">
                    {comment !== "" && (
                      <IonIcon
                        icon={paperPlane}
                        onClick={postComment}
                        size="medium"
                        color="primary"
                      ></IonIcon>
                    )}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default Toolbar;
