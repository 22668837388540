import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonLabel,
  IonModal,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";

import "./Welcome.css";
import { useContext, useEffect, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../../../lib/Firebase";
import { UserContext } from "../../../lib/context";
import { formatToIonicDate, reformatDOB } from "../../../lib/helper";
import { doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";

import { InterestList } from "../Interfaces/Interest";

const Welcome: React.FC = () => {
  const { user, profile } = useContext(UserContext);

  const [firstName, setFirstName] = useState(profile.firstName || "");
  const [lastName, setLastName] = useState(profile.lastName || "");
  const [gender, setGender] = useState(profile.gender || "");
  const [dob, setDob] = useState(formatToIonicDate(profile.dob) || "");
  const [dobTimeStamp, setDobTimeStamp] = useState(profile.dobTimeStamp || "");
  const [heightFt, setHeightFt] = useState(profile.heightFt || "");
  const [heightIn, setHeightIn] = useState(profile.heightIn || "");
  const [weight, setWeight] = useState(profile.weight || "");
  const [interest, setInterest] = useState(profile.interest || "");

  const signOutUser = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { uid, email } = user;

    const koroUser = {
      uid,
      email,
      firstName,
      lastName,
      gender,
      dob: reformatDOB(dob),
      dobTimeStamp,
      heightFt: parseInt(heightFt),
      heightIn: parseInt(heightIn),
      weight: parseInt(weight),
      // interest,
      koroWelcomeComplete: true,
    };

    console.log(koroUser);

    const docRef = doc(getFirestore(), "users", uid);

    updateDoc(docRef, koroUser);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonIcon
              slot="start"
              icon={chevronBack}
              size="large"
              color="primary"
              onClick={() => signOutUser()}
            />
          </IonButtons>
          <IonTitle className="ion-text-center">Bodee</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="koroOnboardingContainer">
          <div className="center-container ion-padding-top">
            <img src="koroLogo.png" className="loginLogo" alt="logo" />
          </div>
          <div className="ionPaddingTopSmall">
            <h2 className="welcomeKoroTitle ">Welcome to Bodee!</h2>
            <div>
              <p className="subTitle">We'd like to know more about you...</p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <div>
                <div className="ionPaddingTopSmall">
                  <IonLabel>
                    <p>First Name</p>
                  </IonLabel>
                  <div className="inputItemForm">
                    <IonInput
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      required={true}
                      value={firstName}
                      onIonInput={(e: any) => setFirstName(e.target.value)}
                    ></IonInput>
                  </div>
                  <IonLabel>
                    <p>Last Name</p>
                  </IonLabel>
                  <div className="inputItemForm">
                    <IonInput
                      type="text"
                      name="lastName"
                      required={true}
                      value={lastName}
                      placeholder="Last Name"
                      onIonInput={(e: any) => setLastName(e.target.value)}
                    ></IonInput>
                  </div>
                  <IonLabel>
                    <p>Gender</p>
                  </IonLabel>
                  <div className="inputItemForm select">
                    <IonSelect
                      onIonChange={(e: any) => setGender(e.target.value)}
                      placeholder="Gender"
                      className="ion-no-padding ionSelect"
                      value={gender}
                    >
                      <IonSelectOption value="male">Male</IonSelectOption>
                      <IonSelectOption value="female">Female</IonSelectOption>
                    </IonSelect>
                  </div>
                  <IonLabel>
                    <p>Date of Birth</p>
                  </IonLabel>
                  <div className="inputItemForm ">
                    <IonInput
                      type="date"
                      name="dob"
                      required={true}
                      value={dob}
                      placeholder="Date of Birth"
                      onIonInput={(e: any) => setDob(e.target.value)}
                    ></IonInput>
                  </div>
                  <IonLabel><p>Height (ft)</p></IonLabel>
                  <div className="inputItemForm">
                    <IonInput
                      type="number"
                      name="heightFt"
                      required={true}
                      value={heightFt}
                      placeholder="Height (ft)"
                      onIonInput={(e: any) => setHeightFt(e.target.value)}
                      inputMode="numeric"
                    ></IonInput>
                  </div>
                  <IonLabel><p>Height (in)</p></IonLabel>
                  <div className="inputItemForm">
                    <IonInput
                      type="number"
                      name="heightIn"
                      required={true}
                      value={heightIn}
                      placeholder="Height (in)"
                      onIonInput={(e: any) => setHeightIn(e.target.value)}
                      inputMode="numeric"
                    ></IonInput>
                  </div>
                  <IonLabel><p>Weight (lbs)</p></IonLabel>
                  <div className="inputItemForm">
                    <IonInput
                      type="number"
                      name="weight"
                      required={true}
                      value={weight}
                      placeholder="Weight (lbs)"
                      onIonInput={(e: any) => setWeight(e.target.value)}
                      inputMode="numeric"
                    ></IonInput>
                  </div>
                  {/* <IonLabel><p>Your Interest</p></IonLabel>
                  <div className="inputItemForm select">
                    <IonSelect
                      onIonChange={(e: any) => setInterest(e.target.value)}
                      placeholder="Your Interest"
                      value={interest}
                      className="ion-no-padding ionSelect"
                      multiple={true}
                    >
                      {InterestList.map((interest, index) => {
                        return (
                          <IonSelectOption value={interest.title} key={index}>
                            {interest.title}
                          </IonSelectOption>
                        );
                      })}
                    </IonSelect>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="ionPaddingTopSmall">
              <IonButton expand="block" type="submit" className="ion-margin">
                Continue
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
