import { IonItem, IonIcon, IonLabel, IonButton, IonButtons, IonCardSubtitle, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { doc, getDoc, getFirestore, onSnapshot } from "firebase/firestore";
import { checkbox, squareOutline } from "ionicons/icons";
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../lib/context";
import GoalsDailyGraph from "./GoalsDailyGraph";
import GoalsOneTimeGraph from "./GoalsOneTimeGraph";
import GoalsWeeklyGraph from "./GoalsWeeklyGraph";

type Props = { compliance: any };

const ChatWithCoachGoal: React.FC<Props> = ({compliance}) => {
  const { user, profile } = useContext(UserContext);
  const [showModal, setShowModal] = React.useState(false);


  const RenderNextMonthDate = () => {

    var nextMonth;
    // get next month date
    const date = new Date();
    const month = date.getMonth() + 1;
    var year = date.getFullYear();

    if(month === 12){
      nextMonth = 1;
      year = year + 1;
    }
    else {
      nextMonth = month + 1;
      
    }

    return (
      <>{nextMonth}/1/{year}</>
    )
  }

  if (compliance['compliant'] === false)
    return (
      <>
        <IonItem
          className="goal need-goal goal-text"
          lines="none"
          color="primary"
          fill="outline"
          onClick={() => setShowModal(true)}
        >
          {" "} 
          <IonIcon
            icon={squareOutline}
            color="primary"
            slot="end"
          ></IonIcon>{" "}
          <IonLabel className="goal-title ">
            {" "}
            Check in With Your Coach{" "}
          </IonLabel>{" "}
        </IonItem>

        <IonModal isOpen={showModal}>
        
            <IonHeader>
              <IonToolbar>
                <IonTitle slot="start" className="ion-text-wrap mainPageTitle">
                  Goal
                </IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setShowModal(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding ion-text-center">
              <h1>Check in With Your Coach</h1>
              

              <IonCardSubtitle>one-time by <RenderNextMonthDate /></IonCardSubtitle>
              <br></br>
              <br></br>

              

              <br></br>
              Check in with your coach to discuss your progress and any challenges you are facing. This goal will automatically be marked as complete once you have completed your check in.
              <br></br>
           

            </IonContent>
      </IonModal>

      </>
    );

    else {
      return (

        <>
        <IonItem
          className="goal  goal-text"
          lines="none"
          color="success"
          onClick={() => setShowModal(true)}
        >
          {" "} 
          <IonIcon
            icon={checkbox}
            slot="end"
          ></IonIcon>{" "}
          <IonLabel className="goal-title ">
            {" "}
            Check in With Your Coach{" "}
          </IonLabel>{" "}
        </IonItem>

        <IonModal isOpen={showModal}>
        
            <IonHeader>
              <IonToolbar>
                <IonTitle slot="start" className="ion-text-wrap mainPageTitle">
                  Goal
                </IonTitle>
                <IonButtons slot="end">
                  <IonButton onClick={() => setShowModal(false)}>
                    Close
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <h1>Check in With Your Coach</h1>
              

              <IonCardSubtitle>one-time by <RenderNextMonthDate /></IonCardSubtitle>
              <br></br>
              <br></br>

              

              <br></br>
              Check in with your coach to discuss your progress and any challenges you are facing. This goal will automatically be marked as complete once you have completed your check in.
              <br></br>
           

            </IonContent>
      </IonModal>

      </>
      )
    }
    
};

export default ChatWithCoachGoal;
