/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
//import spinner
import React, { useEffect } from "react";
import "./HRAStyle.css";
import "firebase/compat/auth";
import { formatToIonicDate } from "../../lib/helper";
import { Browser } from "@capacitor/browser";

type Props = {
  slides;
  buttonHandler;
  profileHandler;
  profile;
  slideIndex;
};

const SetUpProfile: React.FC<Props> = ({
  slides,
  buttonHandler,
  profileHandler,
  profile,
  slideIndex,
}) => {
  const [firstName, setFirstName] = React.useState(profile.firstName);
  const [lastName, setLastName] = React.useState(profile.lastName);
  const [dob, setDob] = React.useState(
    profile.dob ? formatToIonicDate(profile.dob) : " "
  );
  const [phone, setPhone] = React.useState(profile.phone);
  const [checked, setChecked] = React.useState(false);

  const validateFirstName = () => {
    var firstNameHelp = document.getElementById("firstNameHelp");
    var regex = /^[a-z ,.'-]+$/i;

    if (firstName === undefined || firstName === "") {
      firstNameHelp.innerHTML = "First name is required";
      return false;
    }

    // make sure only accepts letters
    else if (!regex.test(firstName)) {
      firstNameHelp.innerHTML = "First name must only contain letters or ,.'-";
      return false;
    } else if (firstName.length < 2) {
      firstNameHelp.innerHTML = "First name must be at least 2 characters";
      return false;
    } else {
      firstNameHelp.innerHTML = "";
      return true;
    }
  };

  const validateLastName = () => {
    var lastNameHelper = document.getElementById("lastNameHelpH");
    var regex = /^[a-z ,.'-]+$/i;

    if (lastName === undefined || lastName === "") {
      lastNameHelper.innerHTML = "last name is required";
      return false;
    }

    // make sure only accepts letters
    else if (!regex.test(lastName)) {
      lastNameHelper.innerHTML = "Last name must only contain letters or ,.'-";
      return false;
    } else if (lastName.length < 2) {
      lastNameHelper.innerHTML = "Last name must be at least 2 characters";
      return false;
    } else {
      lastNameHelper.innerHTML = "";
      return true;
    }
  };

  const validateDOB = () => {
    var dobHelper = document.getElementById("dobHelp");

    if (dob === undefined || dob === "" || dob === " ") {
      dobHelper.innerHTML = "DOB is required";
      return false;
    } else {
      dobHelper.innerHTML = "";
      return true;
    }
  };

  const validatePhone = () => {
    var phoneHelper = document.getElementById("phoneHelp");
    var regex = /^\d{3}\-\d{3}\-\d{4}$/;

    if (phone === undefined || phone === "") {
      phoneHelper.innerHTML = "Phone number is required";
      return false;
    }

    // make sure only accepts numbers
    else if (!regex.test(phone)) {
      phoneHelper.innerHTML = "Phone number must be in the format 123-456-7890";
      return false;
    } else {
      phoneHelper.innerHTML = "";
      return true;
    }
  };

  const validateValues = () => {
    if (
      validateFirstName() &&
      validateLastName() &&
      validateDOB() &&
      validatePhone() &&
      checked
    ) {
      buttonHandler(false);
    } else {
      buttonHandler(true);
    }
  };

  useEffect(() => {
    if (slideIndex === 0) {
      validateValues();
    }
  }, [firstName, lastName, slideIndex, dob, phone, checked]);

  const openBrowser = async (url) => {
    await Browser.open({ url: url });
  };

  return (
    <>
      <IonCardTitle class="ion-text-start slideTitle">
        Let's set up your Allied Account
      </IonCardTitle>
      <IonCardSubtitle class="ion-text-start slideSubTitle">
        Please fill
      </IonCardSubtitle>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">First Name</IonLabel>
              <IonInput
                autoCapitalize="on"
                type="text"
                value={firstName}
                onIonChange={(e: any) => {
                  setFirstName(e.detail.value);
                  profile.firstName = e.detail.value;
                  profileHandler(profile);
                }}
                onIonBlur={(e: any) => {
                  validateFirstName();
                }}
              />
              <p className="formHelp" id="firstNameHelp"></p>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Last Name</IonLabel>
              <IonInput
                autoCapitalize="on"
                type="text"
                value={lastName}
                onIonChange={(e: any) => {
                  setLastName(e.detail.value);
                  profile.lastName = e.detail.value;
                  profileHandler(profile);
                }}
                onIonBlur={(e: any) => {
                  validateLastName();
                }}
              />
              <p className="formHelp" id="lastNameHelpH"></p>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">DOB</IonLabel>
              <IonInput
                type="date"
                value={dob}
                onIonChange={(e: any) => {
                  setDob(e.detail.value);
                  profile.dob = e.detail.value;
                  profileHandler(profile);
                }}
                onIonBlur={(e: any) => {
                  validateDOB();
                }}
              />
              <p className="formHelp" id="dobHelp"></p>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Phone</IonLabel>
              <IonInput
                value={phone}
                onIonChange={(e: any) => {
                  setPhone(e.detail.value);
                  profile.phone = e.detail.value;
                  profileHandler(profile);
                }}
                onIonBlur={(e: any) => {
                  validatePhone();
                }}
              />
              <p className="formHelp" id="phoneHelp"></p>
            </IonItem>
          </IonCol>
        </IonRow>
        {/* <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">New Password</IonLabel>
              <IonInput
                type="password"
                value={password}
                onIonChange={(e: any) => {
                  setPassword(e.detail.value);
                }}
                onIonBlur={(e: any) => {
                  validatePassword();
                }}
              />
              <p className="formHelp" id="passwordHelp"></p>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Confirm New Password</IonLabel>
              <IonInput
                type="password"
                value={passwordConfirm}
                onIonChange={(e: any) => {
                  setPasswordConfirm(e.detail.value);
                }}
                onIonBlur={(e: any) => {
                  validateConfirmPassword();
                }}
              />
              <p className="formHelp" id="passwordConfirmHelp"></p>
            </IonItem>
          </IonCol>
        </IonRow> */}
      </IonGrid>
      <IonItem>
        <p>
          I agree to the{" "}
          <a
            onClick={() => openBrowser("https://alliedwellness.com/terms-conditions/")}
            className="terms"
          >
            terms of service
          </a>
        </p>
        <IonCheckbox
          slot="start"
          onIonChange={(e) => setChecked(e.detail.checked)}
        />
      </IonItem>
    </>
  );
};

export default SetUpProfile; // /* eslint-disable jsx-a11y/anchor-is-valid */
// import {
//   IonButton,
//   IonCardSubtitle,
//   IonCardTitle,
//   IonCheckbox,
//   IonCol,
//   IonContent,
//   IonGrid,
//   IonInput,
//   IonItem,
//   IonLabel,
//   IonPage,
//   IonRow,
// } from "@ionic/react";
// //import spinner
// import React, { useContext, useEffect, useState } from "react";
// import "./HRAStyle.css";
// import firebase from "firebase/compat/app";
// import {
//   query,
//   collection,
//   getFirestore,
//   where,
//   getDocs,
//   doc,
//   writeBatch,
//   updateDoc,
// } from "firebase/firestore";
// import { UserContext } from "../../lib/context";
// import "firebase/compat/auth";
// import { Browser } from "@capacitor/browser";

// import { reformatDOB, formatToIonicDate } from "../../lib/helper";

// const SetUpProfile = ({ slides, buttonDisabled, buttonHandler}) => {

//   const test = () => {
//     buttonHandler(!buttonDisabled)
//   };

//   const { user, profile } = useContext(UserContext);

//   const [firstName, setFirstName] = useState(profile ? profile.firstName : "");
//   const [lastName, setLastName] = useState(profile ? profile.lastName : "");
//   const [dob, setDob] = useState(formatToIonicDate(profile ? profile.dob : ""));
//   const [password, setPassword] = useState("");
//   const [passwordConfirm, setPasswordConfirm] = useState("");
//   const [validFisrtName, setValidFirstName] = useState(false);
//   const [validLastName, setValidLastName] = useState(false);
//   const [validDOB, setValidDOB] = useState(false);
//   const [validPassword, setValidPassword] = useState(false);
//   const [validPasswordConfirm, setValidPasswordConfirm] = useState(false);
//   const [validPhone, setValidPhone] = useState(false);
//   const [checked, setChecked] = React.useState(false);
//   const [phone, setPhone] = useState(profile ? profile.phone : "");

//   const validateFirstName = () => {
//     var firstNameHelp = document.getElementById("firstNameHelp");
//     var regex = /^[a-z ,.'-]+$/i;

//     if (firstName === undefined || firstName === "") {
//       firstNameHelp.innerHTML = "First name is required";
//       setValidFirstName(false);
//       return false;
//     }

//     // make sure only accepts letters
//     else if (!regex.test(firstName)) {
//       firstNameHelp.innerHTML = "First name must only contain letters or ,.'-";
//       setValidFirstName(false);
//       return false;
//     } else if (firstName.length < 2) {
//       firstNameHelp.innerHTML = "First name must be at least 2 characters";
//       setValidFirstName(false);
//       return false;
//     } else {
//       firstNameHelp.innerHTML = "";
//       setValidFirstName(true);
//       return true;
//     }
//   };

//   const validateLastNameH = () => {
//     var lastNameHelper = document.getElementById("lastNameHelpH");
//     var regex = /^[a-z ,.'-]+$/i;

//     if (lastName === undefined || lastName === "") {
//       lastNameHelper.innerHTML = "last name is required";
//       setValidLastName(false);
//       return false;
//     }

//     // make sure only accepts letters
//     else if (!regex.test(lastName)) {
//       lastNameHelper.innerHTML = "Last name must only contain letters or ,.'-";
//       setValidLastName(false);
//       return false;
//     } else if (lastName.length < 2) {
//       lastNameHelper.innerHTML = "Last name must be at least 2 characters";
//       setValidLastName(false);
//       return false;
//     } else {
//       lastNameHelper.innerHTML = "";
//       setValidLastName(true);
//       return true;
//     }
//   };

// const validateDOB = () => {
//   var dobHelper = document.getElementById("dobHelp");

//   if (dob === undefined || dob === "") {
//     dobHelper.innerHTML = "DOB is required";
//     setValidDOB(false);
//     return false;
//   } else {
//     dobHelper.innerHTML = "";
//     setValidDOB(true);
//     return true;
//   }
// };

// const validatePhone = () => {
//   var phoneHelper = document.getElementById("phoneHelp");
//   var regex = /^\d{3}\-\d{3}\-\d{4}$/;

//   if (phone === undefined || phone === "") {
//     phoneHelper.innerHTML = "Phone number is required";
//     setValidPhone(false);
//     return false;
//   }

//   // make sure only accepts numbers
//   else if (!regex.test(phone)) {
//     phoneHelper.innerHTML = "Phone number must be in the format 123-456-7890";
//     setValidPhone(false);
//     return false;
//   } else {
//     phoneHelper.innerHTML = "";
//     setValidPhone(true);
//     return true;
//   }
// };

//   const validatePassword = () => {
//     var passwordHelper = document.getElementById("passwordHelp")!;
//     //pasword regex
//     var regex =
//       /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

//     if (password === undefined || password === "") {
//       passwordHelper.innerHTML = "Password is required";
//       setValidPassword(false);
//       return false;
//     } else if (password.length < 8) {
//       passwordHelper.innerHTML = "Password must be at least 8 characters";
//       setValidPassword(false);
//       return false;
//     } else if (!regex.test(password)) {
//       passwordHelper.innerHTML =
//         "Password must contain one letter, one number, and one special character";
//       setValidPassword(false);
//       return false;
//     } else {
//       passwordHelper.innerHTML = "";
//       setValidPassword(true);
//       return true;
//     }
//   };

//   const validateConfirmPassword = () => {
//     var confirmPasswordHelper = document.getElementById("passwordConfirmHelp");

//     if (passwordConfirm === undefined || passwordConfirm === "") {
//       confirmPasswordHelper.innerHTML = "Confirm password is required";
//       setValidPasswordConfirm(false);
//       return false;
//     } else if (passwordConfirm !== password) {
//       confirmPasswordHelper.innerHTML = "Passwords do not match";
//       setValidPasswordConfirm(false);
//       return false;
//     } else {
//       confirmPasswordHelper.innerHTML = "";
//       setValidPasswordConfirm(true);
//       return true;
//     }
//   };

//   const updatePasswordFunction = async () => {
//     if (password === passwordConfirm) {
//       const user = firebase.auth().currentUser;

//       user
//         .updatePassword(password)
//         .then(() => {
//           // Update successful.
//           return true;
//         })
//         .catch((error) => {
//           // An error happened.
//           console.log("an error occured");
//           console.log(error);
//           return false;

//           // An error ocurred
//           // ...
//         });
//     } else {
//       return false;
//       alert("Passwords do not match");
//     }
//   };

//   const createProfile = async () => {
//     if (
//       validateFirstName() &&
//       validateLastNameH() &&
//       validateDOB &&
//       validatePassword() &&
//       validateConfirmPassword() &&
//       validatePhone()
//     ) {
//       //console.log("valid");
//     } else {
//       //console.log("invalid");
//       return;
//     }

//     const passwordChangedStatus = updatePasswordFunction();

//     if (passwordChangedStatus) {
//       const dobTimeStamp = new Date(dob) || "";

//       // const dobArray = dob.split("-");
//       // const dobYear = dobArray[0];
//       // const dobMonth = dobArray[1];
//       // const dobDay = dobArray[2];
//       const formatedDob = reformatDOB(dob);

//       const ref = doc(getFirestore(), "users", user.uid);
//       await updateDoc(ref, {
//         dob: formatedDob,
//         firstName: firstName,
//         lastName: lastName,
//         phone: phone,
//         active: true,
//         dobTimeStamp: dobTimeStamp,
//       });
//       // // Create refs for both documents
//       // const userDoc = doc(getFirestore(), "users", user.uid);

//       // // Commit both docs together as a batch write.
//       // const batch = writeBatch(getFirestore());

//       // batch.set(userDoc, {
//       //   role: "member",
//       //   dob: dob,
//       //   hraComplete: false,
//       //   uid: user.uid,
//       //   firstName: firstName,
//       //   lastName: lastName,
//       //   email: user.email,
//       //   active: true,
//       //   coach: coach,
//       //   photoURL:
//       //     "https://firebasestorage.googleapis.com/v0/b/allied-wellness-22d1f.appspot.com/o/default.png?alt=media&token=c8450ca6-7ef6-49a3-95ff-2246452d62cc",
//       // });
//       // //batch.set(userDoc, { age: 20, weight: weight, uid: userData.user.uid, role: 'member', email: userData.user.email });

//       // //Add the user to the database
//       // await batch
//       //   .commit()
//       //   .then(() => {})
//       //   .catch((error) => {
//       //     console.log(error);
//       //   });
//     } else {
//       console.log("password not changed");
//       return;
//     }
//   };

  // const openBrowser = async (url) => {
  //   await Browser.open({ url: url });
  // };

//   const enableContinue = () => {
//     if (
//       validateFirstName &&
//       validateLastNameH &&
//       validateDOB &&
//       validatePassword &&
//       validateConfirmPassword &&
//       validatePhone
//     ) {
//       buttonHandler(true);
//     } else {
//       buttonHandler(false);
//     }

//   };

//   useEffect(() => {
//     validateDOB();
//     validateFirstName();
//     validateLastNameH();
//     validatePhone();
//   });

//   return (
//     <>
//       <IonCardTitle class="ion-text-start slideTitle">
//         Let's set up your Allied Account

//       </IonCardTitle>
//       <IonCardSubtitle class="ion-text-start slideSubTitle">
//         Please fill
//       </IonCardSubtitle>

// <IonGrid>
//   <IonRow>
//     <IonCol>
//       <IonItem>
//         <IonLabel position="floating">First Name</IonLabel>
//         <IonInput
//           autoCapitalize="on"
//           type="text"
//           value={firstName}
//           onIonChange={(e: any) => {
//             setFirstName(e.detail.value);
//           }}
//           onIonBlur={(e: any) => {
//             validateFirstName();
//           }}
//         />
//         <p className="formHelp" id="firstNameHelp"></p>
//       </IonItem>
//     </IonCol>
//     <IonCol>
//       <IonItem>
//         <IonLabel position="floating">Last Name</IonLabel>
//         <IonInput
//           autoCapitalize="on"
//           type="text"
//           value={lastName}
//           onIonChange={(e: any) => {
//             setLastName(e.detail.value);
//           }}
//           onIonBlur={(e: any) => {
//             validateLastNameH();
//           }}
//         />
//         <p className="formHelp" id="lastNameHelpH"></p>
//       </IonItem>
//     </IonCol>
//   </IonRow>
//   <IonRow>
//     <IonCol>
//       <IonItem>
//         <IonLabel position="floating">DOB</IonLabel>
//         <IonInput
//           type="date"
//           value={dob}
//           onIonChange={(e: any) => {
//             setDob(e.detail.value);
//           }}
//           onIonBlur={(e: any) => {
//             validateDOB();
//           }}
//         />
//         <p className="formHelp" id="dobHelp"></p>
//       </IonItem>
//       <IonItem>
//         <IonLabel position="floating">Phone</IonLabel>
//         <IonInput
//           value={phone}
//           onIonChange={(e: any) => {
//             setPhone(e.detail.value);
//           }}
//           onIonBlur={(e: any) => {
//             validatePhone();
//           }}
//         />
//         <p className="formHelp" id="phoneHelp"></p>
//       </IonItem>
//     </IonCol>
//   </IonRow>
//   <IonRow>
//     <IonCol>
//       <IonItem>
//         <IonLabel position="floating">New Password</IonLabel>
//         <IonInput
//           type="password"
//           value={password}
//           onIonChange={(e: any) => {
//             setPassword(e.detail.value);
//           }}
//           onIonBlur={(e: any) => {
//             validatePassword();
//           }}
//         />
//         <p className="formHelp" id="passwordHelp"></p>
//       </IonItem>
//     </IonCol>
//   </IonRow>
//   <IonRow>
//     <IonCol>
//       <IonItem>
//         <IonLabel position="floating">Confirm New Password</IonLabel>
//         <IonInput
//           type="password"
//           value={passwordConfirm}
//           onIonChange={(e: any) => {
//             setPasswordConfirm(e.detail.value);
//           }}
//           onIonBlur={(e: any) => {
//             validateConfirmPassword();
//           }}
//         />
//         <p className="formHelp" id="passwordConfirmHelp"></p>
//       </IonItem>
//     </IonCol>
//   </IonRow>
// </IonGrid>
// <IonItem>
//   <p>
//     I agree to the{" "}
//     <a
//       id="trigger-terms"
//       href=""
//       onClick={() => openBrowser("https://alliedwellness.com/app-terms/")}
//       className="terms"
//     >
//       terms of service
//     </a>
//   </p>
//   <IonCheckbox
//     slot="start"
//     onIonChange={(e) => setChecked(e.detail.checked)}
//   />
// </IonItem>
//       {/* <IonButton
//         onClick={() => {
//           changeDirection("next");
//           createProfile();
//         }}
//         className="firstButton"
//         expand="block"
//         disabled={
//           !validFisrtName ||
//           !validLastName ||
//           !validDOB ||
//           !validPassword ||
//           !validPasswordConfirm ||
//           !checked
//         }
//       >
//         CONTINUE
//       </IonButton> */}
//     </>
//   );
// };

// export default SetUpProfile;
