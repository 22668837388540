import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import { useState } from "react";
import React from "react";
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";

const ChangePassword: React.FC = () => {
  const [passwordOld, setPasswordOld] = useState();
  const [password, setPassword] = React.useState<string>();
  const [confirmPassword, setConfirmPassword] = React.useState<string>();

  const validatePassword = () => {
    var passwordHelper = document.getElementById("passwordHelp");
    //pasword regex
    var regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (password === undefined || password === "") {
      passwordHelper.innerHTML = "Password is required";
      return false;
    } else if (password.length < 8) {
      passwordHelper.innerHTML = "Password must be at least 8 characters";
      return false;
    } else if (!regex.test(password)) {
      passwordHelper.innerHTML =
        "Password must contain at least one number and one letter";
      return false;
    } else {
      passwordHelper.innerHTML = "";
      return true;
    }
  };

  const validateConfirmPassword = () => {
    var confirmPasswordHelper = document.getElementById("passwordConfirmHelp");

    if (confirmPassword === undefined || confirmPassword === "") {
      confirmPasswordHelper.innerHTML = "Confirm password is required";
      return false;
    } else if (confirmPassword !== password) {
      confirmPasswordHelper.innerHTML = "Passwords do not match";
      return false;
    } else {
      confirmPasswordHelper.innerHTML = "";
      return true;
    }
  };

  const updatePasswordAuth = async (e) => {
    e.preventDefault();

    if (validatePassword() && validateConfirmPassword()) {
      const auth = getAuth();
      const user = auth.currentUser;

      const credential = EmailAuthProvider.credential(user.email, passwordOld);

      reauthenticateWithCredential(user, credential)
        .then(() => {
          // User re-authenticated.

          updatePassword(user, password)
            .then(() => {
              window.location.href = "/profile";

              auth.signOut();
              window.location.href = "/login";
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          var oldPasswordHelp = document.getElementById("oldPasswordHelp");
          oldPasswordHelp.innerHTML = "Old password is incorrect";
          console.log(error);
        });
    } else {
      //alert("Something went wrong, please try again later");
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="settings" />
          </IonButtons>

          <IonTitle className="mainPageTitle">Change Password</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <form onSubmit={updatePasswordAuth}>
          <IonItem>
            <IonLabel position="stacked">Current Password</IonLabel>
            <IonInput
              type="password"
              onIonChange={(e: any) => {
                setPasswordOld(e.detail.value);
              }}
            ></IonInput>
            <p className="formHelp" id="oldPasswordHelp"></p>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">New Password</IonLabel>
            <IonInput
              type="password"
              onIonChange={(e: any) => {
                setPassword(e.detail.value);
              }}
              onIonBlur={validatePassword}
            ></IonInput>
            <p className="formHelp" id="passwordHelp"></p>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Confirm New Password</IonLabel>
            <IonInput
              type="password"
              onIonChange={(e: any) => {
                setConfirmPassword(e.detail.value);
              }}
              onIonBlur={validateConfirmPassword}
            ></IonInput>
            <p className="formHelp" id="passwordConfirmHelp"></p>
          </IonItem>

          <div id="login-help"></div>

          <IonButton type="submit" expand="block" className="ion-padding">
            Update
          </IonButton>
        </form>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
