import { useContext, useEffect, useState } from "react";
import "./style.css";
import { UserContext } from "../../../../lib/context";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
} from "firebase/firestore";
import Popup from "./Popup";

type Props = {};

const AnswerFeedback: React.FC<Props> = () => {
  const [needFeedback, setNeedFeedback] = useState([]);
  const { user } = useContext(UserContext);

  const fetchNeedFeedback = async () => {
    var data = [];

    const q = query(
      collection(getFirestore(), "koroQuestions"),
      where("hidden", "==", false),
      where("status", "==", "waitingForMember"),
      where("poster", "==", user.uid)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data() });
    });

    // console.log("data", data)

    setNeedFeedback(data);
  };

  useEffect(() => {
    fetchNeedFeedback();
  }, []);

  return (<>
  {needFeedback.map((question) => {
    return (
      <Popup question={question} />
    );
  })}
  </>);
};

export default AnswerFeedback;
