/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
//import spinner
import React, { useEffect, useState } from "react";
import "./HRAStyle.css";
import "firebase/compat/auth";

type Props = {
  slides;
  buttonHandler;
  profileHandler;
  profile;
  slideIndex;
};

const AddAddress: React.FC<Props> = ({
  slides,
  buttonHandler,
  profileHandler,
  profile,
  slideIndex,
}) => {
  const [addr1, setAddr1] = useState(profile ? profile.addr1 : "");
  const [addr2, setAddr2] = useState(profile ? profile.addr2 : "");
  const [city, setCity] = useState(profile ? profile.city : "");
  const [state, setState] = useState(profile ? profile.state : "");
  const [zip, setZip] = useState(profile ? profile.zip : "");

  const states = [
    { name: "None", value: "" },

    {
      name: "Alabama",
      abbreviation: "AL",
    },
    {
      name: "Alaska",
      abbreviation: "AK",
    },
    {
      name: "American Samoa",
      abbreviation: "AS",
    },
    {
      name: "Arizona",
      abbreviation: "AZ",
    },
    {
      name: "Arkansas",
      abbreviation: "AR",
    },
    {
      name: "California",
      abbreviation: "CA",
    },
    {
      name: "Colorado",
      abbreviation: "CO",
    },
    {
      name: "Connecticut",
      abbreviation: "CT",
    },
    {
      name: "Delaware",
      abbreviation: "DE",
    },
    {
      name: "District Of Columbia",
      abbreviation: "DC",
    },
    {
      name: "Federated States Of Micronesia",
      abbreviation: "FM",
    },
    {
      name: "Florida",
      abbreviation: "FL",
    },
    {
      name: "Georgia",
      abbreviation: "GA",
    },
    {
      name: "Guam",
      abbreviation: "GU",
    },
    {
      name: "Hawaii",
      abbreviation: "HI",
    },
    {
      name: "Idaho",
      abbreviation: "ID",
    },
    {
      name: "Illinois",
      abbreviation: "IL",
    },
    {
      name: "Indiana",
      abbreviation: "IN",
    },
    {
      name: "Iowa",
      abbreviation: "IA",
    },
    {
      name: "Kansas",
      abbreviation: "KS",
    },
    {
      name: "Kentucky",
      abbreviation: "KY",
    },
    {
      name: "Louisiana",
      abbreviation: "LA",
    },
    {
      name: "Maine",
      abbreviation: "ME",
    },
    {
      name: "Marshall Islands",
      abbreviation: "MH",
    },
    {
      name: "Maryland",
      abbreviation: "MD",
    },
    {
      name: "Massachusetts",
      abbreviation: "MA",
    },
    {
      name: "Michigan",
      abbreviation: "MI",
    },
    {
      name: "Minnesota",
      abbreviation: "MN",
    },
    {
      name: "Mississippi",
      abbreviation: "MS",
    },
    {
      name: "Missouri",
      abbreviation: "MO",
    },
    {
      name: "Montana",
      abbreviation: "MT",
    },
    {
      name: "Nebraska",
      abbreviation: "NE",
    },
    {
      name: "Nevada",
      abbreviation: "NV",
    },
    {
      name: "New Hampshire",
      abbreviation: "NH",
    },
    {
      name: "New Jersey",
      abbreviation: "NJ",
    },
    {
      name: "New Mexico",
      abbreviation: "NM",
    },
    {
      name: "New York",
      abbreviation: "NY",
    },
    {
      name: "North Carolina",
      abbreviation: "NC",
    },
    {
      name: "North Dakota",
      abbreviation: "ND",
    },
    {
      name: "Northern Mariana Islands",
      abbreviation: "MP",
    },
    {
      name: "Ohio",
      abbreviation: "OH",
    },
    {
      name: "Oklahoma",
      abbreviation: "OK",
    },
    {
      name: "Oregon",
      abbreviation: "OR",
    },
    {
      name: "Palau",
      abbreviation: "PW",
    },
    {
      name: "Pennsylvania",
      abbreviation: "PA",
    },
    {
      name: "Puerto Rico",
      abbreviation: "PR",
    },
    {
      name: "Rhode Island",
      abbreviation: "RI",
    },
    {
      name: "South Carolina",
      abbreviation: "SC",
    },
    {
      name: "South Dakota",
      abbreviation: "SD",
    },
    {
      name: "Tennessee",
      abbreviation: "TN",
    },
    {
      name: "Texas",
      abbreviation: "TX",
    },
    {
      name: "Utah",
      abbreviation: "UT",
    },
    {
      name: "Vermont",
      abbreviation: "VT",
    },
    {
      name: "Virgin Islands",
      abbreviation: "VI",
    },
    {
      name: "Virginia",
      abbreviation: "VA",
    },
    {
      name: "Washington",
      abbreviation: "WA",
    },
    {
      name: "West Virginia",
      abbreviation: "WV",
    },
    {
      name: "Wisconsin",
      abbreviation: "WI",
    },
    {
      name: "Wyoming",
      abbreviation: "WY",
    },
  ];

  useEffect(() => {
    if(slideIndex === 1){
      buttonHandler(false);
    }
  }, [slideIndex]);

  const renderStates = states.map((state) => (
    <IonSelectOption key={state.abbreviation} value={state.name}>
      {state.name}
    </IonSelectOption>
  ));

  return (
    <>
      <IonCardTitle class="ion-text-start slideTitle">
        We need your address for Telemedicine
      </IonCardTitle>
      <IonCardSubtitle class="ion-text-start slideSubTitle">
        Please fill
      </IonCardSubtitle>

      <IonGrid>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Address 1</IonLabel>
              <IonInput
                autoCapitalize="on"
                type="text"
                value={addr1}
                onIonChange={(e: any) => {
                  setAddr1(e.detail.value);
                  profile.addr1 = e.detail.value;
                  profileHandler(profile);
                }}
              />
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Address 2</IonLabel>
              <IonInput
                autoCapitalize="on"
                type="text"
                value={addr2}
                onIonChange={(e: any) => {
                  setAddr2(e.detail.value);
                  profile.addr2 = e.detail.value;
                  profileHandler(profile);
                }}
              />
              <p className="formHelp" id="lastNameHelpH"></p>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">City</IonLabel>
              <IonInput
                autoCapitalize="on"
                type="text"
                value={city}
                onIonChange={(e: any) => {
                  setCity(e.detail.value);
                  profile.city = e.detail.value;
                  profileHandler(profile);
                }}
              />
              <p className="formHelp" id="lastNameHelpH"></p>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">State</IonLabel>
              <IonSelect
                value={state}
                onIonChange={(e) => {
                  setState(e.detail.value);
                  profile.state = e.detail.value;
                  profileHandler(profile);
                }}
              >
                {renderStates}
              </IonSelect>
              <p className="formHelp" id="dobHelp"></p>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">ZIP</IonLabel>
              <IonInput
                autoCapitalize="on"
                type="text"
                value={zip}
                onIonChange={(e: any) => {
                  setZip(e.detail.value);
                  profile.zip = e.detail.value;
                  profileHandler(profile);
                }}
              />
              <p className="formHelp" id="lastNameHelpH"></p>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default AddAddress;