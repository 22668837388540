import { addDoc, collection, deleteDoc, doc, getDocs, getFirestore, query, where } from "firebase/firestore";
import { GlucoseLogTypes } from "../Types/LogsTypes";

export async function updateLogGlucoseDB(userData: Partial<GlucoseLogTypes>) {
  const glucoseCollection = collection(getFirestore(), "glucose");
  const docRef = await addDoc(glucoseCollection, userData);
  return docRef.id

}

export async function deleteLogGlucoseDB(docId: string) {
  const glucoseDocRef = doc(getFirestore(), "glucose", docId);
  await deleteDoc(glucoseDocRef);
}


export async function fetchLogGlucoseCollection(memberUid: string, date: string) {
  const q = query(
    collection(getFirestore(), "glucose"),
    where("uid", "==", memberUid),
    where("date", "==", date)
  );
  const querySnapshot = await getDocs(q);
  const getUserData = querySnapshot.docs.map((doc) => {
    const data = doc.data() as GlucoseLogTypes;
    return { id: doc.id, ...data };
  });
  return getUserData;
}