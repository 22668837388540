import { InterestList } from "../../Interfaces/Interest";
import "./style.css";

type Props = {
  id: string;
}

const RenderInterestTitle: React.FC<Props> = ({id}) => {

    // search InterestList for interest.id == id and get title
    const interest = InterestList.find((interest) => interest.id === id);
    return < >{interest.title}</>;

};

export default RenderInterestTitle;