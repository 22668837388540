import React, { useContext } from "react";
import {
  IonTabs,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonBadge,
  IonItem,
} from "@ionic/react";
import { Route, Redirect } from "react-router";
import { calendar, home, chatbox, book, eye, people } from "ionicons/icons";
import Home from "./Home";
import Chat from "./Chat";
import Appointment from "./Appointment";
import Journal from "./Journal";
import Explore from "./Explore";
import { profile } from "console";
import { UserContext } from "../../../lib/context";
import axios from "axios";
import { off } from "process";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { PushNotifications } from "@capacitor/push-notifications";
import Dietitian from "../../../components/Appointment/Schedule/Dietitian";
import { VisitTypesList } from "../../../Interfaces/Appointment/VisitType";
import Community from "./Community";
import IndividualQuestion from "../../../components/Bodee.tsx/Koro/IndividualQuestion";
// import { CometChat } from "@cometchat-pro/chat";

interface MainTabsProps {}

const MainTabs: React.FC<MainTabsProps> = () => {
  const { user, profile } = useContext(UserContext);
  const [notificationNumber, setNotificationNumber] = React.useState();
  const [selectedTab, setSelectedTab] = React.useState("0");

  const getUnreadMessages = async () => {
    console.log("Getting unread messages");
    const config = {
      method: "post",
      url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/Notification-GetCometChatNotificationsNumber`,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: {},
    };

    axios(config)
      .then(function (response) {
        //console.log(JSON.stringify(response.data));
        if (response.data == "0") {
          setNotificationNumber(null);
        } else {
          setNotificationNumber(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changingTab = (tab) => {
    //check to see if token is expired
    const token = user.accessToken;
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const decodedToken = JSON.parse(window.atob(base64));
    const exp = decodedToken.exp;
    const now = new Date().getTime() / 1000;
    console.log("exp", exp);
    PushNotifications.removeAllDeliveredNotifications().catch((err) =>
      console.log(err)
    );

    if (exp < now) {
      console.log("token expired");
      user
        .getIdToken(true)
        .then(function (idToken) {
          //update tracking collection
          updateDoc(doc(getFirestore(), "tracking", user.uid), {
            lastUsed: new Date(),
            email: user.email,
            name: profile.firstName + " " + profile.lastName,
          });
        })
        .catch(function (error) {
          console.log("error refreshing token", error);
        });
    } else {
      console.log("token not expired");
    }

    console.log("changing from", selectedTab, "to", tab);

    if (tab === "chat") {
      getUnreadMessages();
    }

    if (selectedTab === "chat") {
      getUnreadMessages();
    }

    if (tab === "community") {
      updateDoc(doc(getFirestore(), "koroPublicAvatars", user.uid), {
        lastUsed: new Date(),
      });
    }

    setSelectedTab(tab);
  };

  React.useEffect(() => {
    // get unread messages from api using axios
    getUnreadMessages();
  }, []);

  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect exact path="/tabs" to="/tabs/home" />
        {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}
        <Route path="/tabs/home" render={() => <Home />} exact={true} />
        <Route path="/tabs/chat/:id" render={() => <Chat />} exact={true} />
        <Route path="/tabs/chat" render={() => <Chat />} exact={true} />
        <Route
          path="/tabs/community"
          render={() => <Community />}
          exact={true}
        />
        <Route
          path="/tabs/community/:id"
          render={() => <IndividualQuestion />}
          exact={true}
        />

        <Route
          path="/tabs/appointment"
          render={() => <Appointment />}
          exact={true}
        />

        <Route path="/tabs/journal" render={() => <Journal />} exact={true} />
        <Route path="/tabs/explore" render={() => <Explore />} exact={true} />

        <Route path="/" render={() => <Home />} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton
          tab="home"
          href="/tabs/home"
          onClick={() => changingTab("home")}
        >
          <IonIcon icon={home} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="chat"
          href="/tabs/chat"
          disabled={!profile.hasCoaching || !profile.active}
          onClick={() => changingTab("chat")}
        >
          {notificationNumber ? (
            <IonBadge color="danger">{notificationNumber}</IonBadge>
          ) : null}
          <IonIcon icon={chatbox} />
          <IonLabel>Chat</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="apointment"
          href="/tabs/appointment"
          disabled={!profile.hasCoaching || !profile.active || process.env.REACT_APP_ENVIRONMENT !== "prod"}
          onClick={() => changingTab("appointment")}
        >
          <IonIcon icon={calendar} />
          <IonLabel>Appointment</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="community"
          href="/tabs/community"
          onClick={() => changingTab("community")}
        >
          <IonIcon icon={people} />
          <IonLabel>Community</IonLabel>
        </IonTabButton>
        <IonTabButton
          tab="explore"
          href="/tabs/explore"
          disabled={!profile.hasCoaching || !profile.active}
          onClick={() => changingTab("explore")}
        >
          <IonIcon icon={eye} />
          <IonLabel>Explore</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
