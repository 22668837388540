import { CometChat } from "@cometchat-pro/chat";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonMenuToggle,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
//import { CometChatConversationListWithMessages } from "../../components/chat/cometchat-pro-react-ui-kit/CometChatWorkspace/src";
import {  CometChatConversation, CometChatConversationListWithMessages} from "../../../components/chat/cometchat-pro-react-ui-kit-master/CometChatWorkspace/src";
import { UserContext } from "../../../lib/context";
//import cometchat widget
import "./Chat.css";
import Loader from "../../../components/Loaderv2";
import {
  query,
  collection,
  getFirestore,
  limit,
  where,
  orderBy,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";

const Chat: React.FC = () => {
  const { user, profile } = useContext(UserContext);
  const [loggedInUser, setLoggedInUser] = useState<any>(false);
  const [openChatWithUser, setOpenChatWithUser] = useState<string>("");

  /**
   * get first day of month and return in format yyyy-mm-ddT00:00:00
   * @param {string} str
   * @return {string} string with no special char
   */
  function removeSpecialChar(str) {
    return str.replace(/[^a-zA-Z0-9]/g, "");
  }

  function loginCometUser() {
    const authKey = process.env.REACT_APP_COMET_AUTH_KEY;
    const uid = removeSpecialChar(user.uid);

    CometChat.login(uid, authKey).then(
      (user) => {
        console.log("CometChat Login Successfull");
        setLoggedInUser(true);
      },
      (error) => {
        console.log("Login failed with exception:", { error });
        setLoggedInUser(false);
      }
    );
  }

  const getLastPartOfUrl = () => {
    const url = window.location.href;
    const urlParts = url.split("/");
    const lastPart = urlParts[urlParts.length - 1];
    console.log("last part of url", lastPart);
    if (lastPart !== "chat" && lastPart !== "messages") {
      setOpenChatWithUser(lastPart);
    }
    return lastPart;
  };

  useEffect(() => {
    //set timeout for login
    getLastPartOfUrl();
    setTimeout(() => {
      loginCometUser();
    }, 3000);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="mainHeaderBackground">
          <IonButtons slot="start">
            <IonMenuToggle className="white">
              <IonMenuButton className="white" />
            </IonMenuToggle>
          </IonButtons>
          <IonTitle className="mainPageTitleTEst">Chat</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className=" ion-text-center">
        {loggedInUser && profile ? (
          <CometChatConversationListWithMessages
            chatWithUser={openChatWithUser}
          />
        ) : (
          <Loader />
        )}
         {/* <CometChatConversation
            chatWithGroup={"b80e79d5-e99a-4727-b197-672896d17cbe"}
          /> */}
      </IonContent>
    </IonPage>
  );
};

export default Chat;
