/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import React, { useContext, useEffect, useState } from "react";

import "./ManageSub.css";

import { UserContext } from "../../../lib/context";

import { createPortalSession } from "../../../stripe/createPortalSession";
import { createCheckoutSession } from "../../../stripe/createCheckoutSession";
import {
  isUserBasic,
  isUserPremium,
  isUserStripe,
  userSubType,
} from "../../../stripe/isUser";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const ManageSub: React.FC = () => {
  const { user, profile } = useContext(UserContext);

  const [freeTrial, setFreeTrial] = useState(null);

  const [userStripe, setUserStripe] = useState(false);

  const [subType, setUserType] = useState(null);

  // get user claims firebase auth

  const getClaims = async () => {
    isUserBasic().then((result) => {
      console.log("basic", result);
    });

    isUserPremium().then((result) => {
      console.log("premium", result);
    });
  };

  const getFreeTrial = async () => {
    const docRef = doc(getFirestore(), "freeTrials", user.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setFreeTrial(docSnap.data());
    } else {
      setFreeTrial(null);
    }
  };

  const isUserStripeAccount = () => {
    isUserStripe().then((result) => {
      setUserStripe(result);
    });
  };

  const getUserSubType = () => {
    userSubType().then((result) => {
      setUserType(result);
    });
  };

  useEffect(() => {
    getFreeTrial();
    isUserStripeAccount();
    getUserSubType();
  }, [profile]);

  const FreeTrialEnd = () => {
    return (
      <>
        <IonLabel className="ion-text-center">
          <h1> Your free trial has ended. </h1>
          {/* <h1> Reactive to gain back full access to Allied Wellness</h1> */}
        </IonLabel>

        <div className="ion-margin">
          <IonLabel>
            <h2 className="ion-text-center">
              Reactivate to get full access to Allied Wellness features.
            </h2>
          </IonLabel>
        </div>
      </>
    );
  };

  const getDaysLeft = (date) => {
    // get endDate from date.seconds
    const endDate = new Date(date.seconds * 1000);
    const today = new Date();

    // get difference in days
    const diffTime = Math.abs(endDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  };

  const FreeTrialActive = () => {
    return (
      <>
        <IonLabel className="ion-text-center">
          <h1>
            {" "}
            You have{" "}
            <span className="bold">
              {getDaysLeft(freeTrial?.endDate)} days
            </span>{" "}
            left
          </h1>
          {/* <h1> Reactive to gain back full access to Allied Wellness</h1> */}
        </IonLabel>

        <div className="ion-margin">
          <IonLabel>
            <h2 className="ion-text-center">
              Get a head start and purchase a subscription now!
            </h2>
          </IonLabel>
        </div>
      </>
    );
  };

  const SubscriptionActive = () => {
    return (
      <>
        <IonLabel className="ion-text-center">
          <h1>
            You are currently subscribed to:{" "}
            <span className="bold">
              {subType == "basic" ? "Basic" : "Coach Pro"}
            </span>
          </h1>
        </IonLabel>

        <div className="ion-margin">
          <IonLabel>
            <h2 className="ion-text-center">
              To manage your subscription, click the button below.
            </h2>
          </IonLabel>
        </div>
      </>
    );
  };

  const CoachProCard = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (loading) {
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    }, [loading]);

    return (
      <IonCard className="ion-padding" color="primary">
        <IonLabel>
          <h2 className="ion-text-center">Premium</h2>
          <h1 className="ion-text-center">
            <span className="bold price">$59.99</span>/Month
          </h1>
        </IonLabel>

        <IonLabel>
          <p>
            <p className="ion-text-center packageSubTitle">Includes all the features in the basic plan plus…</p>
            <ul className="featureListPremium">
              <li>Personal health coach</li>
              <li>Unlimited calls and messaging</li>
              <li>Personalized health plans</li>
              {/* <li>$0 Telemedicine, Therapy, and Psychiatry Visits</li>
              <li>Access to Premium Content</li>
              <li>Face scan vitals</li>
              <li>Meal, mood, and exercise tracking</li> */}
            </ul>
          </p>
        </IonLabel>

        <IonButton
          expand="block"
          shape="round"
          fill="solid"
          className="subButtonPremium ion-margin"
          onClick={() => {
            // getClaims();
            setLoading(true);
            createCheckoutSession(
              user.uid,
              process.env.REACT_APP_STRIPE_PREMIUM_PRICE_ID
            );
          }}
        >
          {loading ? <IonSpinner /> : "Subscribe"}
        </IonButton>
      </IonCard>
    );
  };

  const BasicCard = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (loading) {
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    }, [loading]);

    return (
      <IonCard className="ion-padding" >
        <IonLabel>
          <h2 className="ion-text-center">Basic</h2>
          <h1 className="ion-text-center">
            <span className="bold price">$19.99</span>/Month
          </h1>
        </IonLabel>

        <IonLabel>
          <p>
          <ul className="featureListBasic">
              <li>$0 Telemedicine Visits</li>
              <li>$0 Therapy Visits</li>
              <li>$0 Psychiatry Visits</li>
              <li>Access to Premium Content</li>
              <li>Face scan vitals</li>
              <li>Meal, mood, and exercise tracking</li>
            </ul>
          </p>
        </IonLabel>

        <IonButton
          expand="block"
          shape="round"
          className="subButtonBasic ion-margin"
          onClick={() => {
            setLoading(true);
            createCheckoutSession(
              user.uid,
              process.env.REACT_APP_STRIPE_BASIC_PRICE_ID
            );
          }}
        >
          {loading ? <IonSpinner /> : "Subscribe"}
        </IonButton>
      </IonCard>
    );
  };

  const ManageSubscriptionButton = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (loading) {
        setTimeout(() => {
          setLoading(false);
        }, 5000);
      }
    }, [loading]);

    return (
      <IonButton
        expand="block"
        shape="round"
        className="subButton ion-margin"
        onClick={() => {
          setLoading(true);
          createPortalSession(user.uid);
        }}
      >
        {loading ? <IonSpinner /> : "Manage Subscription"}
      </IonButton>
    );
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="tabs/home" />
          </IonButtons>

          <IonTitle className="mainPageTitle">Subscription</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen className="ion-padding">
        <div className="center-container ion-margin-top">
          <img
            src="/assets/icon/icon.png"
            className="loginManageSub"
            alt="logo"
          />
        </div>

        {userStripe ? (
          <SubscriptionActive />
        ) : (
          <>{freeTrial?.active ? <FreeTrialActive /> : <FreeTrialEnd />}</>
        )}

        {userStripe ? (
          <ManageSubscriptionButton />
        ) : (
          <>
            <CoachProCard /> <BasicCard />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ManageSub;
