import React from "react";
import "../Popup.css";

const Popup_msi = () => {
  return (
    <div className="ion-padding popup-wrapper">
      <ul>
        <li>
          Currently, your normal stress results in a moderate Adrenal Stress
          Index (ASI) that is not considered harmful to your psychological and
          physical health.
        </li>
        <li>
          Most people work optimally having an ASI within this stress zone.
        </li>
        <li>
          However, if your stress remains within this zone under all situations,
          including moments of relaxation, then you may benefit from learning
          effective relaxation techniques
        </li>
      </ul>

      <p>
        Just as your resting heart rate can vary constantly it is also normal
        for stress levels to change constantly. This is because your heart rate
        variability (HRV) is also changing constantly. As a result it is not
        unusual to experience variations in your ASI results by as much as 1.0
        when you take consecutive measurements.
      </p>
    </div>
  );
};

export default Popup_msi;
