import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonRouterLink,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
//import spinner
import React, { useContext, useEffect, useState } from "react";
import "firebase/compat/auth";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { doc, getFirestore, getDoc } from "firebase/firestore";
import { UserContext } from "../../../lib/context";

const PhysicalTherapist: React.FC = () => {
  //email uststate
  const [coach, setCoach] = useState<any>({});
  const { user, profile } = useContext(UserContext);

  //get coach document from firestore


  const clearIonicHistory = () => {
    //clear history
    //log history
    setTimeout(() => { window.location.reload(); }, 3000);

  };



  useCalendlyEventListener({
    onEventScheduled: (e) => clearIonicHistory(),
  });

  document.addEventListener("ionBackButton", (ev) => {
    console.log("Back button pressed!");
})

  return (
    <>
    <IonPage>
      <IonHeader>
        <IonToolbar className="mainHeaderBackground">
          <IonButtons slot="start" className="mainPageTitleTEst">
            <IonBackButton
              className="mainPageTitleTEst"
              defaultHref="/tabs/appointment"
            />

            <IonRouterLink href="/tabs/appointment" slot="start">
            </IonRouterLink>
          </IonButtons>
          <IonTitle className="mainPageTitleTEst">Physical Therapist</IonTitle>
        </IonToolbar>
      </IonHeader>
    <IonContent>
        <InlineWidget
          url={
              "https://calendly.com/allied-wellness-coaches-app/allied-wellness-app-physical-therapist" +
                `?hide_gdpr_banner=1&email=${user.email}&name=${profile.firstName}%20${profile.lastName}`
          }
          pageSettings={{
            hideLandingPageDetails: true,
            hideEventTypeDetails: true,
            hideGdprBanner: true,
            primaryColor: "456673",
            textColor: "456673",
          }}
          
        />
        </IonContent>
        </IonPage>
    </>
  );
};

export default PhysicalTherapist;
