/* eslint-disable no-restricted-globals */
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonContent,
  IonAvatar,
  IonChip,
  AlertInput,
  useIonAlert,
  IonButton,
  IonIcon,
  IonModal,
  IonList,
  IonItem,
  IonLabel,
  IonMenuToggle,
} from "@ionic/react";
import "./style.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../lib/context";
import { getAge, signUserOut } from "../../../../../lib/helper";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { InterestList } from "../../../Interfaces/Interest";

import { avatars } from "../../../Interfaces/Avatars";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  closeOutline,
  addOutline,
  helpOutline,
  homeOutline,
  personOutline,
  settingsOutline,
  timerOutline,
} from "ionicons/icons";
import { title } from "process";
import Activity from "./Activity";
import MyQuestions from "./MyQuestions";
import Avatar from "avataaars";
import { generateRandomAvatarOptions } from "../../../../../lib/Helpers/avatar";
import { ClinicalConcernsList } from "../../../Interfaces/ClinicalConcerns";
import { GoalsList } from "../../../Interfaces/Goals";

type Props = {};

const routes = {
  appPages: [
    { title: "Activity", path: "activity", icon: timerOutline },
    // { title: 'Downvoted Post', path: '/tabs/map', icon: mapOutline },
    { title: "My Questions", path: "my-questions", icon: helpOutline },
  ],
};

interface Pages {
  title: string;
  path: string;
  icon: string;
  routerDirection?: string;
}

const Template: React.FC<Props> = () => {
  const { koroPublicAvatar, profile } = useContext(UserContext);

  const [interest, setInterest] = useState(koroPublicAvatar.koroInterest || []);
  const [goal, setGoal] = useState(koroPublicAvatar.koroGoals || []);

  const [profileOpen, setProfileOpen] = useState(false);

  const [presentAlert] = useIonAlert();

  const ListOfInterest: AlertInput[] = [];
  const ListOfGoals: AlertInput[] = [];

  const randomConfig = () => {
    // setAvatarConfig(generateRandomAvatarOptions());
    updateDoc(doc(getFirestore(), "koroPublicAvatars", koroPublicAvatar.uid), {
      avatarConfig: generateRandomAvatarOptions(),
    });
  };

  InterestList.map((interest, index) => {
    ListOfInterest.push({
      label: interest.title,
      type: "checkbox",
      value: interest.id,
      checked: koroPublicAvatar.koroInterest?.includes(interest.id),
    });
  });

  GoalsList.map((goal, index) => {
    ListOfGoals.push({
      label: goal.title,
      type: "checkbox",
      value: goal.id,
      checked: koroPublicAvatar.koroGoals?.includes(goal.id),
    });
  });

  const updateInterest = async (interest: string[]) => {
    console.log(interest);
    // update doc firebase
    const userDoc = doc(
      getFirestore(),
      "koroPublicAvatars",
      koroPublicAvatar.uid
    );

    // Set the "capital" field of the city 'DC'
    await updateDoc(userDoc, {
      koroInterest: interest,
    }).then(() => {
      // console.log("success");
      // update profile doc now
      const profileDoc = doc(getFirestore(), "users", profile.uid);
      updateDoc(profileDoc, {
        koroInterest: interest,
      });
      
    });
  };

  const updateGoal = async (goal: string[]) => {
    console.log(goal);
    // update doc firebase
    const userDoc = doc(
      getFirestore(),
      "koroPublicAvatars",
      koroPublicAvatar.uid
    );

    // Set the "capital" field of the city 'DC'
    await updateDoc(userDoc, {
      koroGoals: goal,
    }).then(() => {
      // console.log("success");
      // update profile doc now
      const profileDoc = doc(getFirestore(), "users", profile.uid);
      updateDoc(profileDoc, {
        koroGoals: goal,
      });
      
    });
  };

  const RenderInterestTag = ({ tag }) => {
    // find tag in GoalLists.id\

    const tagObj = InterestList.find((item) => item.id === tag);

    if (tagObj == undefined) return null;

    return <IonChip>{tagObj.title}</IonChip>;
  };

  const RenderGoalTag = ({ tag }) => {
    // find tag in GoalLists.id\

    const tagObj = GoalsList.find((item) => item.id === tag);

    if (tagObj == undefined) return null;

    return <IonChip>{tagObj.title}</IonChip>;
  };

  return (
    <>
      <IonButtons slot="end">
        {/* <IonAvatar className="profileAvatarIcon">
          <img
            src={koroPublicAvatar.avatar}
            onClick={() => setProfileOpen(true)}
          />
        </IonAvatar> */}
        <div onClick={() => setProfileOpen(true)}>
          <Avatar
            style={{ width: "60px", height: "60px" }}
            avatarStyle="Circle"
            {...koroPublicAvatar.avatarConfig}
          />
        </div>
      </IonButtons>
      <IonModal isOpen={profileOpen} onDidDismiss={() => setProfileOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton onClick={() => setProfileOpen(false)} slot="start">
                Back
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="backgroundColor">
          <div className="ionPaddingTopLarge">
            <div className="setPublicAvatarContainer">
              {/* <IonButton onClick={() => tetet()}>test</IonButton> */}
              {/* <Swiper
                slidesPerView={2.5}
                className=""
                spaceBetween={10}
                initialSlide={getIndexOfAvatar() - 1}
              >
                {avatars.map((avatar) => (
                  <SwiperSlide key={avatar.id}>
                    <IonAvatar
                      className={
                        profilePhoto === avatar.url
                          ? "setAvatarPhoto setAvatarPhotoSelected"
                          : "setAvatarPhoto"
                      }
                      onClick={() => updateAvatartUrl(avatar.url)}
                    >
                      <img src={avatar.url} alt="profile" />
                    </IonAvatar>
                  </SwiperSlide>
                ))}
              </Swiper> */}
              <p className="ion-text-center">
                {" "}
                <Avatar
                  style={{ width: "150px", height: "150px" }}
                  avatarStyle="Circle"
                  {...koroPublicAvatar.avatarConfig}
                />
                <p>
                  <IonButton onClick={randomConfig}>Randomize</IonButton>
                </p>
              </p>

              <h2 className="ion-text-center">
                {" "}
                {koroPublicAvatar.displayName}
              </h2>
              <p className="ion-text-center">
                {koroPublicAvatar.gender === "male" ? "Male" : "Female"},{" "}
                <span className="bold">{getAge(koroPublicAvatar.dob)}</span>
              </p>

              <div className="ionPaddingTopSmall">
                <h3 className="ion-text-center">Interest</h3>
                <div className="ion-text-center">
                  {koroPublicAvatar.koroInterest ? (
                    <>
                      {koroPublicAvatar.koroInterest.map((interest) => (
                        <RenderInterestTag tag={interest} />
                      ))}
                    </>
                  ) : (
                    <p className="ion-text-center">No interest selected</p>
                  )}
                </div>
              </div>
              <p
                className="link ion-text-center"
                onClick={() =>
                  presentAlert({
                    header: "Select Your Interest",
                    buttons: [
                      { text: "Cancel" },
                      {
                        text: "Ok",
                        handler: (d) => {
                          updateInterest(d);
                        },
                      },
                    ],
                    inputs: ListOfInterest,
                  })
                }
              >
                Edit
              </p>
              <div className="ionPaddingTopSmall">
                <h3 className="ion-text-center">Goals</h3>
                <div className="ion-text-center">
                  {koroPublicAvatar.koroGoals ? (
                    <>
                      {koroPublicAvatar.koroGoals.map((goal) => (
                        <RenderGoalTag tag={goal} />
                      ))}
                    </>
                  ) : (
                    <p className="ion-text-center">No goal selected</p>
                  )}
                </div>

                <p
                  className="link ion-text-center"
                  onClick={() =>
                    presentAlert({
                      header: "Select Your Goals",
                      buttons: [
                        { text: "Cancel" },
                        {
                          text: "Ok",
                          handler: (d) => {
                            updateGoal(d);
                          },
                        },
                      ],
                      inputs: ListOfGoals,
                    })
                  }
                >
                  Edit
                </p>
              </div>
            </div>
            <div className="ionPaddingTopSmall">
              <p className="ion-text-center">Bodee Contributor Score</p>
              <h3 className="ion-text-center">{koroPublicAvatar.koroScore}</h3>
            </div>
            {/* <div className="ionPaddingTopSmall">
                <h3 className="ion-text-center">Post</h3>
                {questionsData.map((question, index) => {
                  return <QuestionCard question={question} refresh={refresh} />;
                })}
              </div> */}
          </div>
          {/* {renderlistItems(routes.appPages)} */}
          <Activity />
          <MyQuestions />
        </IonContent>
      </IonModal>
    </>
  );
};

export default Template;
