import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonPage,
  IonRow,
} from "@ionic/react";
import { checkbox } from "ionicons/icons";
//import spinner
import React, { useContext, useEffect } from "react";
import { UserContext } from "../../lib/context";
import "./HRAStyle.css";

type Props = {
  slides;
  buttonHandler;
  profileHandler;
  profile;
  slideIndex;
};

const HealthStatsAbout: React.FC<Props> = ({
  slides,
  buttonHandler,
  profileHandler,
  profile,
  slideIndex,
}) => {

  useEffect(() => {
    if(slideIndex === 2){
      buttonHandler(false);
    }
  }, [slideIndex]);

  return (
    <>
      <IonCardTitle class="ion-text-start slideTitle">
        We'd like to learn a little <br></br>more about you,{" "}
        {profile ? profile.firstName : null}
      </IonCardTitle>

      <IonGrid>
        <IonRow>
          <IonCol>
            <IonCard>
              <IonCardHeader class="ion-text-start" title="Hi">
                <IonCardTitle className="aboutCardTitle">
                  In this section we'll:
                </IonCardTitle>
              </IonCardHeader>
              <IonCardContent>
                <IonItem lines="none">
                  <IonIcon
                    icon={checkbox}
                    size="small"
                    color="success"
                    slot="start"
                  ></IonIcon>
                  <p>Collect basic health information</p>
                </IonItem>
                <IonItem lines="none">
                  <IonIcon
                    icon={checkbox}
                    size="small"
                    color="success"
                    slot="start"
                  ></IonIcon>
                  <p>Learn more about your lifestyle habits</p>
                </IonItem>
                <IonItem lines="none">
                  <IonIcon
                    icon={checkbox}
                    size="small"
                    color="success"
                    slot="start"
                  ></IonIcon>
                  <p>Ask about chronic conditions</p>
                </IonItem>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default HealthStatsAbout;
