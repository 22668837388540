import { Redirect, Route } from "react-router-dom";
import { IonHeader, IonRouterOutlet, IonSplitPane } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import ProfileCreate from "./ProfileCreate";
import NotAllowed from "./NotAllowed";

import MainTabs from "./MainTabs";
import UpdateProfileData from "../../../components/Account/Settings/Profile/UpdateProfileData";
import ChangeHealthData from "../../../components/Account/Settings/Profile/ChangeHealthData";
import ChangeEmail from "../../../components/Account/Settings/Profile/ChangeEmail";
import ChangePassword from "../../../components/Account/Settings/Profile/ChangePassword";
import ChangeName from "../../../components/Account/Settings/Profile/ChangeName";
import Menu from "../../../components/Menu";
import ScanList from "../../../components/Tools/DFX/ScanList";
import DeepAffex from "../../../components/Tools/DFX/DeepAffex";
import GuestScan from "../../../components/Tools/DFX/GuestScan";
import Scan from "../../../components/Tools/DFX/Scan";

import All from "../../../components/Explore/ExploreAll";
import Nutrition from "../../../components/Explore/ExploreNutrition";
import Fitness from "../../../components/Explore/ExploreFitness";
import MentalHealth from "../../../components/Explore/ExploreMentalHealth";
import Rehabilitation from "../../../components/Explore/ExploreRehabilitation";
import Addiction from "../../../components/Explore/ExploreAddiction";

import Session from "../../../components/Appointment/Session";
import MyCoach from "../../../components/Tools/MyCoach";
import UpdateAddress from "../../../components/Account/Settings/Profile/ChangeAddress";

import ChangePhone from "../../../components/Account/Settings/Profile/ChangePhone";
import Preferences from "../../../components/Account/Preferences/Preferences";

import ManageSub from "../../../components/Account/Settings/ManageSub";

import { UserContext } from "../../../lib/context";
import { useContext, useEffect, useState } from "react";
import Telemedicine from "../../../components/Tools/TeleMedicine";

import { VisitTypesList } from "../../../Interfaces/Appointment/VisitType";
import Settings from "../../../components/Account/Settings/Settings";

import Meeting from "../../../components/Appointment/Meeting";
import { PushNotifications } from "@capacitor/push-notifications";
import Community from "./Community";
import AppUrlListener from "../../../lib/AppUrlListener";

const PrivateRouter = () => {
  //user user context
  const { user, profile } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      PushNotifications.removeAllDeliveredNotifications().catch((err) => {
        console.log("err", err);
      });
    }, 3000);
  }, []);

  return (
    <>
      <IonReactRouter>
        {profile.role === "member" ? (
          <>
            {profile && profile.accountSetupComplete ? (
              <>
                <IonReactRouter>
      <AppUrlListener></AppUrlListener>

                  <IonSplitPane contentId="main">
                    <Menu />
                 
                    <IonRouterOutlet id="main">
                      <Route path="/tabs" render={() => <MainTabs />} />
                      <Route
                        path="/change-password"
                        component={ChangePassword}
                      />
                      <Route
                        path="/update-profile"
                        component={UpdateProfileData}
                      />
                      <Route
                        path="/change-health-data"
                        component={ChangeHealthData}
                      />
                      <Route path="/change-email" component={ChangeEmail} />
                      <Route path="/change-name" component={ChangeName} />
                      <Route path="/change-address" component={UpdateAddress} />

                      <Route path="/scan-list" component={ScanList} />
                      <Route path="/diagnostics" component={DeepAffex} />
                      <Route path="/guest-scan" component={GuestScan} />
                      <Route path="/telemedicine" component={Telemedicine} />

                      <Route path="/scan/:id" component={Scan} />
                      <Route path="/all" component={All} />
                      <Route path="/nutrition" component={Nutrition} />
                      <Route path="/fitness" component={Fitness} />
                      <Route path="/mental-health" component={MentalHealth} />
                      <Route
                        path="/rehabilitation"
                        component={Rehabilitation}
                      />
                      <Route path="/addiction" component={Addiction} />
                      <Route path="/session/:id" component={Session} />
                      <Route path="/my-coach" component={MyCoach} />
                      <Route path="/change-phone" component={ChangePhone} />
                      <Route path="/preferences" component={Preferences} />
                      <Route path="/settings" component={Settings} />
                      <Route path="/community" component={Community} />

                      <Route path="/meeting/:id" component={Meeting} />
                      <Route
                        path="/manage-subscription"
                        component={ManageSub}
                      />

                      <Redirect to="/tabs" />
                    </IonRouterOutlet>
                  </IonSplitPane>
                </IonReactRouter>
              </>
            ) : (
              <>{loading ? null : <ProfileCreate />}</>
            )}
          </>
        ) : (
          <NotAllowed />
        )}
      </IonReactRouter>
    </>
  );
};

export default PrivateRouter;
