export const actionMessageStyle = () => {

    return {
        padding: "8px 16px",
        marginBottom: "16px",
        textAlign: "center",
        height: "36px"
    }
}

export const actionMessageTxtStyle = () => {

    return {
        fontSize: "13.5px",
        margin: "0",
        lineHeight: "20px",
    }
}
