import {
  IonModal,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonImg,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonHeader,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { camera } from "ionicons/icons";
import React from "react";
import { useRef } from "react";
import { usePhotoGallery } from "../../lib/usePhotoGallery";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  addDoc,
  collection,
  doc,
  getFirestore,
  updateDoc,
} from "firebase/firestore";

export default function LogMeal(props) {
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [mealType, setMealType] = React.useState<string>("");
  const [mealName, setMealName] = React.useState<string>("");
  const [mealCalories, setMealCalories] = React.useState<string>("");

  const { photos, takePhoto, clearPhoto } = usePhotoGallery();

  var getFileBlob = function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function () {
      cb(xhr.response);
    });
    xhr.send();
  };

  function confirm() {
    if (!mealName) {
      alert("Please enter a meal name");
      return;
    }

    modal.current?.dismiss(input.current?.value, "confirm");
  }

  async function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {

    const dateArray = props.date.split("-");
    const timestamp = new Date( parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]));

    if (ev.detail.role === "confirm") {
      updateDoc(doc(getFirestore(), "tracking", props.user.uid), {
        lastMealLog: new Date(),
        email: props.user.email,
        uid: props.user.uid,
      });
      await addDoc(collection(getFirestore(), "meals"), {
        mealPhoto: "no-photo",
        mealType: mealType,
        mealName: mealName,
        mealCalories: Number(mealCalories),
        date: props.date,
        timestamp: timestamp,
        uid: props.user.uid,
        email: props.user.email,
      })
        .then((document) => {
          if (photos.length > 0) {
            getFileBlob(photos[0].webviewPath, (blob) => {
              const storage = getStorage();
              const profilePhotoRef = ref(
                storage,
                "meals/" + photos[0].filepath
              );
              uploadBytes(profilePhotoRef, blob)
                .then((snapshot) => {
                  getDownloadURL(profilePhotoRef).then(async (url) => {
                    const ref = doc(getFirestore(), "meals", document.id);
                    await updateDoc(ref, {
                      mealPhoto: url,
                    })
                  });
                })
                .catch((error) => {
                  console.log(", error: " + error);
                });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      clearPhoto();
      setMealCalories("");
      setMealName("");
      setMealType("");
    }
  }

  const validateMealType = () => {
    var mealTypeHelper = document.getElementById("mealTypeHelp");
    if (mealType === undefined) {
      mealTypeHelper.innerHTML = "Please select a meal type";
      return false;
    } else {
      mealTypeHelper.innerHTML = "";
      return true;
    }
  };

  const validateMealName = () => {
    var mealNameHelper = document.getElementById("mealNameHelp");
    if (mealName === undefined) {
      mealNameHelper.innerHTML = "Please enter a meal name";
      return false;
    } else {
      mealNameHelper.innerHTML = "";
      return true;
    }
  };

  const validateMealCalories = () => {
    var mealCaloriesHelper = document.getElementById("mealCaloriesHelp");
    if (mealCalories === undefined) {
      mealCaloriesHelper.innerHTML = "Please enter meal calories";
      return false;
    } else {
      mealCaloriesHelper.innerHTML = "";
      return true;
    }
  };

  const validatePhoto = () => {
    var photoHelper = document.getElementById("photoHelp");

    if (photos.length > 0) {
      photoHelper.innerHTML = "";
      return true;
    } else {
      photoHelper.innerHTML = "Please take or select a photo";
      return false;
    }
  };

  return (
    <>
      <IonModal
        ref={modal}
        trigger="open-log-meal2"
        onWillDismiss={(ev) => onWillDismiss(ev)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle className="mainPageTitle">Log Meal</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          {photos.length > 0 ? null : (
            <IonFab
              className="ion-padding-bottom"
              vertical="bottom"
              horizontal="center"
              slot="fixed"
            >
              <IonFabButton onClick={() => takePhoto()}>
                <IonIcon size="large" icon={camera}></IonIcon>
              </IonFabButton>
            </IonFab>
          )}

          {photos.length > 0 ? null : (
            <div className="center" onClick={() => takePhoto()}>
              <img
                src="https://static.thenounproject.com/png/485692-200.png"
                alt="cat"
              ></img>
            </div>
          )}

          {photos.map((photo, index) => (
            <>
              <IonImg key={index} src={photo.webviewPath} />
            </>
          ))}
          <p className="formHelp" id="photoHelp"></p>

          <IonItem>
            <IonLabel>Which Meal</IonLabel>
            <IonSelect
              onIonChange={(e) => setMealType(e.detail.value)}
              onIonDismiss={validateMealType}
            >
              <IonSelectOption value="Breakfast">Breakfast</IonSelectOption>
              <IonSelectOption value="Lunch">Lunch</IonSelectOption>
              <IonSelectOption value="Dinner">Dinner</IonSelectOption>
              <IonSelectOption value="Snack">Snack</IonSelectOption>
            </IonSelect>
            <p className="formHelp" id="mealTypeHelp"></p>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">What did you eat?</IonLabel>
            <IonInput
              type="text"
              placeholder="Name"
              autoCapitalize="on"
              autoCorrect="on"
              onIonChange={(e) => setMealName(e.detail.value)}
              onIonBlur={validateMealName}
            ></IonInput>
            <p className="formHelp" id="mealNameHelp"></p>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Calories</IonLabel>
            <IonInput
              type="number"
              placeholder="Calories"
              autocorrect="on"
              autoCapitalize="on"
              onIonChange={(e) => setMealCalories(e.detail.value)}
              onIonBlur={validateMealCalories}
            ></IonInput>
            <p className="formHelp" id="mealCaloriesHelp"></p>
          </IonItem>

          <IonButton
            className="ion-padding"
            expand="block"
            onClick={() => confirm()}
          >
            Log
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
}
