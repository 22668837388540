import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLabel,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./index.css";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import MessageBox from "../MessageBox";
import QuestionCard from "../../QuestionCard";
import { UserContext } from "../../../../../lib/context";

type Props = {
  item;
  refresh;
};

const ItemTile: React.FC<Props> = ({ item, refresh }) => {
  const [itemOpen, setItemOpen] = useState<boolean>(false);

  const { user, koroPublicAvatar } = useContext(UserContext);

  const [questionsData, setQuestionsData] = useState([]);

  const GetQuestionNumber = ({ item }) => {
    const [questionNumber, setQuestionNumber] = useState<number>();

    const getQuestionNumber = async () => {
      const docRef = doc(getFirestore(), "koroTags", item.id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setQuestionNumber(docSnap.data().questions);
      } else {
        setQuestionNumber(0);
      }
      // setQuestionNumber();
    };

    useEffect(() => {
      getQuestionNumber();
    }, [refresh]);

    return <IonLabel><p>{questionNumber} questions</p></IonLabel>;
  };

  const getQuestions = async () => {
    console.log("getting questions");
    var questions = [];

    var q = query(
      collection(getFirestore(), "koroQuestions"),
      where("hidden", "==", false),
      where("tags", "array-contains", item.id)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      questions.push({ id: doc.id, ...doc.data() });
    });

    // sort questions by timestamp.seconds in ascending order
    questions.sort((a, b) => {
      return b.dateCreated.seconds - a.dateCreated.seconds;
    });

    console.log("questions", questions);

    setQuestionsData(questions);
  };

  useEffect(() => {
    if (itemOpen == true) {
      getQuestions();
    }
  }, [itemOpen]);

  function handleItemClick(title: any) {
    // update field title in koroPublicAvatar by 1
    updateDoc(doc(getFirestore(), "koroPublicAvatars", user.uid), {
      [title]: (koroPublicAvatar[title] || 0) + 1,
    });
  }

  return (
    <>
      <div
        className="koroInterestTile"
        onClick={() => {
          setItemOpen(true);
          handleItemClick(item.id);
        }}
      >
        <IonGrid className="tileGrid">
          <IonRow>
            <IonCol>
              <IonAvatar className="koroInterestTileImg">
                <img src={item.img} loading="lazy" />
              </IonAvatar>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel>
                <h3>{item.title}</h3>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonLabel>
                <GetQuestionNumber item={item} />
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
      <IonModal
        isOpen={itemOpen}
        onDidDismiss={() => setItemOpen(false)}
      >
        <IonHeader>
          <IonToolbar>
            <IonTitle>{item.title}</IonTitle>
            <IonButtons slot="start">
              <IonButton onClick={() => setItemOpen(false)}>
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="backgroundColor ion-padding-bottom">
          <h3 className="ion-margin">Message Board</h3>
          <MessageBox />
          {questionsData.length === 0 && (
            <IonLabel className="ion-text-center">
              <p className="ion-margin-start ion-padding-start ion-text-center">
                No questions found
              </p>
            </IonLabel>
          )}
          {questionsData.map((question, index) => {
            console.log("question");
            return (
              <QuestionCard
                question={question}
                refresh={refresh}
                key={question.id}
                refreshHandler={undefined}
              />
            );
          })}
        </IonContent>
      </IonModal>
    </>
  );
};

export default ItemTile;
