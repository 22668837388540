/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
//import spinner
import React, { useEffect, useState } from "react";
import "./HRAStyle.css";
import "firebase/compat/auth";
import { formatToIonicDate } from "../../lib/helper";
import { Browser } from "@capacitor/browser";
import { femaleOutline, maleOutline } from "ionicons/icons";

type Props = {
  slides;
  buttonHandler;
  profileHandler;
  profile;
  slideIndex;
};

const AddAppearanceData: React.FC<Props> = ({
  slides,
  buttonHandler,
  profileHandler,
  profile,
  slideIndex,
}) => {
  const [weight, setWeight] = useState(profile ? profile.weight : null);
  const [heightFt, setHeightFt] = useState(profile ? profile.heightFt : null);
  const [heightIn, setHeightIn] = useState(profile ? profile.heightIn : null);
  const [gender, setGender] = useState(profile ? profile.gender : "");

  const validateFeet = () => {
    var feetHelper = document.getElementById("feetHelp");

    if (heightFt === undefined) {
      feetHelper.innerHTML = "Feet is required";
      return false;
    } else if (heightFt < 0) {
      feetHelper.innerHTML = "Feet must be greater than 0";
      return false;
    }

    //check if weight is a number
    else if (isNaN(heightFt)) {
      feetHelper.innerHTML = "Feet must be a number";
      return false;
    } else {
      feetHelper.innerHTML = "";
      return true;
    }
  };

  const validateInches = () => {
    var inchHelper = document.getElementById("inchHelp");

    if (heightIn === undefined) {
      inchHelper.innerHTML = "Inches are required";
      return false;
    } else if (heightIn < 0) {
      inchHelper.innerHTML = "Inches must be greater than 0";
      return false;
    }

    //check if inches are between 0 and 12
    else if (heightIn > 12) {
      inchHelper.innerHTML = "Inches must be between 0 and 12";
      return false;
    }

    //check if weight is a number
    else if (isNaN(heightIn)) {
      inchHelper.innerHTML = "Inches must be a number";
      return false;
    } else {
      inchHelper.innerHTML = "";
      return true;
    }
  };

  const validateWeight = () => {
    var weightHelper = document.getElementById("weightHelp");

    if (weight === undefined) {
      weightHelper.innerHTML = "Weight is required";
      return false;
    } else if (weight < 0) {
      weightHelper.innerHTML = "Weight must be greater than 0";
      return false;
    }

    //check if weight is a number
    else if (isNaN(weight)) {
      weightHelper.innerHTML = "Weight must be a number";
      return false;
    } else {
      weightHelper.innerHTML = "";
      return true;
    }
  };

  const validateValues = () => {
    if (
      validateFeet() &&
      validateInches() &&
      validateWeight() &&
      validateGender()
    ) {
      buttonHandler(false);
    } else {
      buttonHandler(true);
    }
  };

  const validateGender = () => {
    if (gender === "" || gender === undefined) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (slideIndex === 3) {
      validateValues();
    }
  }, [heightFt, heightIn, slideIndex, weight, gender]);

  return (
    <>
      <IonCardTitle class="ion-text-start slideTitle">
        Let's create your profile, {profile ? profile.firstName : null}
      </IonCardTitle>
      <p className="ion-text-start subTitle">
        This info helps use understand you, mind and body
      </p>
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Height (ft)</IonLabel>
              <IonInput
                type="number"
                value={heightFt}
                onIonChange={(e: any) => {
                  setHeightFt(e.detail.value);
                  profile.heightFt = Number(e.detail.value);
                  profileHandler(profile);
                }}
                onIonBlur={(e: any) => {
                  validateFeet();
                }}
              />
              <p className="formHelp" id="feetHelp"></p>
            </IonItem>
          </IonCol>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Height (in)</IonLabel>
              <IonInput
                type="number"
                value={heightIn}
                onIonChange={(e: any) => {
                  setHeightIn(e.detail.value);
                  profile.heightIn = Number(e.detail.value);
                  profileHandler(profile);
                }}
                onIonBlur={(e: any) => {
                  validateInches();
                }}
              />
              <p className="formHelp" id="inchHelp"></p>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel position="floating">Weight (lbs)</IonLabel>
              <IonInput
                type="number"
                value={weight}
                onIonChange={(e: any) => {
                  setWeight(e.detail.value);
                  profile.weight = Number(e.detail.value);
                  profileHandler(profile);
                }}
                onIonBlur={(e: any) => {
                  validateWeight();
                }}
              />
              <p className="formHelp" id="weightHelp"></p>
            </IonItem>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <IonSegment
              value={gender}
              onIonChange={(e) => {
                setGender(e.detail.value);
                profile.gender = e.detail.value;
                profileHandler(profile);
              }}
            >
              <IonSegmentButton value="female">
                <IonIcon icon={femaleOutline} />
                <IonLabel>Female</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="male">
                <IonIcon icon={maleOutline} />
                <IonLabel>Male</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

export default AddAppearanceData;

// import {
//   IonButton,
//   IonCardTitle,
//   IonCol,
//   IonContent,
//   IonGrid,
//   IonIcon,
//   IonInput,
//   IonItem,
//   IonLabel,
//   IonPage,
//   IonRow,
//   IonSegment,
//   IonSegmentButton,
// } from "@ionic/react";
// import { doc, getFirestore, updateDoc } from "firebase/firestore";
// import { femaleOutline, maleOutline } from "ionicons/icons";
// //import spinner
// import React, { useContext, useState } from "react";
// import { UserContext } from "../../lib/context";
// import "./HRAStyle.css";

// const AddAppearanceData = ({ slides }) => {
//   const { profile, user } = useContext(UserContext);
// const [weight, setWeight] = useState(profile ? profile.weight : "");
// const [heightFt, setHeightFt] = useState(profile ? profile.heightFt : "");
// const [heightIn, setHeightIn] = useState(profile ? profile.heightIn : "");
// const [gender, setGender] = useState(profile ? profile.gender : "");
// const [validHeightFt, setValidHeightFt] = useState(false);
// const [validHeightIn, setValidHeightIn] = useState(false);
// const [validWeight, setValidWeight] = useState(false);
// const [validGender, setValidGender] = useState(false);

//   // get slide ref
//   const changeDirection = async (direction: string) => {
//     const swiper = await slides.current.getSwiper();
//     if (direction === "next") {
//       swiper.slideNext();
//     } else if (direction === "prev") {
//       swiper.slidePrev();
//     }
//   };

// const validateWeight = () => {
//   var weightHelper = document.getElementById("weightHelp");

//   if (weight === undefined) {
//     weightHelper.innerHTML = "Weight is required";
//     setValidWeight(false);
//     return false;
//   } else if (weight < 0) {
//     weightHelper.innerHTML = "Weight must be greater than 0";
//     setValidWeight(false);
//     return false;
//   }

//   //check if weight is a number
//   else if (isNaN(weight)) {
//     weightHelper.innerHTML = "Weight must be a number";
//     setValidWeight(false);
//     return false;
//   } else {
//     weightHelper.innerHTML = "";
//     setValidWeight(true);
//     return true;
//   }
// };

// const validateFeet = () => {
//   var feetHelper = document.getElementById("feetHelp");

//   if (heightFt === undefined) {
//     feetHelper.innerHTML = "Feet is required";
//     setValidHeightFt(false);
//     return false;
//   } else if (heightFt < 0) {
//     feetHelper.innerHTML = "Feet must be greater than 0";
//     setValidHeightFt(false);
//     return false;
//   }

//   //check if weight is a number
//   else if (isNaN(heightFt)) {
//     feetHelper.innerHTML = "Feet must be a number";
//     setValidHeightFt(false);
//     return false;
//   } else {
//     feetHelper.innerHTML = "";
//     setValidHeightFt(true);
//     return true;
//   }
// };

// const validateInches = () => {
//   var inchHelper = document.getElementById("inchHelp");

//   if (heightIn === undefined) {
//     inchHelper.innerHTML = "Inches are required";
//     setValidHeightIn(false);
//     return false;
//   } else if (heightIn < 0) {
//     inchHelper.innerHTML = "Inches must be greater than 0";
//     setValidHeightIn(false);
//     return false;
//   }

//   //check if inches are between 0 and 12
//   else if (heightIn > 12) {
//     inchHelper.innerHTML = "Inches must be between 0 and 12";
//     setValidHeightIn(false);
//     return false;
//   }

//   //check if weight is a number
//   else if (isNaN(heightIn)) {
//     inchHelper.innerHTML = "Inches must be a number";
//     setValidHeightIn(false);
//     return false;
//   } else {
//     inchHelper.innerHTML = "";
//     setValidHeightIn(true);
//     return true;
//   }
// };

//   const validateGender = () => {
//     var genderHelp = document.getElementById("genderHelp");

//     if (gender === undefined) {
//       genderHelp.innerHTML = "Gender is required";
//       setValidGender(false);
//       return false;
//     } else {
//       genderHelp.innerHTML = "";
//       setValidGender(true);
//       return true;
//     }
//   };

//   const addHealthData = async () => {
//     if (validGender && validHeightFt && validHeightIn && validWeight) {
      // const ref = doc(getFirestore(), "users", user.uid);
      // await updateDoc(ref, {
      //   weight: Number(weight),
      //   heightIn: Number(heightIn),
      //   heightFt: Number(heightFt),
      //   gender: gender,
      // });
//     }
//   };

//   return (
//     <IonPage>
//       <IonContent fullscreen>
// <IonCardTitle class="ion-text-start slideTitle">
//   Let's create your profile, {profile ? profile.firstName : null}
// </IonCardTitle>
// <p className="ion-text-start subTitle">
//   This info helps use understand you, mind and body
// </p>

// <IonGrid>
//   <IonRow>
//     <IonCol>
//       <IonItem>
//         <IonLabel position="floating">Height (ft)</IonLabel>
//         <IonInput
//           type="number"
//           value={heightFt}
//           onIonChange={(e: any) => {
//             setHeightFt(e.detail.value);
//           }}
//           onIonBlur={(e: any) => {
//             validateFeet();
//           }}
//         />
//         <p className="formHelp" id="feetHelp"></p>
//       </IonItem>
//     </IonCol>
//     <IonCol>
//       <IonItem>
//         <IonLabel position="floating">Height (in)</IonLabel>
//         <IonInput
//           type="number"
//           value={heightIn}
//           onIonChange={(e: any) => {
//             setHeightIn(e.detail.value);
//           }}
//           onIonBlur={(e: any) => {
//             validateInches();
//           }}
//         />
//         <p className="formHelp" id="inchHelp"></p>
//       </IonItem>
//     </IonCol>
//   </IonRow>
//   <IonRow>
//     <IonCol>
//       <IonItem>
//         <IonLabel position="floating">Weight</IonLabel>
//         <IonInput
//           type="number"
//           value={weight}
//           onIonChange={(e: any) => {
//             setWeight(e.detail.value);
//           }}
//           onIonBlur={(e: any) => {
//             validateWeight();
//           }}
//         />
//         <p className="formHelp" id="weightHelp"></p>
//       </IonItem>
//     </IonCol>
//   </IonRow>
//   <IonRow>
//     <IonCol>
//       <IonSegment
//         onIonChange={(e) => {
//           setGender(e.detail.value);
//           setValidGender(true);
//         }}
//       >
//         <IonSegmentButton value="female">
//           <IonIcon icon={femaleOutline} />
//           <IonLabel>Female</IonLabel>
//         </IonSegmentButton>
//         <IonSegmentButton value="male">
//           <IonIcon icon={maleOutline} />
//           <IonLabel>Male</IonLabel>
//         </IonSegmentButton>
//       </IonSegment>
//     </IonCol>
//   </IonRow>
// </IonGrid>
//         <IonButton
//           onClick={() => {
//             changeDirection("next");
//             addHealthData();
//           }}
//           className="appearanceButton"
//           disabled={
//             !validHeightFt || !validHeightIn || !validWeight || !validGender
//           }
//           expand="block"
//         >
//           CONTINUE
//         </IonButton>
//       </IonContent>
//     </IonPage>
//   );
// };

// export default AddAppearanceData;
