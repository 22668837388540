import { Redirect, Route } from "react-router-dom";
import { IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import ProfileCreate from "./ProfileCreate";
// import NotAllowed from "./NotAllowed";

// import MainTabs from "./MainTabs";
import UpdateProfileData from "../../../components/Account/Settings/Profile/UpdateProfileData";
import ChangeEmail from "../../../components/Account/Settings/Profile/ChangeEmail";
import ChangePassword from "../../../components/Account/Settings/Profile/ChangePassword";
import Menu from "../../../components/Menu";
import ScanList from "../../../components/Tools/DFX/ScanList";
import DeepAffex from "../../../components/Tools/DFX/DeepAffex";
import GuestScan from "../../../components/Tools/DFX/GuestScan";
import Scan from "../../../components/Tools/DFX/Scan";
// import ExploreHealthyEating from "../../components/Explore/ExploreHealthyEating";
// import ExploreFitnessAndActivity from "../../components/Explore/ExploreFitnessAndActivity";
// import LivingWell from "../../components/Explore/ExploreLivingWell";
// import PracticingMindfulness from "../../components/Explore/ExplorePracticingMindfulness";
// import HealingAndPreventingInjuries from "../../components/Explore/ExploreHealingAndPreventingInjuries";
// import OvercomingDisabalities from "../../components/Explore/ExploreOvercomingDisabilities";
// import RecoverFromAddiction from "../../components/Explore/ExploreRecoverFromAddiction";
// import AboutUs from "../../components/Explore/ExploreAboutUs";
// import ManageStressAndAnxiety from "../../components/Explore/ExploreManageStressAndAnxiety";
// import Session from "../../components/Appointment/Session";
// import MyCoach from "../../components/Tools/MyCoach";
import UpdateAddress from "../../../components/Account/Settings/Profile/ChangeAddress";

import ChangePhone from "../../../components/Account/Settings/Profile/ChangePhone";

import { UserContext } from "../../../lib/context";
import { useContext, useEffect, useState } from "react";
import NotAllowed from "../NotAllowed";
//import Telemedicine from "../../components/Tools/TeleMedicine";

const TelemedicineOnlyRouter = () => {
  //user user context
  const { user, profile } = useContext(UserContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {profile.role === "member" ? (
        <>
          {" "}
          <IonReactRouter>
            {profile && profile.accountSetupComplete ? (
              <>
                <Menu />
                <IonRouterOutlet>
                  <Route path="/change-password" component={ChangePassword} />
                  <Route path="/update-profile" component={UpdateProfileData} />
                  <Route path="/change-email" component={ChangeEmail} />
                  <Route path="/scan-list" component={ScanList} />
                  <Route path="/diagnostics" component={DeepAffex} />
                  <Route path="/guest-scan" component={GuestScan} />

                  <Route path="/scan/:id" component={Scan} />
                  {/* <Route
                  path="/healthy-eating"
                  component={ExploreHealthyEating}
                />
                <Route
                  path="/fitness-and-activity"
                  component={ExploreFitnessAndActivity}
                />
                <Route path="/living-well" component={LivingWell} />
                <Route
                  path="/practicing-mindfulness"
                  component={PracticingMindfulness}
                />
                <Route
                  path="/healing-and-preventing-injuries"
                  component={HealingAndPreventingInjuries}
                />
                <Route
                  path="/overcoming-disabilities"
                  component={OvercomingDisabalities}
                />
                <Route
                  path="/recover-from-addiction"
                  component={RecoverFromAddiction}
                />
                <Route path="/about-us" component={AboutUs} />
                <Route
                  path="/manage-stress-and-anxiety"
                  component={ManageStressAndAnxiety}
                />
                <Route path="/session/:id" component={Session} />
                <Route path="/my-coach" component={MyCoach} /> */}
                  <Route path="/update-address" component={UpdateAddress} />
                  <Route path="/change-phone" component={ChangePhone} />

                  <Redirect to="/diagnostics" />
                </IonRouterOutlet>
              </>
            ) : (
              <>{loading ? null : <ProfileCreate />}</>
            )}
          </IonReactRouter>
        </>
      ) : (
        <NotAllowed />
      )}
    </>
  );
};

export default TelemedicineOnlyRouter;
