import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonRow,
  IonAvatar,
  IonSegment,
  IonSegmentButton,
  IonCardContent,
  IonButtons,
  IonMenuButton,
  IonMenuToggle,
  IonRouterOutlet,
} from "@ionic/react";
import {
  chevronForward,
  home,
  informationCircle,
  personOutline,
} from "ionicons/icons";
import Loader from "../../../components/Loaders/LoaderTransparent";

import "./Appointment.css";

import { VisitTypesList } from "../../../Interfaces/Appointment/VisitType";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../lib/context";
import { Browser } from "@capacitor/browser";
import React from "react";
import {
  query,
  collection,
  getFirestore,
  where,
  getDocs,
} from "firebase/firestore";
import StartTimePretty from "../../../components/Appointment/StartTimePretty";
import { Route } from "react-router";

const Appointment: React.FC = () => {
  const { user, profile } = useContext(UserContext);
  const [loading, setLoading] = React.useState(false);
  const [section, setSection] = useState("schedule");
  const [appointment, setAppointment] = useState([]);

  const openBrowser = async (url) => {
    await Browser.open({ url: url });
  };

  function getTelemedicineUrlSSO() {
    setLoading(true);

    const data = { walmartHealthId: profile.walmartHealthId };

    const config = {
      method: "post",
      url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/WalmartHealth-GetDashboardSSO`,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        openBrowser(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
      });
  }

  const RenderSessionStartTimePretty = ({ startTime }) => {
    const sessionStartTime = new Date(startTime);
    const sessionStartTimeAndDatePretty = sessionStartTime.toLocaleString(
      "en-US",
      {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }
    );

    return <>{sessionStartTimeAndDatePretty}</>;
  };

  const RenderSessionsUpcomming = () => {
    let sessions = [];
    appointment.forEach((session, index) => {
      var date = new Date(session.startTime);
      var today = new Date();

      if (date > today && session.status != "cancelled") {
        sessions.push(
          <IonItem
            className="todo"
            lines="none"
            color="primary"
            routerLink={"/session/" + session.id}
          >
            {" "}
            <IonIcon icon={informationCircle} slot="end"></IonIcon>{" "}
            <IonLabel class="ion-text-center todo-text ion-text-wrap">
              {" "}
              <StartTimePretty startTime={session.startTime} />
            </IonLabel>{" "}
          </IonItem>
        );
      }
    });

    if (sessions.length == 0) {
      return <div>No Upcoming Appointment</div>;
    }

    return <>{sessions}</>;
  };

  const RenderSessionsPast = () => {
    if (appointment.length == 0) {
      return <div>No Past Appointment</div>;
    }

    let sessions = [];
    appointment.forEach((session, index) => {
      var date = new Date(session.startTime);
      var today = new Date();

      if (date < today && session.status != "cancelled") {
        sessions.push(
          <IonItem
            className="todo"
            lines="none"
            color="medium"
            routerLink={"/session/" + session.id}
          >
            {" "}
            <IonIcon icon={informationCircle} slot="end"></IonIcon>{" "}
            <IonLabel class="ion-text-center todo-text ion-text-wrap">
              {" "}
              <StartTimePretty startTime={session.startTime} />
            </IonLabel>{" "}
          </IonItem>
        );
      }
    });

    return <>{sessions}</>;
  };

  const fetchSessions = async () => {
    //get sessiosn from firebase
    const q = query(
      collection(getFirestore(), "sessions"),
      where("memberEmail", "==", user.email)
    );

    var sessions = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      sessions.push({ id: doc.id, ...doc.data() });
    });

    setAppointment(sessions);
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  const [view, setView] = useState("default");

  return (
    <>
      <IonRouterOutlet>
        {VisitTypesList.map((visitType) => (
          <Route path={visitType.url} component={visitType.component} />
        ))}
      </IonRouterOutlet>
      <IonPage>
        <IonHeader>
          <IonToolbar className="mainHeaderBackground">
            <IonButtons slot="start">
              <IonMenuToggle className="white">
                <IonMenuButton className="white" />
              </IonMenuToggle>
            </IonButtons>
            <IonTitle className="mainPageTitleTEst">Appointment</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="ion-text-center">
          <IonSegment
            value={section}
            onIonChange={(e) => setSection(e.detail.value)}
          >
            <IonSegmentButton value="schedule">
              <IonLabel>Schedule Appointment</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value="my-appointments">
              <IonLabel>My Appointments</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          {section === "schedule" ? (
            <>
              <p className="ion-padding-top visitTypeText">
                Choose your visit type
              </p>
              {loading && <Loader />}
              {VisitTypesList.map((visitType) => (
                <IonItem
                  className="visitType-item"
                  lines="none"
                  routerLink={visitType.isWalmartHealth ? null : visitType.url}
                  href={visitType.isWalmartHealth ? null : visitType.url}
                  detail={false}
                  routerDirection="none"
                  onClick={() => {
                    if (visitType.isWalmartHealth) {
                      //open walmart health
                      getTelemedicineUrlSSO();
                    }
                  }}
                >
                  <IonIcon
                    color="primary"
                    className="visitTypeIcon"
                    icon={visitType.mdIcon}
                    slot="start"
                  ></IonIcon>
                  <IonIcon
                    icon={chevronForward}
                    slot="end"
                    size="medium"
                    color="primary"
                  ></IonIcon>
                  <IonLabel
                    class="ion-text-wrap visitType-title"
                    color="primary"
                  >
                    {visitType.title}
                  </IonLabel>
                </IonItem>
              ))}
            </>
          ) : (
            <>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Upcoming</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <RenderSessionsUpcomming />
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Past</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <RenderSessionsPast />
                </IonCardContent>
              </IonCard>
            </>
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default Appointment;
// import {
//   IonCard,
//   IonCardContent,
//   IonCardHeader,
//   IonCardTitle,
//   IonContent,
//   IonHeader,
//   IonIcon,
//   IonItem,
//   IonLabel,
//   IonPage,
//   IonSegment,
//   IonSegmentButton,
//   IonTitle,
//   IonToolbar,
// } from "@ionic/react";
// import { AppTrackingTransparency } from "capacitor-ios-app-tracking";
// import {
//   query,
//   collection,
//   getFirestore,
//   where,
//   getDocs,
//   doc,
//   getDoc,
// } from "firebase/firestore";
// import { callOutline, informationCircle } from "ionicons/icons";
// import { useContext, useEffect, useState } from "react";
// //import spinner
// import { InlineWidget } from "react-calendly";
// import { UserContext } from "../../../lib/context";

// import "./Appointment.css";

// const Appointment: React.FC = () => {
//   const [appointment, setAppointment] = useState([]);
//   const [coach, setCoach] = useState<any>({});
//   const { user, profile } = useContext(UserContext);
//   const [section, setSection] = useState("my-coach");
//   const [trackingStatus, setTrackingStatus] = useState("");

// //get coach document from firestore
// const getCoach = async () => {
//   const docRef = doc(getFirestore(), "users", profile.coach);
//   const docSnap = await getDoc(docRef);

//   if (docSnap.exists()) {
//     setCoach(docSnap.data());
//   } else {
//     // doc.data() will be undefined in this case
//   }
// };

// useEffect(() => {
//   getCoach();
// }, []);

// const fetchSessions = async () => {
//   //get sessiosn from firebase
//   const q = query(
//     collection(getFirestore(), "sessions"),
//     where("memberEmail", "==", user.email)
//   );

//   var sessions = [];

//   const querySnapshot = await getDocs(q);
//   querySnapshot.forEach((doc) => {
//     sessions.push({ id: doc.id, ...doc.data() });
//   });

//   setAppointment(sessions);
// };

// useEffect(() => {
//   fetchSessions();
// }, []);

//   const openSession = (sessionLink) => {
//     window.open(sessionLink, "_blank");
//   };
//   const openSessionDetails = (sessionID) => {
//     window.open("/session/" + sessionID, "_self");
//   };

// useEffect(() => {
//   AppTrackingTransparency.getTrackingStatus().then((status) => {
//     setTrackingStatus(status["status"]);
//   });
// }, []);

// const RenderSessionsUpcomming = () => {
//   let sessions = [];
//   appointment.forEach((session, index) => {
//     var date = new Date(session.startTime);
//     var today = new Date();

//     if (date > today && session.status != "cancelled") {
//       sessions.push(
//         <IonItem
//           className="todo"
//           lines="none"
//           color="primary"
//           routerLink={"/session/" + session.id}
//         >
//           {" "}

//           <IonIcon icon={informationCircle} slot="end"></IonIcon>{" "}
//           <IonLabel class="ion-text-center todo-text ion-text-wrap">
//             {" "}
//             {session.startTimePretty}
//           </IonLabel>{" "}
//         </IonItem>
//       );
//     }
//   });

//   if (sessions.length == 0) {
//     return <div>No Upcomming Appointment</div>;
//   }

//   return <>{sessions}</>;
// };

// const RenderSessionsPast = () => {
//   if (appointment.length == 0) {
//     return <div>No Past Appointment</div>;
//   }

//   let sessions = [];
//   appointment.forEach((session, index) => {
//     var date = new Date(session.startTime);
//     var today = new Date();

//     if (date < today && session.status != "cancelled") {
//       sessions.push(
//         <IonItem
//           className="todo"
//           lines="none"
//           color="medium"
//           routerLink={"/session/" + session.id}
//         >
//           {" "}
//           <IonIcon icon={informationCircle} slot="end"></IonIcon>{" "}
//           <IonLabel class="ion-text-center todo-text ion-text-wrap">
//             {" "}
//             {session.startTimePretty}
//           </IonLabel>{" "}
//         </IonItem>
//       );
//     }
//   });

//   return <>{sessions}</>;
// };

//   return (
// <IonPage>
//   <IonHeader>
//     <IonToolbar>
//       <IonTitle className="mainPageTitle">Appointment</IonTitle>
//     </IonToolbar>
//   </IonHeader>
//   <IonContent fullscreen>
//     {trackingStatus === "authorized" || trackingStatus === "" ? (
//       <>
// <IonSegment
//   value={section}
//   onIonChange={(e) => setSection(e.detail.value)}
// >
//   <IonSegmentButton value="my-coach">
//     <IonLabel>My Coach</IonLabel>
//   </IonSegmentButton>
//   <IonSegmentButton value="general">
//     <IonLabel>General</IonLabel>
//   </IonSegmentButton>
//   <IonSegmentButton value="past">
//     <IonLabel>My Appointments</IonLabel>
//   </IonSegmentButton>
// </IonSegment>
//         {section === "my-coach" && (
//           <InlineWidget
//             url={
//               coach.calendlyLink
//                 ? coach.calendlyLink + `?hide_gdpr_banner=1&email=${user.email}&name=${profile.firstName}%20${profile.lastName}`
//                 : "hh"
//             }
//           />
//         )}
//         {section === "general" && (
//           <InlineWidget
//             url={
//               `https://calendly.com/aw-health-coaches?hide_gdpr_banner=1&email=${user.email}&name=${profile.firstName}%20${profile.lastName}`
//             }
//           />
//         )}
//         {section === "past" && (
//     <>
//       <IonCard>
//         <IonCardHeader>
//           <IonCardTitle>Upcoming</IonCardTitle>
//         </IonCardHeader>
//         <IonCardContent>
//           <RenderSessionsUpcomming />
//         </IonCardContent>
//       </IonCard>
//       <IonCard>
//         <IonCardHeader>
//           <IonCardTitle>Past</IonCardTitle>
//         </IonCardHeader>
//         <IonCardContent>
//           <RenderSessionsPast />
//         </IonCardContent>
//       </IonCard>
//     </>
//   )}
// </>
//     ) : (
//       <><p>Please allow for app tracking in settings to use this feature. Settings {'>>'} Alliedwellness {'>>'} Allow Tracking.</p></>
//     )}
//   </IonContent>
// </IonPage>
//   );
// };

// export default Appointment;
