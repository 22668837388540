import {
  IonItem,
  IonAvatar,
  IonLabel,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
  useIonAlert,
} from "@ionic/react";
import "./style.css";
import Avatar from "../Avatar";
import { useContext, useRef, useState } from "react";
import { UserContext } from "../../../../../lib/context";
import {
  arrowDown,
  arrowUndo,
  arrowUp,
  sparklesSharp,
  trashBin,
} from "ionicons/icons";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

type Props = {
  avatar;
  name;
  comment;
  time;
  expert;
  publicAvatarUid;
  replyHandler;
  id;
  answer;
  commentObject;
  allowReply;
};

const Comment: React.FC<Props> = ({
  avatar,
  name,
  comment,
  time,
  expert,
  publicAvatarUid,
  replyHandler,
  id,
  answer,
  commentObject,
  allowReply,
}) => {
  // const [reply, setReply] = useState("");
  const { koroPublicAvatar } = useContext(UserContext);

  const [presentAlert] = useIonAlert();

  const [commentData, setCommentData] = useState(commentObject);

  const [upvotesTracker, setUpvotesTracker] = useState(
    commentObject.upvotes || []
  );
  const [downvotesTracker, setDownvotesTracker] = useState(
    commentObject.downvotes || []
  );

  const upVote = async () => {
    var upvotesData = upvotesTracker;
    var downvotesData = downvotesTracker;

    if (upvotesTracker.includes(koroPublicAvatar.uid)) {
      const newUpvotesTracker = upvotesTracker.filter(
        (upvote) => upvote !== koroPublicAvatar.uid
      );
      setUpvotesTracker(newUpvotesTracker);
      upvotesData = newUpvotesTracker;
    } else {
      setUpvotesTracker([...upvotesTracker, koroPublicAvatar.uid]);
      upvotesData = [...upvotesTracker, koroPublicAvatar.uid];
      // remove from downvotesTracker if it exists
      if (downvotesTracker.includes(koroPublicAvatar.uid)) {
        const newDownvotesTracker = downvotesTracker.filter(
          (downvote) => downvote !== koroPublicAvatar.uid
        );
        setDownvotesTracker(newDownvotesTracker);
        downvotesData = newDownvotesTracker;
      }
    }

    const docRef = doc(getFirestore(), "koroComments", id);

    await updateDoc(docRef, {
      upvotes: upvotesData,
      downvotes: downvotesData,
    })
      .then(() => {
        console.log("Document successfully updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
      });
  };

  const downVote = async () => {
    var upvotesData = upvotesTracker;
    var downvotesData = downvotesTracker;

    if (downvotesTracker.includes(koroPublicAvatar.uid)) {
      const newDownvotesTracker = downvotesTracker.filter(
        (downvote) => downvote !== koroPublicAvatar.uid
      );
      setDownvotesTracker(newDownvotesTracker);
      downvotesData = newDownvotesTracker;
    } else {
      setDownvotesTracker([...downvotesTracker, koroPublicAvatar.uid]);
      downvotesData = [...downvotesTracker, koroPublicAvatar.uid];
      // remove from upvotesTracker if it exists
      if (upvotesTracker.includes(koroPublicAvatar.uid)) {
        const newUpvotesTracker = upvotesTracker.filter(
          (upvote) => upvote !== koroPublicAvatar.uid
        );
        setUpvotesTracker(newUpvotesTracker);
        upvotesData = newUpvotesTracker;
      }
    }

    const docRef = doc(getFirestore(), "koroComments", id);

    await updateDoc(docRef, {
      upvotes: upvotesData,
      downvotes: downvotesData,
    });
  };

  const deleteComment = async () => {
    const docRef = doc(getFirestore(), "koroComments", id);

    await updateDoc(docRef, {
      deleted: true,
    }).then(() => {
      console.log("Document successfully deleted!");
      setCommentData({ ...commentData, deleted: true });
    });
  }

  if (commentData.deleted === true) {
    return <></>;
  }

  return (
    <>
      <IonItem className="koroQuestionAnswerHeaderItem" lines="none">
        <IonGrid>
          <IonRow class="ion-align-items-start">
            <IonCol size="auto">
              {" "}
              <Avatar
                src={avatar}
                publicAvatarUid={publicAvatarUid}
                expert={expert}
              />
            </IonCol>
            <IonCol>
              <IonRow>
                <IonLabel className="ion-text-wrap">
                  <h2 className="ion-text-wrap">
                    {answer && (
                      <span className="bold">
                        <IonIcon icon={sparklesSharp} color="primary"></IonIcon>
                      </span>
                    )}{" "}
                    <span className="bold">{name}</span> ·{" "}
                    {expert && (
                      <>
                        <span className="expert">Expert</span> ·
                      </>
                    )}
                    <span className="koroQuestionAnswerTime">{" "}{time}</span> ·{" "}
                    <span> </span>
                  </h2>
                </IonLabel>
                <IonLabel>
                  {!expert && (
                    <>
                      <IonIcon
                        icon={arrowUp}
                        onClick={upVote}
                        color={
                          upvotesTracker.includes(koroPublicAvatar?.uid)
                            ? "primary"
                            : ""
                        }
                      />{" "}
                      <span
                        className={
                          upvotesTracker.includes(koroPublicAvatar?.uid)
                            ? "colorPrimary"
                            : ""
                        }
                      >
                        {upvotesTracker.length}
                      </span>{" "}
                      <IonIcon
                        icon={arrowDown}
                        onClick={downVote}
                        color={
                          downvotesTracker.includes(koroPublicAvatar?.uid)
                            ? "primary"
                            : ""
                        }
                      />{" "}
                      <span
                        className={
                          downvotesTracker.includes(koroPublicAvatar?.uid)
                            ? "colorPrimary"
                            : ""
                        }
                      >
                        {downvotesTracker.length}
                      </span>
                      <span className="ion-margin"></span>
                    </>
                  )}
                </IonLabel>
              </IonRow>
              <IonRow className="ion-align-items-center">
                <IonCol>
                  <IonLabel>
                    <p className="ion-text-wrap">{comment}</p>
                    {publicAvatarUid !== koroPublicAvatar.uid && allowReply && (
                      <span
                        className="ion-text-wrap replyLink"
                        onClick={() => {
                          replyHandler({
                            publicAvatarUid,
                            name,
                            parentCommentId: id,
                          });
                        }}
                      >
                        Reply
                      </span>
                    )}
                  </IonLabel>
                </IonCol>
                {publicAvatarUid === koroPublicAvatar.uid && (
                  <IonCol size="auto">
                    <IonIcon
                      icon={trashBin}
                      onClick={() =>
                        presentAlert({
                          header: "Delete Comment",
                          message:
                            "Are you sure you want to delete this comment?",
                          buttons: [
                            {
                              text: "Cancel",
                              role: "cancel",
                              handler: () => {
                                console.log("Cancel clicked");
                              },
                            },
                            {
                              text: "OK",
                              role: "confirm",
                              handler: () => {
                                deleteComment();
                              },
                            },
                          ],
                        })
                      }
                    />
                  </IonCol>
                )}
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
        {/* <IonGrid>
        <IonRow >
          <IonCol  className="boarderTesting" size="2">
            <Avatar
              src={avatar}
              publicAvatarUid={publicAvatarUid}
              expert={expert}
            />
          </IonCol>
          <IonCol className="boarderTesting">
            <IonRow>
              <IonCol>
                {" "}
                <IonLabel className="ion-text-wrap">
                  <h2 className="ion-text-wrap">
                    {answer && (
                      <span className="bold">
                        <IonIcon icon={sparklesSharp} color="primary"></IonIcon>
                      </span>
                    )}{" "}
                    <span className="bold">{name}</span> ·{" "}
                    {expert && (
                      <>
                        <span className="expert">Expert</span> ·
                      </>
                    )}
                    <span className="koroQuestionAnswerTime">{time}</span>
                  </h2>
                </IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonLabel>
                  <p className="ion-text-wrap">{comment}</p>
                  {publicAvatarUid !== koroPublicAvatar.uid && allowReply && (
                    <span
                      className="ion-text-wrap replyLink"
                      onClick={() => {
                        replyHandler({
                          publicAvatarUid,
                          name,
                          parentCommentId: id,
                        });
                      }}
                    >
                      Reply
                    </span>
                  )}
                </IonLabel>
              </IonCol>
            </IonRow>
          </IonCol>
        </IonRow>
        
      </IonGrid> */}
      </IonItem>
    </>
  );
};

export default Comment;
