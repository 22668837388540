import { notifications } from "ionicons/icons";
import "./style.css";
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import Avatar from "../../QuestionCard/Avatar";
import AvatarMini from "../../QuestionCard/AvatarMini";
import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { UserContext } from "../../../../../lib/context";
import { timeSince } from "../../../../../lib/Helpers/time";
import NotificationItem from "./NotificationItem";

type Props = {};

const NotificationTray: React.FC<Props> = () => {
  const { user } = useContext(UserContext);

  const [notificationTrayOpen, setNotificationTrayOpen] = useState(false);

  const [notificationsData, setNotificationsData] = useState([]) as any;

  const fetchNotifications = async () => {
    const q = query(
      collection(getFirestore(), "appNotifications"),
      where("uid", "==", user.uid),
      where("dismissed", "==", false),
      orderBy("timestamp", "desc"),
      limit(10)
    );

    const notificationsList = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      notificationsList.push({ id: doc.id, ...doc.data() });
    });

    // order them by timestamp
    notificationsList.sort((a, b) => {
      return b.timestamp - a.timestamp;
    }
    );

    console.log(notificationsList);
    setNotificationsData(notificationsList);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <>
      <IonIcon
        size="large"
        icon={notifications}
        className="notifIcon"
        onClick={() => setNotificationTrayOpen(true)}
      />
      <IonModal isOpen={notificationTrayOpen} onDidDismiss={() => setNotificationTrayOpen(false)}>
        <IonHeader>
          <IonToolbar>
            <IonButtons>
              <IonButton
                onClick={() => setNotificationTrayOpen(false)}
                slot="start"
              >
                Back
              </IonButton>
            </IonButtons>
            <IonTitle className="ion-text-center">Notifications</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding backgroundColor" >
            {notificationsData.map((notification) => (
             <NotificationItem notification={notification} key={notification.id} />
            ))}


            {/* <IonItem lines="none" className="ion-no-padding">
              <AvatarMini
                expert={false}
                publicAvatarUid="7pyySiqYANUY8wZCWdhz5ilgE9t1"
                src=""
              />
              <IonLabel className="ion-text-wrap">
                <p><b>AmaratheHare</b> commented on your post</p>
              </IonLabel>
            </IonItem> */}
        </IonContent>
      </IonModal>
    </>
  );
};

export default NotificationTray;
