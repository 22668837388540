import React from "react";
import "../Popup.css";

const Popup_cardiacWorkload = () => {
  return (
    <div className="ion-padding popup-wrapper">
      <p>
        Cardiac Workload, or more precisely myocardial workload, is a measure of
        the stress put on the heart muscle.
      </p>
      <p>
        Cardiac Workload can be calculated using the formula: Heart Rate x
        Systolic Blood Pressure.
      </p>
      <p>
        When measured at rest, this index can be used as an indicator of
        cardiovascular health.
      </p>
      <p>
        For example, a person who exercises regularly may have lower Cardiac
        Workload than another person who has a sedentary lifestyle. During
        intense physical exercise, your Cardiac Workload may increase but should
        decrease post-exercise.
      </p>
    </div>
  );
};

export default Popup_cardiacWorkload;
