import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
//import spinner
import { useContext, useEffect, useState } from "react";
import React from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import "firebase/compat/auth";
import { UserContext } from "../../lib/context";
import "./TeleMedicine.css";

import Image from "../../assets/images/stethoscope.png";
import { profile } from "console";
import axios from "axios";
import { Browser } from "@capacitor/browser";
import { chevronForward } from "ionicons/icons";

const Telemedicine: React.FC = () => {
  const { user, profile } = useContext(UserContext);
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();
  const [loading, setLoading] = useState(false);

  // console.log("profile", profile);
  // console.log("Token", user._delegate.accessToken);
  // console.log("user", user);

  const presentToast = (position, message, color) => {
    present({
      message: message,
      duration: 3000,
      position: position,
      color: color,
    });
  };

  const openBrowser = async (url) => {
    await Browser.open({ url: url });
  };

  const ShowTeleMedicine = () => {
    const [loading, setLoading] = useState(false);

    function getTelemedicineUrlSSO() {
      console.log("getTelemedicineUrlSSO");

      const data = { walmartHealthId: profile.walmartHealthId, uid: user.uid };

      // https://us-central1-allied-wellness-dev.cloudfunctions.net/HelloToTheWorld
      // https://us-central1-allied-wellness-dev.cloudfunctions.net/GetWalmartHealthDashboardSSO

      const config = {
        method: "post",
        url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/WalmartHealth-GetDashboardSSO`,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setLoading(false);
          openBrowser(response.data);
        })
        .catch(function (error) {
          presentToast("top", "An error has occurred", "danger");
          console.log(error);
        });
    }

    return (
      <IonCard>
        <IonButton
          onClick={() => {
            setLoading(true);
            getTelemedicineUrlSSO();
          }}
        >
          {" "}
          {loading ? <IonSpinner></IonSpinner> : <>Dashboard</>}
        </IonButton>
      </IonCard>
    );
  };

  function getTelemedicineUrlSSO() {
    console.log("getTelemedicineUrlSSO");

    const data = { walmartHealthId: profile.walmartHealthId };

    // https://us-central1-allied-wellness-dev.cloudfunctions.net/HelloToTheWorld
    // https://us-central1-allied-wellness-dev.cloudfunctions.net/GetWalmartHealthDashboardSSO
    presentToast("top", "Redirecting to Telemedicine", "primary");

    const config = {
      method: "post",
      url: `https://${process.env.REACT_APP_CF_URL}.cloudfunctions.net/WalmartHealth-GetDashboardSSO`,
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        openBrowser(response.data);
      })
      .catch(function (error) {
        presentToast("top", "An error has occurred", "danger");
        console.log(error);
      });
  }

  return (
    <>
      {profile.subscriptionType === "freeTrial" ? (
        <IonItem
          className="disabled"
          routerDirection="none"
          onClick={() =>
            presentAlert({
              subHeader: "Attention",
              message:
                "Please upgrade to a paid subscription to access Telemedicine",
              buttons: ["OK"],
            })
          }
        >
          <IonIcon slot="end" icon={chevronForward} />
          <IonLabel>Telemedicine</IonLabel>
        </IonItem>
      ) : (
        <>
          {profile.addr1 !== "" &&
          profile.city !== "" &&
          profile.state !== "" &&
          profile.zip !== "" &&
          profile.hasTelemedicine ? (
            <IonItem
              detail={false}
              routerDirection="none"
              onClick={() => getTelemedicineUrlSSO()}
              disabled={!profile.hasTelemedicine}
            >
              <IonIcon slot="end" icon={chevronForward} />

              <IonLabel>Telemedicine</IonLabel>
            </IonItem>
          ) : (
            <>
              {profile.hasTelemedicine ? (
                <IonItem
                  className="disabled"
                  routerDirection="none"
                  onClick={() =>
                    presentAlert({
                      subHeader: "Warning",
                      message: "Please update address to use Telemedicine",
                      buttons: ["OK"],
                    })
                  }
                >
                  <IonIcon slot="end" icon={chevronForward} />
                  <IonLabel>Telemedicine</IonLabel>
                </IonItem>
              ) : (
                <IonItem
                  className="disabled"
                  routerDirection="none"
                  onClick={() =>
                    presentAlert({
                      subHeader: "Warning",
                      message: "Your access does not include Telemedicine. Please contact your coach to get access.",
                      buttons: ["OK"],
                    })
                  }
                >
                  <IonIcon slot="end" icon={chevronForward} />
                  <IonLabel>Telemedicine</IonLabel>
                </IonItem>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Telemedicine;
