/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonAlert,
} from "@ionic/react";
//import spinner
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../lib/context";
import React from "react";
import {
  getDocs,
  collection,
  getFirestore,
  doc,
  updateDoc,
} from "firebase/firestore";
import { reformatDOB, formatToIonicDate } from "../../../../lib/helper";

const ChangeName: React.FC = () => {
  const { user, profile } = useContext(UserContext);

  const [dob, setDob] = React.useState(formatToIonicDate(profile ? profile.dob : ""));
  const [weight, setWeight] = React.useState(profile.weight);
  const [firstName, setFirstName] = React.useState(profile.firstName);
  const [lastName, setLastName] = React.useState(profile.lastName);
  const [heightIn, setHeightIn] = React.useState<number>(profile.heightIn);
  const [heightFt, setHeightFt] = React.useState<number>(profile.heightFt);
  const [gender, setGender] = React.useState(profile.gender);
  const [smoker, setSmoker] = React.useState(profile.smoker);
  const [hypertension, setHypertension] = React.useState(profile.hypertension);
  const [bloodPressureMeds, setBloodPressureMeds] = React.useState(
    profile.bloodPressureMeds
  );
  const [diabetes, setDiabetes] = React.useState(profile.diabetes);
  const [goals, setGoals] = React.useState(profile.goals);
  const [goalsData, setGoalsData] = useState([]);
  const [presentAlert] = useIonAlert();


  const validateFisrtName = () => {
    var firstNameHelp = document.getElementById("firstNameHelp");
    var regex = /^[a-z ,.'-]+$/i;

    if (firstName === undefined || firstName === "") {
      firstNameHelp.innerHTML = "First name is required";
      return false;
    }

    // make sure only accepts letters
    else if (!regex.test(firstName)) {
      firstNameHelp.innerHTML = "First name must only contain letters or ,.'-";
      return false;
    } else if (firstName.length < 2) {
      firstNameHelp.innerHTML = "First name must be at least 2 characters";
      return false;
    } else {
      firstNameHelp.innerHTML = "";
      return true;
    }
  };

  const validateLastName = () => {
    var lastNameHelper = document.getElementById("lastNameHelp");
    var regex = /^[a-z ,.'-]+$/i;

    if (lastName === undefined || firstName === "") {
      lastNameHelper.innerHTML = "Last name is required";
      return false;
    }

    // make sure only accepts letters
    else if (!regex.test(lastName)) {
      lastNameHelper.innerHTML = "Last name must only contain letters or ,.'-";
      return false;
    } else if (lastName.length < 2) {
      lastNameHelper.innerHTML = "Last name must be at least 2 characters";
      return false;
    } else {
      lastNameHelper.innerHTML = "";
      return true;
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();

    if (
      validateFisrtName() &&
      validateLastName()
    ) {
      const ref = doc(getFirestore(), "users", user.uid);

      // const dobArray = dob.split("-");
      // const dobYear = dobArray[0];
      // const dobMonth = dobArray[1];
      // const dobDay = dobArray[2];
      const formatedDob = reformatDOB(dob);

      await updateDoc(ref, {
        firstName: firstName,
        lastName: lastName,
      });

      presentAlert({
        header: 'Success',
        message: 'Your name has been updated',
        buttons: ['OK'],
      })
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="tabs/home" />
          </IonButtons>

          <IonTitle className="mainPageTitle">Update Health Profile</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
            <form onSubmit={updateProfile}>
              <IonItem>
                <IonLabel position="stacked">First Name</IonLabel>
                <IonInput
                  type="text"
                  placeholder="John"
                  value={firstName}
                  onIonChange={(e: any) => {
                    setFirstName(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateFisrtName();
                  }}
                  name="firstName"
                ></IonInput>
                <p className="formHelp" id="firstNameHelp"></p>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Last Name</IonLabel>
                <IonInput
                  type="text"
                  placeholder="Doe"
                  value={lastName}
                  onIonChange={(e: any) => {
                    setLastName(e.detail.value);
                  }}
                  onIonBlur={(e: any) => {
                    validateLastName();
                  }}
                  name="firstName"
                ></IonInput>
                <p className="formHelp" id="lastNameHelp"></p>
              </IonItem>

              <div className="ion-padding-top" id="login-help"></div>

              <IonButton type="submit" expand="block" className="ion-padding">
                Update
              </IonButton>
            </form>
      </IonContent>
    </IonPage>
  );
};

export default ChangeName;

