import {
  IonModal,
  IonContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonHeader,
} from "@ionic/react";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import React from "react";
import { useRef, useState } from "react";
import { addDoc, collection, doc, getFirestore, updateDoc } from "firebase/firestore";

export default function LogExercise(props) {
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [exerciseType, setExerciseType] = useState<string>("");
  const [intensity, setIntensity] = React.useState<number>(0);
  const [exerciseComment, setExerciseComment] = useState<string>("");
  const [exerciseDuration, setExerciseDuration] = useState<number>();

  function confirm() {
    if (!exerciseType || !exerciseDuration) {
      alert("Please select an exercise type and duration");
      return;
    } else {
      modal.current?.dismiss(input.current?.value, "confirm");
    }
  }

  async function onWillDismiss(ev: CustomEvent<OverlayEventDetail>) {

    const dateArray = props.date.split("-");
    const timestamp = new Date( parseInt(dateArray[0]), parseInt(dateArray[1]) - 1, parseInt(dateArray[2]));

    if (ev.detail.role === "confirm") {
      await addDoc(collection(getFirestore(), "exercise"), {
        type: exerciseType,
        intensity: Number(intensity),
        comment: exerciseComment,
        duration: Number(exerciseDuration),
        date: props.date,
        timestamp: timestamp,
        uid: props.user.uid,
        email: props.user.email,
      }).then(() => {
        updateDoc(doc(getFirestore(), "tracking", props.user.uid), {
          lastExerciseLog: new Date(),
          email: props.user.email,
          uid: props.user.uid
        });
      });

      setExerciseComment("");
      setExerciseDuration(null);
      setExerciseType("");
      setIntensity(0);
    }
  }

  const validateExerciseType = () => {
    var exerciseTypeHelper = document.getElementById("exerciseTypeHelp");
    if (exerciseType === undefined) {
      exerciseTypeHelper.innerHTML = "Please select an exercise type";
      return false;
    } else {
      exerciseTypeHelper.innerHTML = "";
      return true;
    }
  };

  const validateExerciseIntensity = () => {
    var exerciseIntensityHelper = document.getElementById(
      "exerciseIntensityHelp"
    );
    if (intensity === undefined) {
      exerciseIntensityHelper.innerHTML = "Please enter an exercise intensity";
      return false;
    } else {
      exerciseIntensityHelper.innerHTML = "";
      return true;
    }
  };

  const validateExerciseDuration = () => {
    var exerciseDurationHelper = document.getElementById(
      "exerciseDurationHelp"
    );
    if (exerciseDuration === undefined) {
      exerciseDurationHelper.innerHTML = "Please enter an exercise duration";
      return false;
    } else {
      exerciseDurationHelper.innerHTML = "";
      return true;
    }
  };

  const validateExerciseComment = () => {
    var exerciseCommentHelper = document.getElementById("exerciseCommentHelp");
    if (exerciseComment === undefined) {
      exerciseCommentHelper.innerHTML = "Please enter an exercise comment";
      return false;
    } else {
      exerciseCommentHelper.innerHTML = "";
      return true;
    }
  };

  return (
    <>
      <IonModal
        ref={modal}
        trigger="open-log-exercise2"
        onWillDismiss={(ev) => onWillDismiss(ev)}
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={() => modal.current?.dismiss()}>
                Cancel
              </IonButton>
            </IonButtons>
            <IonTitle  className="mainPageTitle">Log Exercise</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <IonItem>
            <IonLabel>What kind of exercise?</IonLabel>
            <IonSelect
              onIonChange={(e) => setExerciseType(e.detail.value)}
              onIonDismiss={validateExerciseType}
              value={exerciseType}
            >
              <IonSelectOption value="Cycling">Cycling</IonSelectOption>
              <IonSelectOption value="Meditation">Meditation</IonSelectOption>
              <IonSelectOption value="Running">Running</IonSelectOption>
              <IonSelectOption value="Swimming">Swimming</IonSelectOption>
              <IonSelectOption value="Walking">Walking</IonSelectOption>
              <IonSelectOption value="Weight">Weight</IonSelectOption>
              <IonSelectOption value="Yoga">Yoga</IonSelectOption>
              <IonSelectOption value="Other">Other</IonSelectOption>
            </IonSelect>
            <p className="formHelp" id="exerciseTypeHelp"></p>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">
              What was the intensity of your activity? (1-10*)
            </IonLabel>
            <IonInput
              min="1"
              max="10"
              placeholder="1-10"
              onIonChange={(e: any) => setIntensity(e.detail.value)}
              onIonBlur={validateExerciseIntensity}
              value={intensity}
            ></IonInput>
            <p className="formHelp" id="exerciseIntensityHelp"></p>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Duration (minutes)</IonLabel>
            <IonInput
              type="number"
              placeholder="30"
              onIonChange={(e: any) => setExerciseDuration(e.detail.value)}
              onIonBlur={validateExerciseDuration}
              value={exerciseDuration}
            ></IonInput>
            <p className="formHelp" id="exerciseDurationHelp"></p>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Comment</IonLabel>
            <IonInput
              type="text"
              autoCapitalize="on"
              autoCorrect="on"
              placeholder="Ex: I felt tired after this, but good overall"
              onIonChange={(e) => setExerciseComment(e.detail.value)}
              onIonBlur={validateExerciseComment}
              value={exerciseComment}
            ></IonInput>
            <p className="formHelp" id="exerciseCommentHelp"></p>
          </IonItem>
          <IonButton
            className="ion-padding"
            expand="block"
            onClick={() => confirm()}
          >
            Log
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
}
