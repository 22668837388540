import React from "react";
import "../Popup.css";

const Popup_systolic = () => {
  return (
    <div className="ion-padding popup-wrapper">
      <p>
        Systolic blood pressure is the peak pressure in your brachial arteries
        during the contraction of your heart muscle, measured in millimetres of
        mercury (mmHg).
      </p>
      <b>Systolic blood pressure levels in the USA:</b>
      <p>
        According to the{" "}
        <a href="https://www.ahajournals.org/doi/10.1161/HYP.0000000000000065">
          American Heart Association and the American College of Cardiology
        </a>
        , high blood pressure (hypertension) is defined as the following:
      </p>

      <table>
        <thead>
          <tr>
            <th></th>
            <th>Systolic Blood Pressure (mmHg)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Normal</td>
            <td>&lt; 120</td>
          </tr>
          <tr>
            <td>Elevated</td>
            <td>120 - 129</td>
          </tr>
          <tr>
            <td>Hypertension</td>
            <td>≥ 130</td>
          </tr>
        </tbody>
      </table>

      <b>Systolic blood pressure levels elsewhere:</b>
      <p>
        According to the{" "}
        <a href="https://academic.oup.com/eurheartj/article/39/33/3021/5079119">
          European Society of Cardiology and the European Society of
          Hypertension
        </a>
        , high blood pressure (hypertension) is defined as the following:
      </p>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Systolic Blood Pressure (mmHg)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Optimal</td>
            <td>&lt; 120</td>
          </tr>
          <tr>
            <td>Normal</td>
            <td>120 - 129</td>
          </tr>
          <tr>
            <td>High Normal</td>
            <td>130 - 139</td>
          </tr>
          <tr>
            <td>Hypertension</td>
            <td>≥ 140</td>
          </tr>
        </tbody>
      </table>

      <b>DISCLAIMER:</b>
      <p>
        This scan is not a medical device and should not be used for medical
        purposes. Always consult with your physician or other medical
        professional should you have any health-related question, issues or
        emergency.
      </p>
      <b>Accuracy of blood pressure estimates:</b>
      <p>
        In a study published in the{" "}
        <a href="https://www.ahajournals.org/doi/10.1161/CIRCIMAGING.119.008857">
          American Heart Association journal – Circulation: Cardiovascular
          Imaging (Vol. 12, No. 8)
        </a>{" "}
        the proprietary technique used by Triada HRM called Transdermal Optical
        Imaging (TOI) has been shown to measure blood pressure with an average
        overall accuracy of 95% over the following ranges:
      </p>
      <ul>
        <li>Systolic: 100 - 139 mmHg</li>
        <li>Diastolic: 60 – 89 mmHg</li>
      </ul>
    </div>
  );
};

export default Popup_systolic;
