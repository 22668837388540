// Updated by Aaron Gingrich 0n 1/27/2023

import React, { useContext, useEffect, useState } from "react";
import { App } from "@capacitor/app";

import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonRow,
  IonToggle,
} from "@ionic/react";
import {
  book,
  calendar,
  chatbox,
  chevronForward,
  eyeSharp,
  home,
  journal,
  people,
  settingsOutline,
  settingsSharp,
} from "ionicons/icons";
//import { CometChat } from "@cometchat-pro/chat";

import "./Menu.css";
import { UserContext } from "../lib/context";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import { usePhotoGallery } from "../lib/usePhotoGallery";
import Telemedicine from "./Tools/TeleMedicine";
import { settings } from "firebase/analytics";
import { Browser } from "@capacitor/browser";

const Menu: React.FC = () => {
  const { user, profile } = useContext(UserContext);
  const { takePhoto, clearPhoto } = usePhotoGallery();
  const [version, setVersion] = useState("web");

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  const signOutUser = () => {
    // CometChat.logout();
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
    //window.location.href = "/login";
  };

  var getFileBlob = function (url, cb) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener("load", function () {
      cb(xhr.response);
    });
    xhr.send();
  };

  const changeProfilePhoto = async () => {
    takePhoto().then(async (photo) => {
      getFileBlob(photo.webPath, (blob) => {
        const storage = getStorage();
        const profilePhotoRef = ref(storage, "profile_pictures/" + user.uid);
        uploadBytes(profilePhotoRef, blob).then(() => {
          getDownloadURL(profilePhotoRef).then(async (url) => {
            const ref = doc(getFirestore(), "users", user.uid);
            await updateDoc(ref, {
              photoURL: url,
            });

            clearPhoto();
          });
        });
      });
    });
  };

  const getVersionNumber = () => {
    App.getInfo().then((info) => {
      console.log(info);
      setVersion(info.version);
    }).catch((err) => {
      console.log("err", err);
    });
  };

  useEffect(() => {
    getVersionNumber();
  }, []);

  interface AppPage {
    url: string;
    disabled?: boolean;
    title: string;
  }

  var Pages: AppPage[] = [];
  var Tools: AppPage[] = [];

  if (profile.access === "facescanOnly") {
    Pages = [
      {
        title: "Change Password",
        url: "/change-password",
      },
      {
        title: "Update Profile Data",
        url: "/update-profile",
      },
      {
        title: "Change Email",
        url: "/change-email",
      },
      {
        title: "Change Phone",
        url: "/change-phone",
      },
      {
        title: "Preferences",
        url: "/preferences",
      },
    ];

    Tools = [
      {
        title: "Face Scan Vitals",
        url: "/diagnostics",
      },
    ];
  }

  if (profile.access === "fullAccess") {
    Pages = [
      {
        title: "Settings",
        url: "/settings",
      },
      {
        title: "Preferences",
        url: "/preferences",
      },
    ];

    Tools = [
      {
        title: "Face Scan Vitals",
        url: "/diagnostics",
        disabled: !profile.hasFacescan || !profile.active,
      },
      {
        title: "My Coach",
        url: "/my-coach",
        disabled: !profile.hasCoaching || !profile.active,
      },
   
    ];
  }

  const openBrowser = async (url) => {
    await Browser.open({ url: url });
  };

  return (
    <>
      <IonMenu contentId="main" type="overlay">
        <IonContent>
          <IonCard color="primary ion-padding-top ion-margin-top">
            <IonRow>
              <IonCol col-4></IonCol>
              <IonCol col-4>
                <div className="wrapper" onClick={changeProfilePhoto}>
                  <IonAvatar className="edit">
                  <img src={profile.photoURL} alt="" />

                  </IonAvatar>
                  <div className="edit-centered ">Edit</div>
                </div>
              </IonCol>

              <IonCol col-4></IonCol>
            </IonRow>
            <p className="name ion-text-center">{profile.firstName + " " + profile.lastName}</p>

            <IonCardContent>
              <p className="ion-text-center">{profile.email}</p>
            </IonCardContent>
          </IonCard>

          {/* <IonList lines="none">
            <IonListHeader>Account</IonListHeader>
            {Pages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    detail={false}
                    routerLink={appPage.url}
                    routerDirection="none"
                  >
                    <IonIcon slot="end" icon={chevronForward} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList> */}

          <IonList lines="none">
            <IonListHeader>Tools</IonListHeader>
            {Tools.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    detail={false}
                    routerLink={appPage.url}
                    routerDirection="none"
                    disabled={appPage.disabled}
                  >
                    <IonIcon slot="end" icon={chevronForward} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}

            {profile.access === "fullAccess" ? <Telemedicine /> : null}
            <IonItem
              detail={false}
              routerDirection="none"
              disabled={!profile.hasQuestis || !profile.active}
              onClick={() => openBrowser("https://go.myquestis.com/auth/login")}
            >
              <IonIcon slot="end" icon={chevronForward} />
              <IonLabel>Financial Wellness</IonLabel>
            </IonItem>
          </IonList>

          {profile.access === "fullAccess" ? (
            <IonList lines="none">
              <IonMenuToggle key="tabs" auto-hide="false">
                <IonItem
                  detail={false}
                  routerLink={"/tabs/home"}
                  routerDirection="none"
                >
                  <IonIcon slot="start" icon={home} />
                  <IonLabel>Home</IonLabel>
                </IonItem>
                <IonItem
                  detail={false}
                  routerLink={"/tabs/chat"}
                  routerDirection="none"
                  disabled={!profile.hasCoaching || !profile.active}
                >
                  <IonIcon slot="start" icon={chatbox} />
                  <IonLabel>Chat</IonLabel>
                </IonItem>
                <IonItem
                  detail={false}
                  routerLink={"/tabs/appointment"}
                  routerDirection="none"
                  disabled={!profile.hasCoaching || !profile.active || process.env.REACT_APP_ENVIRONMENT !== "prod"}
                >
                  <IonIcon slot="start" icon={calendar} />
                  <IonLabel>Appointment</IonLabel>
                </IonItem>
                <IonItem
                  detail={false}
                  routerLink={"/tabs/community"}
                  routerDirection="none"
                >
                  <IonIcon slot="start" icon={people} />
                  <IonLabel>Community</IonLabel>
                </IonItem>
                <IonItem
                  detail={false}
                  routerLink={"/tabs/journal"}
                  routerDirection="none"
                >
                  <IonIcon slot="start" icon={book} />
                  <IonLabel>Journal</IonLabel>
                </IonItem>
                <IonItem
                  detail={false}
                  routerLink={"/tabs/explore"}
                  routerDirection="none"
                  disabled={!profile.hasCoaching || !profile.active}
                >
                  <IonIcon slot="start" icon={eyeSharp} />
                  <IonLabel>Explore</IonLabel>
                </IonItem>
                <IonItem
                  detail={false}
                  routerLink={"/settings"}
                  routerDirection="none"
                >
                  <IonIcon slot="start" icon={settingsSharp} />
                  <IonLabel>Settings</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
          ) : null}
          <IonButton onClick={signOutUser} expand="block">
            Sign Out
          </IonButton>

          <p className="versionCode ion-text-center">Version - {version}</p>
        </IonContent>
      </IonMenu>
    </>
  );
};

export default Menu;
// import React, { useContext, useEffect, useState } from "react";
// import {App} from "@capacitor/app";

// import {
//   IonAvatar,
//   IonButton,
//   IonCard,
//   IonCardContent,
//   IonCardHeader,
//   IonCol,
//   IonContent,
//   IonGrid,
//   IonIcon,
//   IonItem,
//   IonLabel,
//   IonList,
//   IonListHeader,
//   IonMenu,
//   IonMenuToggle,
//   IonRouterOutlet,
//   IonRow,
// } from "@ionic/react";
// import {
//   book,
//   calendar,
//   chatbox,
//   chevronForward,
//   eyeSharp,
//   home,
// } from "ionicons/icons";
// //import { CometChat } from "@cometchat-pro/chat";

// import "./Menu.css";
// import { UserContext } from "../lib/context";
// import { doc, getFirestore, updateDoc } from "firebase/firestore";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
// import "firebase/compat/auth";
// import firebase from "firebase/compat/app";
// import { usePhotoGallery } from "../lib/usePhotoGallery";
// import Telemedicine from "./Tools/TeleMedicine";

// const Menu: React.FC = () => {
//   const { user, profile } = useContext(UserContext);
//   const { takePhoto, clearPhoto } = usePhotoGallery();
//   const [version, setVersion] = useState("web");

//   const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
//   };

//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);

//   const signOutUser = () => {
//     // CometChat.logout();
//     firebase
//       .auth()
//       .signOut()
//       .then(() => {
//         // Sign-out successful.
//       })
//       .catch((error) => {
//         // An error happened.
//       });
//     //window.location.href = "/login";
//   };

//   var getFileBlob = function (url, cb) {
//     var xhr = new XMLHttpRequest();
//     xhr.open("GET", url);
//     xhr.responseType = "blob";
//     xhr.addEventListener("load", function () {
//       cb(xhr.response);
//     });
//     xhr.send();
//   };

//   const changeProfilePhoto = async () => {
//     takePhoto().then(async (photo) => {
//       getFileBlob(photo.webPath, (blob) => {
//         const storage = getStorage();
//         const profilePhotoRef = ref(storage, "profile_pictures/" + user.uid);
//         uploadBytes(profilePhotoRef, blob).then(() => {
//           getDownloadURL(profilePhotoRef).then(async (url) => {
//             const ref = doc(getFirestore(), "users", user.uid);
//             await updateDoc(ref, {
//               photoURL: url,
//             });

//             clearPhoto();
//           });
//         });
//       });
//     });
//   };

//   const getVersionNumber = () => {
//     App.getInfo().then((info) => {
//       console.log(info);
//       setVersion(info.version);
//     });
//   };

//   useEffect(() => {getVersionNumber()}, []);

//   interface AppPage {
//     url: string;
//     disabled?: boolean;
//     title: string;
//   }

//   var Pages: AppPage[] = [];
//   var Tools: AppPage[] = [];

//   if (profile.access === "facescanOnly") {
//     Pages = [
//       {
//         title: "Change Password",
//         url: "/change-password",
//       },
//       {
//         title: "Update Profile Data",
//         url: "/update-profile",
//       },
//       {
//         title: "Change Email",
//         url: "/change-email",
//       },
//       {
//         title: "Change Phone",
//         url: "/change-phone",
//       },
//       {
//         title: "Preferences",
//         url: "/preferences",
//       }
//     ];

//     Tools = [
//       {
//         title: "Face Scan Vitals",
//         url: "/diagnostics",
//       },
//     ];
//   }

//   if (profile.access === "fullAccess") {
//     Pages = [
//       {
//         title: "Change Password",
//         url: "/change-password",
//       },
//       {
//         title: "Update Profile Data",
//         url: "/update-profile",
//       },
//       {
//         title: "Change Email",
//         url: "/change-email",
//       },
//       {
//         title: "Change Phone",
//         url: "/change-phone",
//       },
//       {
//         title: "Preferences",
//         url: "/preferences",
//       }
//     ];

//     Tools = [
//       {
//         title: "Face Scan Vitals",
//         url: "/diagnostics",
//         disabled: !profile.hasFacescan,
//       },
//       {
//         title: "My Coach",
//         url: "/my-coach",
//         disabled: !profile.hasCoaching,
//       },
//       {
//         title: "Update Address",
//         url: "/update-address",
//       },
//     ];
//   }

//   return (
//     <>
//       <IonMenu contentId="main" type="overlay">
//         <IonContent>
//           {/* <IonCard>
//             <IonCardHeader>
//               <IonRow>
//                 <IonCol col-4></IonCol>
//                 <IonCol col-4>
//                   <div className="wrapper" onClick={changeProfilePhoto}>
//                     <img src={profile.photoURL} alt="" />
//                     <div className="edit-centered ">Edit</div>
//                   </div>
//                 </IonCol>

//                 <IonCol col-4></IonCol>
//               </IonRow>
//               <IonLabel className="name">
//                 {profile.firstName + " " + profile.lastName}
//               </IonLabel>
//             </IonCardHeader>

//             <IonCardContent>
//               <IonLabel>{profile.email}</IonLabel>
//             </IonCardContent>
//           </IonCard> */}

//           <IonItem className='ion-padding-top' lines="none">
//             <IonAvatar  className="avatar">
//               <img alt="Silhouette of a person's head" src={profile.photoURL} onClick={changeProfilePhoto}/>
//             </IonAvatar>

//             <IonGrid>
//               <IonRow>
//                 <IonCol col-4>
//                   <IonLabel>
//                     {profile.firstName + " " + profile.lastName}
//                   </IonLabel>
//                 </IonCol>
//               </IonRow>
//               <IonRow>
//                 <IonCol col-4>
//                   <p className="email_subtitle">
//                   {profile.email}
//                   </p>
//                 </IonCol>
//               </IonRow>
//             </IonGrid>
//           </IonItem>

//           <IonList lines="none">
//             <IonListHeader>Account</IonListHeader>
//             {Pages.map((appPage, index) => {
//               return (
//                 <IonMenuToggle key={index} autoHide={false}>
//                   <IonItem
//                     detail={false}
//                     routerLink={appPage.url}
//                     routerDirection="none"
//                   >
//                     <IonIcon slot="end" icon={chevronForward} />
//                     <IonLabel>{appPage.title}</IonLabel>
//                   </IonItem>
//                 </IonMenuToggle>
//               );
//             })}
//           </IonList>

//           <IonList lines="none">
//             <IonListHeader>Tools</IonListHeader>
//             {Tools.map((appPage, index) => {
//               return (
//                 <IonMenuToggle key={index} autoHide={false}>
//                   <IonItem
//                     detail={false}
//                     routerLink={appPage.url}
//                     routerDirection="none"
//                     disabled={appPage.disabled}
//                   >
//                     <IonIcon slot="end" icon={chevronForward} />
//                     <IonLabel>{appPage.title}</IonLabel>
//                   </IonItem>
//                 </IonMenuToggle>
//               );
//             })}

//             {profile.access === "fullAccess" ? <Telemedicine /> : null}
//           </IonList>

//           {profile.access === "fullAccess" ? (
//             <IonList lines="none">
//               <IonMenuToggle key="tabs" auto-hide="false">
//                 <IonItem
//                   detail={false}
//                   routerLink={"/tabs/home"}
//                   routerDirection="none"
//                 >
//                   <IonIcon slot="start" icon={home} />
//                   <IonLabel>Home</IonLabel>
//                 </IonItem>
//                 <IonItem
//                   detail={false}
//                   routerLink={"/tabs/chat"}
//                   routerDirection="none"
//                   disabled={!profile.hasCoaching}
//                 >
//                   <IonIcon slot="start" icon={chatbox} />
//                   <IonLabel>Chat</IonLabel>
//                 </IonItem>
//                 <IonItem
//                   detail={false}
//                   routerLink={"/tabs/appointment"}
//                   routerDirection="none"
//                   disabled={!profile.hasCoaching}
//                 >
//                   <IonIcon slot="start" icon={calendar} />
//                   <IonLabel>Appointment</IonLabel>
//                 </IonItem>
//                 <IonItem
//                   detail={false}
//                   routerLink={"/tabs/journal"}
//                   routerDirection="none"
//                 >
//                   <IonIcon slot="start" icon={book} />
//                   <IonLabel>Journal</IonLabel>
//                 </IonItem>
//                 <IonItem
//                   detail={false}
//                   routerLink={"/tabs/explore"}
//                   routerDirection="none"
//                   disabled={!profile.hasCoaching}
//                 >
//                   <IonIcon slot="start" icon={eyeSharp} />
//                   <IonLabel>Explore</IonLabel>
//                 </IonItem>
//               </IonMenuToggle>
//             </IonList>
//           ) : null}
//           <IonButton onClick={signOutUser} expand="block">Sign Out</IonButton>

//           <p className="versionCode">Version - {version}</p>
//         </IonContent>
//       </IonMenu>
//     </>
//   );
// };

// export default Menu;
