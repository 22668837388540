import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { timeSince } from "../../../../../../lib/Helpers/time";
import AvatarMini from "../../../QuestionCard/AvatarMini";
import "./style.css";
import NotificationPost from "./NotificationPost";
import { close } from "ionicons/icons";

type Props = {
  notification;
};

const Template: React.FC<Props> = ({ notification }) => {
  return (
    <>
      <IonItem lines="none" className="ion-no-padding noBackground">
        <AvatarMini
          expert={notification.expert}
          publicAvatarUid={notification.fromUid}
          src=""
        />
        <IonLabel className="ion-text-wrap">
          <p>
            <b>{notification.fromName}</b> {notification.title}
          </p>
        </IonLabel>
        <IonLabel slot="end">
          <p className="ion-text-center">{timeSince(notification.timestamp)}</p>
        </IonLabel>
      </IonItem>
      <p className="ion-text-center">
        {notification.postId !== undefined && (
          <NotificationPost post={notification.postId} />
        )}
      </p>
    </>
  );
};

export default Template;
