import { GoalsList } from "../../Interfaces/Goals";
import { InterestList } from "../../Interfaces/Interest";
import "./style.css";

type Props = {
  id: string;
}

const RenderGoalTitle: React.FC<Props> = ({id}) => {

    // search InterestList for interest.id == id and get title
    const goal = GoalsList.find((goal) => goal.id === id);
    return < >{goal.title}</>;

};

export default RenderGoalTitle;