// loading spinner
//import spinner css
import "./LoaderTransparent.css";
export default function Loader() {
  return (
    <div className="loadingTransparent">
      <p>Hi</p>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/htm-app-9b541.appspot.com/o/AlliedWellnessLogo.png?alt=media&token=41d93b26-9a0e-4590-a3f4-90ca72616230"
        className="mylogoTransparent"
        alt="logo"
      />
    </div>
  );
}
