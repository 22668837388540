import React from "react";
import "../Popup.css";

const Popup_HypertensionRisk = () => {
  return (
    <div className="ion-padding popup-wrapper">
      <p>
        Hypertension risk corresponds to the percentage of people with the
        user's risk profile who are diagnosed with hypertension (high blood
        pressure) by their doctor.
      </p>
      <p>
        This risk profile is based on their facial blood flow and demographic
        information.
      </p>
      <p>
        Hypertension is a major treatable risk factor for cardiovascular
        disease. Therefore, those with a high hypertension risk should consider
        getting screened for hypertension by their doctor.
      </p>
      <p>
        <strong>Notice</strong>: Moment-to-moment fluctuations in your
        physiology mean that your risk estimates could vary to some degree from
        one measurement to the next. The best estimate of your overall risk is
        therefore obtained by averaging several measurements throughout the day
        and across several days to adequately account for this physiological
        variation.
      </p>
    </div>
  );
};

export default Popup_HypertensionRisk;
